import { CircularProgress, Grid, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { deletePost } from "../../../api/post";
import {
  getPostPicture,
  getRestaurantProfilePicture,
} from "../../../api/storage";
import { ConfirmDialog } from "../../../components";
import { COLORS } from "../../../constants/colors";
import useLoader from "../../../hooks/useLoader";
import PostPreview from "../../posts/PostPreview";

const formatTime = (time) => (time && time.seconds ? time.toDate() : time);

const PostCard = ({ post, t, reloadPosts, openEdit, openPlan, openCoupon, facility }) => {
  const [dialog, setDialog] = useState({ open: false });
  const { startLoading, endLoading } = useLoader();

  const openDeleteDialog = () => {
    setDialog({
      title: t("facilityMain:posts.delete.dialog.title"),
      text: t("facilityMain:posts.delete.dialog.desc"),
      open: true,
      onClose: closeDialog,
      onDecline: closeDialog,
      onAccept: () => {
        startLoading();
        deletePost(post.id)
          .then(() => reloadPosts())
          .catch((error) => console.log("error", error))
          .finally(() => endLoading());
        closeDialog();
      },
    });
  };

  const closeDialog = () => setDialog({ open: false });
  return (
    <Grid
      container
      style={{ marginTop: 20 }}
      alignContent="center"
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Grid item>
        <ConfirmDialog {...dialog} />
        {post ? (
          <div onClick={() => openEdit(post)}>
            <PostPreview
              image={
                post.data.useProfile
                  ? getRestaurantProfilePicture(facility.profilePhoto, "small")
                  : getPostPicture(post.data.photos[0], "small")
              }
              title={post.data.title}
              location={facility.name}
              dateFrom={formatTime(post.data.from)}
              dateTo={formatTime(post.data.to)}
            />
          </div>
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={1}
          direction={window.innerWidth < 438 ? "row" : "column"}
        >
          <Grid item>
            <Link
              onClick={() => openEdit(post)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: COLORS.black,
              }}
            >
              <Typography variant="body2">
                {t("facilityMain:posts.edit.button")}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link
              onClick={() => openDeleteDialog()}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: COLORS.black,
                marginTop: "1rem",
              }}
            >
              <Typography variant="body2">
                {t("facilityMain:posts.delete.button")}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link
              onClick={() => openPlan(post)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: COLORS.black,
                marginTop: "1rem",
              }}
            >
              <Typography variant="body2">
                {t("facilityMain:posts.plan.button")}
              </Typography>
            </Link>
          </Grid>
          {post.data.type === "5miSzPozLFoEJ0r7I2XE" && (
            <Grid item>
              <Link
                onClick={() => openCoupon(post)}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: COLORS.black,
                  marginTop: "1rem",
                }}
              >
                <Typography variant="body2">
                  {t("facilityMain:posts.coupon.info")}
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PostCard;
