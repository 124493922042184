import React from 'react';
import { Typography } from '@material-ui/core';

import { getNewsPicture } from '../../../api/storage';
import { PicPreview } from '../../../components';
import CardTemplate from '../../../components/CardTemplate';
import { useCardStyles } from '../../../styles/card';
import { COLORS } from '../../../constants/colors';
import { seeNews } from '../../../api/manager';


const NewsCard = ({ news, additionalComponents, highlight, openDetail, changeNewsCount, forceMobileView, horizontalPadding = "4rem" }) => {
    const classes = useCardStyles();
    const mobileView = forceMobileView || window.innerWidth < 650;
    const textSliceLen = mobileView ? 200 : Infinity;
    return (
        <div
            onClick={() => {
                if (highlight) {
                    seeNews(news.id);
                    changeNewsCount();
                }
                openDetail(news);
            }}
            style={{
                cursor: 'pointer'
            }}
        >
            <CardTemplate
                title={news.data.title}
                additionalComponents={additionalComponents}
                specialStyles={{
                    header: {
                        backgroundColor: highlight ? COLORS.primary : COLORS.blue
                    }
                }}
                horizontalPadding={horizontalPadding}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: mobileView ? 'column' : 'row',
                    }}
                >
                    {
                        !!news.data.photos.length &&
                        <div>
                            <PicPreview
                                src={news.data.photos[0] ? getNewsPicture(news.data.photos[0], 'tiny') : null}
                                alt='picture'
                                className={classes.cardImage}
                            />
                        </div>
                    }
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: `${mobileView ? 0 : 2}rem`,
                            marginTop: `${mobileView ? 2 : 0}rem`
                        }}
                    >
                        <div
                            style={{
                                height: mobileView ? undefined : '180px',
                                overflow: 'hidden',
                                padding: '0.5rem'
                            }}
                        >
                            {
                                news.data.text.slice(0, textSliceLen).split('\n').map((text, i) =>
                                    <Typography key={i} variant='body2' style={{ marginBottom: '0.5rem' }}>
                                        {text}
                                    </Typography>
                                )
                            }
                        </div>
                    </div>
                </div>
            </CardTemplate>
        </div>
    );
};

export default NewsCard;