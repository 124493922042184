import React from 'react';

import CardTemplate from '../../CardTemplate';
import { SinglePhotoPicker } from '../../facility';


const ProfilePicCard = ({ title, onChange, facility, desc }) => {

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <SinglePhotoPicker
                url={facility.profilePhoto}
                setUrl={newUrl => onChange('profilePhoto', newUrl)}
                showText={false}
            />
        </CardTemplate>
    );
};

export default ProfilePicCard;