import { firestore, firebaseAppAuth } from "../utils/firebase";

const countriesRef = firestore.collection("countries");

/**
 * Get a list of all active countries sorted by name.
 * @returns a promised array of all active countries
 */
export const getCountries = () => {
  return new Promise((resolve, reject) => {
    countriesRef
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        resolve(data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((error) => reject(error));
  });
};

/**
 * Create a new country with the specified data
 * @param {Object} data single parameter: name
 * @returns a promise with the new country object
 */
export const addCountry = (data) => {
  return new Promise((resolve, reject) => {
    countriesRef
      .add({ ...data, deleted: false })
      .then((doc) => {
        resolve({
          ...data,
          id: doc.id,
        });
      })
      .catch((error) => reject(error));
  });
};

export const editCountry = (id, data) => {
  return countriesRef.doc(id).update(data);
};

export const deleteCountry = (id) => {
  return countriesRef.doc(id).update({ deleted: true });
};

/**
 * Get a list of all active localities in the specified country with the specified parent sorted by name.
 * @param {string} countryId id of the country
 * @param {string} parent parent uid
 * @returns a promise with the localities array
 */
export const getLocalities = (countryId, parent) => {
  const localitiesRef = countriesRef.doc(countryId).collection("localities");
  return new Promise((resolve, reject) => {
    localitiesRef
      .where("deleted", "==", false)
      .where("parent", "==", parent)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        resolve(data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((error) => reject(error));
  });
};

/**
 * Get a list of all active localities in the specified country.
 * @param {string} countryId id of the country
 * @returns a promise with the localities array
 */
export const getAllLocalities = (countryId) => {
  const localitiesRef = countriesRef.doc(countryId).collection("localities");
  return new Promise((resolve, reject) => {
    localitiesRef
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};

export const addLocality = (countryId, data) => {
  const localitiesRef = countriesRef.doc(countryId).collection("localities");
  return new Promise((resolve, reject) => {
    localitiesRef
      .add({ ...data, deleted: false })
      .then((doc) => {
        resolve({
          ...data,
          id: doc.id,
        });
      })
      .catch((error) => reject(error));
  });
};

export const editLocality = (countryId, id, data) => {
  return countriesRef
    .doc(countryId)
    .collection("localities")
    .doc(id)
    .update(data);
};

export const deleteLocality = (countryId, id) => {
  return countriesRef
    .doc(countryId)
    .collection("localities")
    .doc(id)
    .update({ deleted: true });
};

/**
 * Returns the ancestor localities for the localities specified by the parameter.
 * @param {string} countryId reference to countries (id)
 * @param {Array} mainLocalities array of references to localities (ids)
 * @returns {Array} array of ancestor localities
 */
export const getDerivedLocalities = async (countryId, mainLocalities) => {
  const allLocalities = await getAllLocalities(countryId);
  const derivedLocalities = new Set();
  const unresolvedLocalities = new Set(mainLocalities);

  while (unresolvedLocalities.size > 0) {
    const resolvedLoc = unresolvedLocalities.values().next().value;
    const resolvedLocObj = allLocalities.find((l) => l.id === resolvedLoc);
    if (resolvedLocObj && resolvedLocObj.parent) {
      unresolvedLocalities.add(resolvedLocObj.parent);
    }
    unresolvedLocalities.delete(resolvedLoc);
    derivedLocalities.add(resolvedLoc);
  }

  for (let loc of mainLocalities) {
    derivedLocalities.delete(loc);
  }

  return Array.from(derivedLocalities);
};
