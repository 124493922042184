import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../../constants/colors";
import { blobToBase64 } from "../../messages/components/utils";
import { useTranslation } from "react-i18next";
import {
  FontDownloadOutlined,
  GetApp,
  PictureAsPdfRounded,
} from "@material-ui/icons";
import CustomImage from "./CustomImage";

const ExportImageList = (props) => {
  const {
    title,
    images,
    onOpen,
    weekMenuRequestData,
    individualDaysRequestData,
  } = props;
  const { t } = useTranslation();

  const [formatedImages, setFormatedImages] = useState([]);

  useEffect(() => {
    if (!images.length) {
      return;
    }
    Promise.all(
      images
        .filter((rawImg) => {
          return rawImg !== undefined;
        })
        .map((img) => {
          return blobToBase64(img);
        })
    ).then((data) => {
      setFormatedImages(data);
    });
  }, [images]);

  const onDownload = () => {
    if (!formatedImages) {
      return;
    }
    const link = document.createElement("a");
    formatedImages.forEach((img, id) => {
      link.download = `restomat-${title
        .split(" ")
        .join("-")
        .toLowerCase()}-${id}.png`;
      link.href = img;
      link.click();
    });
  };

  const onDownloadWeekPdf = () => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(weekMenuRequestData),
      redirect: "follow",
    };
    myHeaders.append("Content-Type", "application/json");
    fetch(
      "https://api.restomat.cz/api/v1/public/images/generate-week-menu-pdf",
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.download = `restomat-${title
          .split(" ")
          .join("-")
          .toLowerCase()}.pdf`;
        link.href = url;
        link.click();
      });
  };

  const onDownloadById = (id) => {
    if (!formatedImages) {
      return;
    }
    const link = document.createElement("a");
    const img = formatedImages.find((element, index) => index === id);

    if (!img) {
      return;
    }

    link.download = `restomat-${title
      .split(" ")
      .join("-")
      .toLowerCase()}-${id}.png`;
    link.href = img;
    link.click();
  };

  const onDownloadPdfById = (id) => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(individualDaysRequestData[id]),
      redirect: "follow",
    };
    myHeaders.append("Content-Type", "application/json");
    fetch(
      "https://api.restomat.cz/api/v1/public/images/generate-individual-day-pdf",
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.download = `restomat-${title
          .split(" ")
          .join("-")
          .toLowerCase()}-${id}.pdf`;
        link.href = url;
        link.click();
      });
  };

  const onDownloadIndividualPdfs = () => {
    formatedImages.forEach((_, id) => {
      onDownloadPdfById(id);
    });
  };

  if (!formatedImages?.length) {
    return <></>;
  }

  const isDownloadPdf =
    title === t("menuEdit:print") || title === `${t("menuEdit:menuDays")} Tisk`;

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Typography variant="h5">{title}</Typography>
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {formatedImages &&
          formatedImages.map((src, id) => {
            return (
              <Grid item>
                <CustomImage
                  isDownloadPdf={isDownloadPdf}
                  onDownload={() => {
                    isDownloadPdf
                      ? title === t("menuEdit:print")
                        ? onDownloadWeekPdf()
                        : onDownloadPdfById(id)
                      : onDownloadById(id);
                  }}
                  src={src}
                  id={id}
                />
              </Grid>
            );
          })}

        <button
          className={"icon-button"}
          onClick={() => onOpen(formatedImages)}
        >
          <img
            src={"/magnifier.svg"}
            alt={"zobrazit"}
            style={{ height: "32px" }}
          />
        </button>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant={"body1"}
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {t("menuEdit:bulkDownload")}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <button className={"icon-button"} onClick={onDownload}>
              <img
                src={"/image.svg"}
                alt={"stáhnout"}
                style={{ height: "32px" }}
              />
            </button>

            {isDownloadPdf && (
              <button
                className={"icon-button"}
                onClick={
                  title === t("menuEdit:print")
                    ? onDownloadWeekPdf
                    : onDownloadIndividualPdfs
                }
              >
                <img
                  src={"/pdf.svg"}
                  alt={"stáhnout jako PDF"}
                  style={{ height: "32px" }}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ExportImageList;
