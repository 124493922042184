import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Delete,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

import { getLocalities, addLocality, deleteLocality } from "../api/locality";
import useLoader from "../hooks/useLoader";
import { useLoginFormStyles } from "../styles/form";

const LocalityTreeNode = ({
  country,
  path,
  data,
  deleteCallback,
  edit = false,
  extraButton,
}) => {
  const [children, setChildren] = useState(null);
  const [hidden, setHidden] = useState(true);
  const { t } = useTranslation();

  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleLocalityAdd = (name) => {
    startLoading();
    addLocality(country, { name, parent: getParent(path) })
      .then((locality) => {
        setChildren((prev) => [...prev, locality]);
        setAddModalOpen(false);
      })
      .catch((error) => {
        console.error(error);
        alert(t('localities:error'));
      })
      .finally(() => endLoading());
  };

  const handleLocalityDelete = () => {
    startLoading();
    deleteLocality(country, data.id)
      .then(() => {
        endLoading();
        deleteCallback();
      })
      .catch((error) => {
        console.error(error);
        endLoading();
        alert(t('localities:error'));
      });
  };

  const { startLoading, endLoading } = useLoader();

  const showChildren = (event) => {
    event.preventDefault();
    if (children) {
      setHidden(false);
      return;
    }

    startLoading();
    getLocalities(country, data.id ? data.id : null)
      .then((data) => {
        // TODO build the tree
        setChildren(data);
        setHidden(false);
      })
      .catch((error) => {
        console.error(error);
        alert(t('localities:error'));
      })
      .finally(() => endLoading());
  };

  const hideChildren = () => {
    setHidden(true);
  };

  const addChild = () => {
    setAddModalOpen(true);
  };

  const noArrow = !edit && !!children && children.length === 0;
  const ExtraButton = extraButton;

  return (
    <>
      <Typography variant="body2">
        {hidden && (
          <IconButton onClick={showChildren}>
            <KeyboardArrowDown style={{ opacity: noArrow ? 0 : undefined }} />
          </IconButton>
        )}
        {!hidden && (
          <IconButton onClick={hideChildren}>
            <KeyboardArrowUp style={{ opacity: noArrow ? 0 : undefined }} />
          </IconButton>
        )}
        {data.name}
        {edit && deleteCallback && (
          <IconButton onClick={handleLocalityDelete}>
            <Delete />
          </IconButton>
        )}
        {extraButton && <ExtraButton path={path} />}
      </Typography>
      <ul style={{ listStyleType: "none", margin: 0 }}>
        {children &&
          !hidden &&
          children.map((child) => (
            <li key={child.name}>
              <LocalityTreeNode
                country={country}
                path={[...path, child.id]}
                data={child}
                deleteCallback={() =>
                  setChildren((prev) => prev.filter((a) => a.id !== child.id))
                }
                edit={edit}
                extraButton={extraButton}
              />
            </li>
          ))}
        {edit && children && !hidden && (
          <li onClick={addChild} style={{ cursor: "pointer" }}>
            <Typography variant="body2">
              <IconButton>
                <Add />
              </IconButton>
                {t('localities:add.short')}
            </Typography>
          </li>
        )}
      </ul>
      {edit && (
        <AddModal
          open={addModalOpen}
          onClose={() => setAddModalOpen(false)}
          onSave={handleLocalityAdd}
        />
      )}
    </>
  );
};

export default LocalityTreeNode;

const AddModal = ({ open, onClose, onSave }) => {
  const classes = useLoginFormStyles();
  const [name, setName] = useState("");
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('localities:add.long')}</DialogTitle>
      <div style={{ padding: 20, paddingTop: 0 }}>
        <div className={classes.formEntry}>
          <TextField
            label={t('localities:name')}
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
          />
        </div>
        <div className={classes.formEntry}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSave(name)}
          >
            {t('localities:save')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const getParent = (path) => {
  if (path.length === 0) {
    return null;
  }

  return path[path.length - 1];
};
