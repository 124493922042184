import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";

import { getEditorInfo } from "../../api/editors";

import ExistingEditorStage from "./ExistingEditorStage";
import ExistingUserStage from "./ExistingUserStage";
import NewUserStage from "./NewUserStage";

const FirstStage = ({ data, setStage, setStageData, onClose }) => {
  const { t } = useTranslation();

  const handleFormSubmit = () => {
    if (!isEmailValid(data.email)) {
      return alert(t("editors:insertEmail"));
    }

    getEditorInfo(data.email)
      .then((responseData) => {
        setStageData((prev) => ({ ...prev, ...responseData }));
        if (responseData.uid && responseData.editorData) {
          setStageData((prev) => ({
            ...prev,
            name: responseData.editorData.name,
          }));
          return setStage(() => ExistingEditorStage);
        }
        if (responseData.uid) {
          return setStage(() => ExistingUserStage);
        }
        return setStage(() => NewUserStage);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <DialogContent>
        <Typography variant="body2">{t("editors:insertEmail")}</Typography>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <TextField
            name="email"
            type="email"
            placeholder={t("editors:email")}
            value={data.email}
            onChange={(e) =>
              setStageData((prev) => ({ ...prev, email: e.target.value }))
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("confirmDialog:cancel")}
        </Button>
        <Button
          color="primary"
          onClick={handleFormSubmit}
          disabled={!isEmailValid(data.email)}
        >
          {t("confirmDialog:accept")}
        </Button>
      </DialogActions>
    </>
  );
};

export default FirstStage;

const isEmailValid = (email) =>
  email.match(
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  );
