import React from "react";
import styled from "@material-ui/styles/styled";
import TextField from "@material-ui/core/TextField";
import { COLORS } from "../constants/colors";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    height: "42px",
    "& fieldset": {
      borderRadius: "24px",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.orange,
    },
  },
});
export default CustomTextField;
