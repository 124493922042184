import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { userCreateConversation } from "../../../api/messages";
import NewMessageArea from "./NewMessageArea";

const NewConversationModal = ({
  opened,
  close,
  t,
  isMobile,
  userFacilities,
}) => {
  const [title, setTitle] = useState("");
  const [sender, setSender] = useState("user");
  const [facility, setFacility] = useState({});
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [msg, setMsg] = useState("");

  const onChangeInput = (e) => setTitle(e.target.value);
  const onChangeRadio = (e) => setSender(e.target.value);
  const onChangeFacility = (value) => setFacility(value);
  const onChangeImages = (img) => setImages(img);
  const onChangeFiles = (fls) => setFiles(fls);
  const onChangeMsg = (e) => setMsg(e.target.value);

  const onClose = (id) => {
    setTitle("");
    setImages([]);
    setFiles([]);
    setMsg("");
    close(id);
  };

  const doSendMessage = () => {
    userCreateConversation({
      title: title || t("messages:conversations.add.defaultSubject"),
      isFacility: sender === "facility",
      facility: facility.id,
      msg,
      files,
      images,
    })
      .then((id) => {
        close(id);
        onClose();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: isMobile ? "100vw" : "50vw",
          margin: 10,
        },
      }}
    >
      <DialogTitle>{t("messages:conversations.add.title")}</DialogTitle>
      <div
        style={{
          minWidth: isMobile ? undefined : "20rem",
          minHeight: "10rem",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          variant="outlined"
          value={title}
          label={t("messages:conversations.add.textField")}
          onChange={onChangeInput}
          style={{
            marginTop: "1rem",
            width: "100%",
          }}
        />
        <Typography
          variant="body1"
          style={{
            marginTop: "1rem",
          }}
        >
          {t("messages:conversations.add.sendAs")}
        </Typography>
        <RadioGroup value={sender} onChange={onChangeRadio} row>
          <FormControlLabel
            value="user"
            control={<Radio />}
            label={t("messages:conversations.add.user")}
          />
          <FormControlLabel
            value="facility"
            control={<Radio />}
            label={t("messages:conversations.add.facility")}
          />
        </RadioGroup>
        {sender === "facility" && (
          <Autocomplete
            options={userFacilities}
            value={facility}
            getOptionLabel={(option) => (option.data ? option.data.name : "")}
            onChange={(event, newValue) => onChangeFacility(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("messages:conversations.add.chooseFacility")}
                variant="outlined"
              />
            )}
            style={{
              marginTop: "1rem",
            }}
          />
        )}
        <NewMessageArea
          idSuffix="-user"
          isMobile={isMobile}
          text={msg}
          setText={onChangeMsg}
          images={images}
          setImages={onChangeImages}
          files={files}
          setFiles={onChangeFiles}
          onSubmit={doSendMessage}
        />
      </div>
    </Dialog>
  );
};

export default NewConversationModal;
