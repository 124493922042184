import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  makeStyles,
} from "@material-ui/core";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";

import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));

const ExportImageDetailModal = (props) => {
  const { open, setOpen, images } = props;
  const classes = useStyles();
  const [currentImageId, setCurrentImageId] = useState(0);

  useEffect(() => {
    setCurrentImageId(0);
  }, [images]);

  const handleClose = () => {
    setOpen(false);
  };

  const nextImage = () => {
    setCurrentImageId((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevImage = () => {
    setCurrentImageId((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  if (!images) {
    return <></>;
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        <IconButton onClick={prevImage}>
          <ArrowBackIosOutlined color="secondary" />
        </IconButton>
        <Fade in={open} timeout={500} className={classes.img}>
          <img
            key={currentImageId}
            src={images[currentImageId]}
            alt="daily-meal"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          ></img>
        </Fade>
        <IconButton onClick={nextImage}>
          <ArrowForwardIosOutlined color="secondary" />
        </IconButton>
      </>
    </Modal>
  );
};

export default ExportImageDetailModal;
