import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { AddCircle, ExpandMore } from "@material-ui/icons";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomSwitch from "../../../components/CustomSwitch";
import { COLORS } from "../../../constants/colors";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment/moment";
import { capitalizeFirstLetter } from "../../../utils/stringHelper";
import TableHeader from "./TableHeader";
import Meal from "./Meal";
import useFacility from "../../../hooks/useFacility";
import useDayMenu from "../../../hooks/useDayMenu";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Loader } from "../../../components";
import { Skeleton } from "@material-ui/lab";

const DayMenu = (props) => {
  const {
    dayId,
    date,
    loaded,
    setWeekMenuData,
    weekMenuData,
    hasUnsavedData,
    setHasUnsavedData,
    isLoading,
  } = props;

  const { facility } = useFacility();
  const { id } = useParams();
  const { t } = useTranslation();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openNoMenuDialog, setOpenNoMenuDialog] = useState(false);
  const [mealToUnpropagate, setMealToUnpropagate] = useState(null);
  const [mealToDelete, setMealToDelete] = useState({ id: 0, everyDay: false });
  const [dayOpen, setDayOpen] = useState(false);
  const [mealsError, setMealsError] = useState(false);

  const {
    shiftDown,
    shiftUp,
    onChangeMealEveryDay,
    removeMeal,
    addMeal,
    addSoup,
    setSoupInPrice,
    propagateEveryDayMeal,
    setMeals,
    setEveryDayMeals,
    setNoDailyMenu,
    removeAllMeals,
    updateMeal,
    updateEveryDayMeal,
    removePropagatedMealFromAllDays,
  } = useDayMenu(weekMenuData, setWeekMenuData, dayId);

  const isDayFilled = useMemo(() => {
    if (!weekMenuData?.days) return false;
    const day = weekMenuData?.days[dayId];
    return day?.meals?.length || day?.everyDayMeals?.length || day?.noDailyMenu;
  }, [dayId, weekMenuData?.days]);

  return !weekMenuData ||
    !weekMenuData.days ||
    weekMenuData.days.length === 0 ? (
    <Skeleton
      style={{
        width: "100%",
        height: "86px",
        marginBottom: "-28px",
        borderRadius: "16px",
      }}
    />
  ) : (
    <>
      <Accordion
        style={{ borderRadius: 16, margin: "10px 0" }}
        expanded={dayOpen}
        onChange={(_, expanded) => {
          setDayOpen(expanded);
        }}
      >
        <AccordionSummary
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: 0,
            backgroundColor: COLORS.blue,
            borderRadius: "16px 16px  0 0 ",
          }}
          expandIcon={
            <ExpandMore style={{ color: COLORS.white, fontSize: 24 }} />
          }
        >
          <Typography
            variant="body1"
            style={{ color: COLORS.white, margin: "0 10px" }}
          >
            {capitalizeFirstLetter(
              new moment(date)
                .locale(navigator.language.split("-")[0])
                .format("dddd Do MMMM")
            )}{" "}
            - {facility.name}
          </Typography>
          <Typography
            variant="body1"
            style={{
              margin: "0 20px 0 auto",
              fontWeight: "bold",
              color: isDayFilled ? COLORS.green : COLORS.red,
            }}
          >
            {loaded
              ? isDayFilled
                ? t("menuEdit:filled")
                : t("menuEdit:notFilled")
              : ""}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container direction="column" spacing={2}>
            <Grid
              key={"checkboxes"}
              item
              style={{ display: "inline-flex", margin: "10px 0" }}
            >
              <CustomSwitch
                disabled={isLoading}
                checked={weekMenuData?.days[dayId]?.soupInPrice}
                onChange={() => {
                  setSoupInPrice(!weekMenuData?.days[dayId]?.soupInPrice);
                }}
              />
              <Typography style={{ marginRight: 24 }}>
                {t("menuEdit:soupInPrice")}
              </Typography>
              <CustomSwitch
                disabled={isLoading}
                checked={weekMenuData.days[dayId]?.noDailyMenu}
                onChange={() => {
                  if (!weekMenuData.days[dayId]?.noDailyMenu) {
                    if (
                      weekMenuData.days[dayId]?.meals.length +
                        weekMenuData.days[dayId]?.everyDayMeals.length >
                      0
                    ) {
                      setOpenNoMenuDialog(true);
                    } else {
                      setNoDailyMenu(true);
                    }
                  } else {
                    setNoDailyMenu(false);
                  }
                }}
              />
              <Typography>{t("menuEdit:todayNoDailyMenu")}</Typography>
            </Grid>
            <div className={"menu-edit-table-container"}>
              <table style={{ width: "90%" }} className={"menu-edit-table"}>
                <TableHeader dayId={dayId} />
                <tbody>
                  {weekMenuData?.days[dayId]?.meals
                    ?.sort((a, b) => {
                      if (a.type === b.type) return a.id - b.id;
                      return a.type === "soup" ? -1 : 1;
                    })
                    .map((meal, id) => {
                      return (
                        <Meal
                          shiftUp={shiftUp}
                          shiftDown={shiftDown}
                          key={id}
                          meal={meal}
                          id={id}
                          dayId={dayId}
                          soupInPrice={weekMenuData.days[dayId]?.soupInPrice}
                          setMealToDelete={setMealToDelete}
                          setOpenCancelDialog={setOpenCancelDialog}
                          onChangeMealEveryDay={onChangeMealEveryDay}
                          updateMeal={updateMeal}
                          updateEveryDayMeal={updateEveryDayMeal}
                          propagateEveryDayMeal={propagateEveryDayMeal}
                        />
                      );
                    })}

                  {weekMenuData?.days[dayId]?.everyDayMeals.length > 0 && (
                    <>
                      <tr>
                        <td colSpan={7} style={{ textAlign: "left" }}>
                          <Typography display="inline" variant="h6">
                            {t("menuEdit:everyDayMeals")}
                          </Typography>
                        </td>
                      </tr>

                      {weekMenuData?.days[dayId]?.everyDayMeals
                        ?.sort((a, b) => {
                          if (a.type === b.type) return a.id - b.id;
                          return a.type === "soup" ? -1 : 1;
                        })
                        .map((meal, everyDayMealId) => {
                          return (
                            <Meal
                              shiftUp={shiftUp}
                              shiftDown={shiftDown}
                              isLoading={isLoading}
                              key={everyDayMealId}
                              meal={meal}
                              id={everyDayMealId}
                              dayId={dayId}
                              soupInPrice={
                                weekMenuData.days[dayId]?.soupInPrice
                              }
                              setMealToDelete={setMealToDelete}
                              setOpenCancelDialog={setOpenCancelDialog}
                              onChangeMealEveryDay={onChangeMealEveryDay}
                              updateMeal={updateMeal}
                              updateEveryDayMeal={updateEveryDayMeal}
                              propagateEveryDayMeal={propagateEveryDayMeal}
                              onRemovePropagatedMealFromAllDays={(meal) =>
                                setMealToUnpropagate(meal)
                              }
                            />
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <Typography
              style={{
                color: COLORS.red,
                marginLeft: 10,
                display: mealsError ? "block" : "none",
              }}
            >
              {t("menuEdit:mealsNotComplete")}
            </Typography>
            <Grid
              key={"formButtons"}
              style={{ display: "flex", justifyContent: "space-between" }}
              item
            >
              <Box style={{ display: "inline-flex" }}>
                <Button
                  variant="contained"
                  disabled={weekMenuData.days[dayId]?.noDailyMenu || isLoading}
                  style={{
                    marginTop: 10,
                    height: 32,
                    backgroundColor: weekMenuData.days[dayId]?.noDailyMenu
                      ? COLORS.superLightGrey
                      : COLORS.primary,
                    color: COLORS.blue,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    addSoup();
                  }}
                >
                  <AddCircle
                    style={{
                      color: COLORS.white,
                      marginRight: 6,
                      marginLeft: -10,
                    }}
                  />
                  {t("menuEdit:addSoup")}
                </Button>
                <Button
                  variant="contained"
                  disabled={weekMenuData.days[dayId]?.noDailyMenu || isLoading}
                  style={{
                    margin: 10,
                    height: 32,
                    backgroundColor: weekMenuData.days[dayId]?.noDailyMenu
                      ? COLORS.superLightGrey
                      : COLORS.primary,
                    color: COLORS.blue,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    addMeal();
                  }}
                >
                  <AddCircle
                    style={{
                      color: COLORS.white,
                      marginRight: 6,
                      marginLeft: -10,
                    }}
                  />
                  {t("menuEdit:addMeal")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ConfirmDialog
        open={openCancelDialog}
        onClose={() => {
          setOpenCancelDialog(false);
        }}
        onAgree={() => {
          removeMeal(mealToDelete.id, mealToDelete.everyDay);
          setOpenCancelDialog(false);
        }}
        t={t}
        text={t("menuEdit:deleteMealDialog")}
      />
      <ConfirmDialog
        open={openNoMenuDialog}
        onClose={() => {
          setOpenNoMenuDialog(false);
        }}
        onAgree={() => {
          setNoDailyMenu(!weekMenuData.days[dayId]?.noDailyMenu);
          removeAllMeals();
          setOpenNoMenuDialog(false);
        }}
        t={t}
        text={t("menuEdit:noMenuRemoveAll")}
      />

      <ConfirmDialog
        open={mealToUnpropagate}
        onClose={() => {
          setMealToUnpropagate(null);
        }}
        onAgree={() => {
          removePropagatedMealFromAllDays(mealToUnpropagate);
          setMealToUnpropagate(null);
        }}
        t={t}
        text={"Opravdu chcete toto jídlo smazat ze všech dnů?"}
      />
    </>
  );
};

export default DayMenu;
