import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { subscribeNewMessages } from '../../api/messages';
import { getUnseenNewsCount } from '../../api/news';

import Loader from '../Loader';
import { AdminRouter, BlankPageRouter, LoginRouter, ManagerRouter, WaitingRoomRouter, EditorRouter } from './routers';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';



const Layout = ({ isAdmin, signOut, user, isLoading, isManager, isEditor }) => {
    const path = window.location.pathname;
    const diplayInitial = !user || path.startsWith('/emailHandler');

    const mobileView = window.innerWidth < 650;

    const [newsCount, setNewsCount] = useState();
    const [messagesCount, setMessagesCount] = useState();
    const [force, setForce] = useState(0);

    useEffect(() => {
        if (!user || !isManager || !isEditor) {
            return;
        }
        getUnseenNewsCount()
            .then(count => setNewsCount(count))
            .catch(error => console.log('error', error));
    }, [path, user, isManager, isEditor, force]);

    useEffect(() => {
        if (!user || isAdmin === null) {
            return;
        }
        const callback = newMessagesCount => {
            setMessagesCount(newMessagesCount);
        };

        const unsubscribe = subscribeNewMessages(isAdmin, callback);

        if (typeof unsubscribe === 'function') {
            return () => unsubscribe();
        }
        return () => {};

    }, [setMessagesCount, user, isAdmin]);

    const changeNewsCount = () => setForce(force + 1);

    return (
        <>
            <TopMenu
                authenticated={!!user}
                isAdmin={isAdmin}
                isManager={isManager}
                isEditor={isEditor}
                mobileView={mobileView}
                signOut={signOut}
            />
            {
                mobileView ?
                    null :
                    <SideMenu
                        authenticated={!diplayInitial}
                        isAdmin={isAdmin}
                        isManager={isManager}
                        isEditor={isEditor}
                        newsCount={newsCount}
                        messagesCount={messagesCount}
                    />
            }
            {isLoading && <Loader />}
            <Container
                style={{
                    position: 'absolute',
                    top: '64px',
                    left: mobileView || diplayInitial ? 0 : '231px',
                    padding: getContainerPadding(!diplayInitial, mobileView),
                    maxWidth: mobileView || diplayInitial ? '100%' : 'calc(100vw - 231px)',
                    height: 'calc(100% - 64px)',
                    overflow: 'auto'
                }}
            >
                {user === null && <LoginRouter />}
                {isAdmin === null && isManager === null && isEditor === null && <BlankPageRouter />}
                {isAdmin === false && isManager === false && isEditor === false && (
                    <WaitingRoomRouter />
                )}
                {isAdmin && <AdminRouter />}
                {!isAdmin && isManager && <ManagerRouter changeNewsCount={changeNewsCount} />}
                {!isAdmin && !isManager && isEditor && <EditorRouter changeNewsCount={changeNewsCount} />}
            </Container>
        </>
    )

};

export default Layout;

const getContainerPadding = (isLoggedIn, isMobile) => {
    if (!isLoggedIn) {
        return 0;
    }

    if (isMobile) {
        return "1rem";
    }

    return "3rem";
};