import React from "react";
import format from "date-fns/format";
import csLocale from "date-fns/locale/cs";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// format docs: https://date-fns.org/v2.28.0/docs/format
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "EEEE d. MMMM", { locale: this.locale });
  }

  getDateTimePickerHeaderText(date) {
    return format(date, "d. M.", { locale: this.locale });
  }

  getCalendarHeaderText(date) {
    return format(date, "LLLL yyyy", { locale: this.locale });
  }
}

const LocalizedPickerProvider = ({ children }) => {
  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={csLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default LocalizedPickerProvider;
