import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { verifyEmail } from '../../hooks/useAuth';
import DefaultBackground from './components/DefaultBackground';
import MainCard from './components/MainCard';


const ConfirmEmail = ({ oobCode }) => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const history = useHistory();
    const { t } = useTranslation();

    const handleSubmit = async e => {
        e.preventDefault();
        verifyEmail(oobCode).
            then(() => {
                setSuccess(true);
                setTimeout(
                    () => { history.push('/'); window.location.reload(true); },
                    2000
                );
            }).
            catch(() => {
                setSuccess(false);
                setError(t('confirmEmail:error'));
            });
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <DefaultBackground>
                    <MainCard
                        isConfirmEmail={true}
                        error={error}
                        success={success}
                    />
                </DefaultBackground>
            </form>
        </>
    );
};

export default ConfirmEmail;