import React from "react";
import {Link} from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useManagedFacilities from "../hooks/useManagedFacilities";
import InDevel from "../components/InDevel";

import { useBasicDataContext } from "../hooks/useBasicData";

const Membership = () => {
  const [facilities, setFacilities] = useManagedFacilities();
  const { t } = useTranslation();

  const { membershipTypes } = useBasicDataContext();

  const getMembershipName = (id) => {
    const membershipType = membershipTypes.find((m) => m.id === id);
    if (membershipType) {
      return membershipType.name;
    }

    return "";
  };

  return (
    <div>
      <Typography variant="h5" style={{marginBottom: 20}}>{t("membership:title")}</Typography>
      {facilities.map((facility) => (
        <div key={facility.name}>
          <Typography variant="body1">
            <Link to={`/facilities/${facility.id}`}>{facility.name}</Link>:{" "}
            {getMembershipName(facility.membership)}
          </Typography>
        </div>
      ))}

      <InDevel />
    </div>
  );
};

export default Membership;
