import React, { useState } from "react";
import CustomTextField from "../../../components/CustomTextField";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constants/colors";
import {
  AddCircle,
  ArrowDownward,
  ArrowUpward,
  CancelRounded,
  DynamicFeedRounded,
  RemoveCircleOutline,
} from "@material-ui/icons";
import {
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CustomCheckbox from "../../../components/CustomCheckbox";
import AllergenSelector from "./AllergenSelector";

const Meal = (props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    meal,
    id,
    dayId,
    soupInPrice,
    setMealToDelete,
    shiftUp,
    shiftDown,
    setOpenCancelDialog,
    onRemovePropagatedMealFromAllDays,
    onChangeMealEveryDay,
    updateMeal,
    updateEveryDayMeal,
    propagateEveryDayMeal,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr key={id} style={{verticalAlign: 'top'}}>
        {meal.type === "soup" ? (
          <div style={{ marginRight: "5px" }}>
            <td></td>
            <td></td>
          </div>
        ) : (
          <div style={{ marginRight: "5px" }}>
            <td>
              <IconButton
                disabled={isLoading}
                style={{
                  height: 8,
                  width: 8,
                  border: `1px solid ${COLORS.blue}`,
                }}
                onClick={() => {
                  shiftUp(id, meal.everyDay);
                }}
              >
                <ArrowUpward style={{ color: COLORS.blue, height: 16 }} />
              </IconButton>
            </td>
            <td>
              <IconButton
                disabled={isLoading}
                style={{
                  height: 8,
                  width: 8,
                  border: `1px solid ${COLORS.blue}`,
                }}
                onClick={() => {
                  shiftDown(id, meal.everyDay);
                }}
              >
                <ArrowDownward style={{ color: COLORS.blue, height: 16 }} />
              </IconButton>
            </td>
          </div>
        )}
        <td>
          <CustomTextField
            disabled={isLoading}
            placeholder={t("menuEdit:" + meal.type)}
            value={
              meal.type === "soup"
                ? t("menuEdit:soup")
                : t("menuEdit:" + meal.name)
            }
            onChange={(e) => {
              if (meal.everyDay) {
                updateEveryDayMeal(id, {
                  ...meal,
                  name: e.target.value ?? " ",
                });
              } else {
                updateMeal(id, {
                  ...meal,
                  name: e.target.value ?? " ",
                });
              }
            }}
            variant="outlined"
            style={{
              display: "flex",
              maxWidth: "350px",
            }}
          />
        </td>

        <td>
          <CustomTextField
            disabled={isLoading}
            placeholder={t("menuEdit:description")}
            value={meal.description}
            onChange={(e) => {
              if (meal.everyDay) {
                updateEveryDayMeal(id, {
                  ...meal,
                  description: e.target.value ?? " ",
                });
              } else {
                updateMeal(id, {
                  ...meal,
                  description: e.target.value ?? " ",
                });
              }
            }}
            variant="outlined"
            style={{ display: "flex", maxWidth: "100%" }}
          />
        </td>
        <td style={{ maxWidth: "100px" }}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <CustomTextField
              disabled={isLoading}
              placeholder={t("menuEdit:price")}
              variant="outlined"
              style={{
                width: "100%",
                visibility:
                  soupInPrice && meal.type === "soup" ? "hidden" : "visible",
              }}
              value={meal.price}
              type="number"
              onChange={(e) => {
                if (meal.everyDay) {
                  updateEveryDayMeal(id, {
                    ...meal,
                    price: e.target.value ?? " ",
                  });
                } else {
                  updateMeal(id, {
                    ...meal,
                    price: e.target.value ?? " ",
                  });
                }
              }}
            />
            <Typography
              display="inline"
              variant="body2"
              style={{
                fontWeight: "bold",
                visibility:
                  soupInPrice && meal.type === "soup" ? "hidden" : "visible",
              }}
            >
              {t("menuEdit:currency")}
            </Typography>
          </div>
        </td>
        {dayId !== 5 && dayId !== 6 && (
          <td>
            <CustomCheckbox
              disabled={isLoading}
              checked={!!meal?.everyDay}
              onChange={(e, value) => {
                onChangeMealEveryDay(meal, value);
              }}
            />
          </td>
        )}
        <td style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Tooltip title={"Smazat z tohoto dne"} placement="top">
            <IconButton
              disabled={isLoading}
              style={{
                height: 16,
                width: 16,
                color: COLORS.blue,
              }}
              onClick={() => {
                setMealToDelete({ id, everyDay: meal.everyDay });
                setOpenCancelDialog(true);
              }}
            >
              <CancelRounded />
            </IconButton>
          </Tooltip>

          {meal.everyDay && (
            <Tooltip title={"Rozkopírovat do všech dnů"} placement="top">
              <IconButton
                disabled={isLoading}
                style={{
                  height: 16,
                  width: 16,
                  color: COLORS.blue,
                }}
                onClick={() => {
                  propagateEveryDayMeal(meal);
                }}
              >
                <DynamicFeedRounded />
              </IconButton>
            </Tooltip>
          )}

          {meal.everyDay && (
            <Tooltip title={"Smazat ze všech dnů"} placement="top">
              <IconButton
                disabled={isLoading}
                style={{
                  height: 16,
                  width: 16,
                  color: COLORS.blue,
                }}
                onClick={() => {
                  onRemovePropagatedMealFromAllDays(meal);
                }}
              >
                <RemoveCircleOutline />
              </IconButton>
            </Tooltip>
          )}
        </td>
      </tr>
      <tr>
        <div style={{ marginRight: "5px" }}>
          <td></td>
          <td></td>
        </div>
        {isOpen ? (
          <td colSpan={4}>
            <AllergenSelector
              setIsOpen={setIsOpen}
              allergens={meal.allergens}
              onSave={(selectedAllergens) => {
                if (meal.everyDay) {
                  updateEveryDayMeal(id, {
                    ...meal,
                    allergens: selectedAllergens,
                  });
                } else {
                  updateMeal(id, {
                    ...meal,
                    allergens: selectedAllergens,
                  });
                }
              }}
              id={id}
            />
          </td>
        ) : (
          <>
            <td>
              <Button
                disabled={isLoading}
                variant="contained"
                style={{
                  float: "left",
                  width: "fit-content",
                  marginBottom: 10,
                  marginRight: 10,
                  height: "fit-content",
                  backgroundColor: COLORS.primary,
                  color: COLORS.blue,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <AddCircle
                  style={{
                    color: COLORS.white,
                    marginRight: 6,
                    marginLeft: -10,
                  }}
                />
                {t("menuEdit:addAllergen")}
              </Button>
            </td>
            <td colSpan={5}>
              {meal?.allergens.map((alergen) => {
                return (
                  <Chip
                    label={t(`menuEdit:${alergen}`)}
                    size="small"
                    style={{
                      float: "left",
                      margin: " 4px",
                      fontWeight: "bold",
                      color: COLORS.blue,
                      backgroundColor: COLORS.primary,
                    }}
                  />
                );
              })}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export default Meal;
