import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomSwitch from "../../../components/CustomSwitch";
import AllergenCheckbox from "./AllergenCheckbox";
import { COLORS } from "../../../constants/colors";
import { CancelRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { saveFacility } from "../../../api/facility";

const ExportSettingsModal = (props) => {
  const { setOpen, open, onClose, facility, facilityId, revalidate } = props;
  const { t } = useTranslation();
  const [exportSettings, setExportSettings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setExportSettings({
      everydayMealsTop: facility?.exportSettings?.everydayMealsTop ?? false,
      showAllergensPrint: facility?.exportSettings?.showAllergensPrint ?? false,
      showAllergensInstagramPost:
        facility?.exportSettings?.showAllergensInstagramPost ?? false,
      showAllergensInstagramStory:
        facility?.exportSettings?.showAllergensInstagramStory ?? false,
      showAllergensGoogle:
        facility?.exportSettings?.showAllergensGoogle ?? false,
      showAllergensFacebook:
        facility?.exportSettings?.showAllergensFacebook ?? false,
    });
  }, [facility]);
  const onSave = () => {
    setIsLoading(true);
    saveFacility({ ...facility, exportSettings }, facilityId)
      .then(() => revalidate())
      .catch((erorr) => {
        console.log(erorr);
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  };

  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          backgroundColor: "white",
          width: "50%",
          borderRadius: "8px",
          padding: "32px",
          minHeight: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h5">{t("menuEdit:exportSettings")}</Typography>
          <IconButton
            style={{
              height: 32,
              width: 32,

              color: COLORS.blue,
            }}
            onClick={onClose}
          >
            <CancelRounded style={{ fontSize: 32 }} />
          </IconButton>
        </div>
        <Box
          style={{
            display: "flex",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
        >
          <CustomSwitch
            disabled={isLoading}
            onChange={() =>
              setExportSettings((prev) => {
                return { ...prev, everydayMealsTop: !prev?.everydayMealsTop };
              })
            }
            checked={exportSettings?.everydayMealsTop}
          />
          <Typography
            variant="h6"
            style={{ verticalAlign: "center", marginBottom: "1rem" }}
          >
            {t("menuEdit:showEveryDayMealOnTop")}
          </Typography>{" "}
        </Box>
        <Typography variant="h5">{t("menuEdit:showAllergensFor")}</Typography>
        <AllergenCheckbox
          disabled={isLoading}
          onChange={() =>
            setExportSettings((prev) => {
              return { ...prev, showAllergensPrint: !prev?.showAllergensPrint };
            })
          }
          checked={exportSettings?.showAllergensPrint}
          title={t("menuEdit:print")}
        />
        <AllergenCheckbox
          disabled={isLoading}
          onChange={() =>
            setExportSettings((prev) => {
              return {
                ...prev,
                showAllergensInstagramPost: !prev?.showAllergensInstagramPost,
              };
            })
          }
          checked={exportSettings?.showAllergensInstagramPost}
          title={t("menuEdit:instagramPost")}
        />
        <AllergenCheckbox
          disabled={isLoading}
          onChange={() =>
            setExportSettings((prev) => {
              return {
                ...prev,
                showAllergensInstagramStory: !prev?.showAllergensInstagramStory,
              };
            })
          }
          checked={exportSettings?.showAllergensInstagramStory}
          title={t("menuEdit:instagramStory")}
        />
        <AllergenCheckbox
          disabled={isLoading}
          onChange={() =>
            setExportSettings((prev) => {
              return {
                ...prev,
                showAllergensGoogle: !prev?.showAllergensGoogle,
              };
            })
          }
          checked={exportSettings?.showAllergensGoogle}
          title={"Google"}
        />
        <AllergenCheckbox
          disabled={isLoading}
          onChange={() =>
            setExportSettings((prev) => {
              return {
                ...prev,
                showAllergensFacebook: !prev?.showAllergensFacebook,
              };
            })
          }
          checked={exportSettings?.showAllergensFacebook}
          title={"Facebook"}
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            style={{
              margin: 10,
              backgroundColor: COLORS.primary,
              color: COLORS.white,
              fontWeight: "bold",
            }}
            onClick={() => onSave()}
            disabled={isLoading}
          >
            {t("menuEdit:save")}
            {isLoading && (
              <CircularProgress
                style={{
                  marginLeft: 10,
                  color: COLORS.white,
                }}
                size={14}
              />
            )}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ExportSettingsModal;
