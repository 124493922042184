import React from "react";

import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const AdminMain = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h5">{t('adminMain:title')}</Typography>
      <Typography variant="body2">
        {t('adminMain:desc')}
      </Typography>

      <ul>
        <li>
          <Link to="/types/">
            <Typography variant="body1">{t('types:title')}</Typography>
          </Link>
        </li>
        <li>
          <Link to="/localities/">
            <Typography variant="body1">{t('localities:title')}</Typography>
          </Link>
        </li>
        <li>
          <Link to="/newRegistrations/">
            <Typography variant="body1">{t('newRegistrations:title')}</Typography>
          </Link>
        </li>
        <li>
          <Link to="/facilitiesManager/">
            <Typography variant="body1">{t('facilitiesManager:title')}</Typography>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminMain;
