import firebase from "firebase/app";
import "firebase/firebase-storage";
import { firebaseAppAuth, firebaseEndpoint } from "../utils/firebase";
import "firebase/functions";

const storageRef = firebase.storage().ref();

const IMAGE_ENDPOINT = `${firebaseEndpoint}/imageEndpoint`;
const RESTAURANT_PROFILES_DIR = "facilityProfiles/";
const RESTAURANT_PHOTOS_DIR = "facilityPhotos/";
const POSTS_PHOTOS_DIR = "postPhotos/";
const NEWS_PHOTOS_DIR = "newsPhotos/";
const PDF_DIR = "pdf/";
const MSG_FILES_DIR = "messagesFiles/";

const STORAGE_ENDPOINT = `${firebaseEndpoint}/storageEndpoint`;

/**
 * Saves the photo into the local storage.
 * @param {File} file chosen by the user
 * @returns a promise with the filename
 */
export const uploadRestaurantPhoto = (file) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      reject({ message: "Pro přidávání fotek je nutné být přihlášený." });
    }

    const filetype = file.name.match(/.(jpg|jpeg|png)$/i);

    if (!filetype) {
      reject({ message: "Daný typ souboru není podporován." });
    }

    const filename = user.uid + new Date().valueOf() + filetype[0];

    const imageRef = storageRef.child(RESTAURANT_PHOTOS_DIR + filename);

    imageRef
      .put(file)
      .then(() => resolve(filename))
      .catch((error) => reject(error));
  });
};

export const uploadPostPhoto = (file) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      reject({ message: "Pro přidávání fotek je nutné být přihlášený." });
    }

    const filetype = file.name.match(/.(jpg|jpeg|png)$/i);

    if (!filetype) {
      reject({ message: "Daný typ souboru není podporován." });
    }

    const filename = user.uid + new Date().valueOf() + filetype[0];

    const imageRef = storageRef.child(POSTS_PHOTOS_DIR + filename);

    imageRef
      .put(file)
      .then(() => resolve(filename))
      .catch((error) => reject(error));
  });
};

export const uploadNewsPhoto = (file) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      reject({ message: "Pro přidávání fotek je nutné být přihlášený." });
    }

    const filetype = file.name.match(/.(jpg|jpeg|png)$/i);

    if (!filetype) {
      reject({ message: "Daný typ souboru není podporován." });
    }

    const filename = user.uid + new Date().valueOf() + filetype[0];

    const imageRef = storageRef.child(NEWS_PHOTOS_DIR + filename);

    imageRef
      .put(file)
      .then(() => resolve(filename))
      .catch((error) => reject(error));
  });
};

// TODO reduce copy-paste

/**
 * Saves the photo into the local storage.
 * @param {File} file chosen by the user
 * @returns a promise with the filename
 */
export const uploadRestaurantProfile = (file) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      reject({ message: "Pro přidávání fotek je nutné být přihlášený." });
    }

    const filetype = file.name.match(/.(jpg|jpeg|png)$/i);

    if (!filetype) {
      reject({ message: "Daný typ souboru není podporován." });
    }

    const filename = user.uid + new Date().valueOf() + filetype[0];

    const imageRef = storageRef.child(RESTAURANT_PROFILES_DIR + filename);

    imageRef
      .put(file)
      .then(() => resolve(filename))
      .catch((error) => reject(error));
  });
};

export const uploadMessageFile = (file) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      reject({ message: "Pro nahravani souboru je nutne byt prihlaseny" });
    }

    if (file.name.match(/.(exe|bat)$/i)) {
      //TODO: add some dangerous file extensions
      reject({ message: "Dany typ souboru neni podporovany" });
    }

    const filename = user.uid + new Date().valueOf() + file.name;

    const fileRef = storageRef.child(MSG_FILES_DIR + filename);

    fileRef
      .put(file)
      .then(() => resolve(filename))
      .catch((error) => reject(error));
  });
};

export const getRestaurantPicture = (filename, size = "large") =>
  `${IMAGE_ENDPOINT}?dir=facilityPhotos&file=${filename}&size=${size}`;

export const getRestaurantProfilePicture = (filename, size = "large") =>
  `${IMAGE_ENDPOINT}?dir=facilityProfiles&file=${filename}&size=${size}`;

export const getPostPicture = (filename, size = "large") =>
  `${IMAGE_ENDPOINT}?dir=postPhotos&file=${filename}&size=${size}`;

export const getNewsPicture = (filename, size = "large") =>
  `${IMAGE_ENDPOINT}?dir=newsPhotos&file=${filename}&size=${size}`;

export const getMsgFile = async (file) => {
  const user = firebaseAppAuth.currentUser;
  return `${STORAGE_ENDPOINT}?user=${user.uid}&file=${file}`;
};

export const getGdprPdf = () => {
  return new Promise((resolve, reject) => {
    storageRef
      .child(`${PDF_DIR}MOCK_gdpr.pdf`)
      .getDownloadURL()
      .then((url) => resolve(url))
      .catch((error) => reject(error));
  });
};

export const getTermsPdf = () => {
  return new Promise((resolve, reject) => {
    storageRef
      .child(`${PDF_DIR}MOCK_terms.pdf`)
      .getDownloadURL()
      .then((url) => resolve(url))
      .catch((error) => reject(error));
  });
};

export const getMultipleMsgFiles = async (files) => {
  const result = [];
  for (let i = 0; i < files.length; ++i) {
    const url = await getMsgFile(files[i]);
    result.push({ filename: files[i], url });
  }
  return result;
};
