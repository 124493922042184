import React from "react";
import { useTranslation } from "react-i18next";
import stringInject from "stringinject";

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";

import { addEditorToFacility, createEditorAccount } from "../../api/editors";

const NewUserStage = ({
  data,
  onClose,
  setStageData,
  facility,
  onEditorAdd,
}) => {
  const { t } = useTranslation();

  const handleFormSubmit = async () => {
    if (!data.name) {
      return alert(t("editors:insertNameAndSurname"));
    }

    const userData = await createEditorAccount(data.email, data.name, facility);
    await addEditorToFacility(userData.uid, facility);

    onEditorAdd(userData.uid);

    onClose();
  };

  return (
    <>
      <DialogContent>
        <Typography variant="body2">
          {stringInject(t("editors:createDescription"), [data.email])}
        </Typography>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <TextField
            name="name"
            type="text"
            placeholder={t("editors:nameAndSurname")}
            value={data.name}
            onChange={(e) =>
              setStageData((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
        <br />
        <Typography variant="caption">
          {t("editors:createExplanation")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("confirmDialog:cancel")}
        </Button>
        <Button
          color="primary"
          disabled={!data.name}
          onClick={handleFormSubmit}
        >
          {t("confirmDialog:accept")}
        </Button>
      </DialogActions>
    </>
  );
};

export default NewUserStage;
