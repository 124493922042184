import { useState, useEffect } from "react";

import { isAdmin } from "../api/user";
import useLoader from "./useLoader";

const useAdmin = (user) => {
  const [isUserAdmin, setIsUserAdmin] = useState(null);
  const { startLoading, endLoading } = useLoader();
  
  useEffect(() => {
    startLoading();
    isAdmin()
      .then((result) => setIsUserAdmin(result))
      .catch((error) => {
        console.error(error);
        setIsUserAdmin(null);
      })
      .finally(() => endLoading());
  }, [setIsUserAdmin, user]);

  return isUserAdmin;
};

export default useAdmin;
