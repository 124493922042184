import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

import { useFullFormStyles } from "../../../styles/form";
import Alert from "@material-ui/lab/Alert";

const formatTime = (time) => (time && time.seconds ? time.toDate() : time);

const PlanModal = ({ post, t, opened, closeEdit, saveEdit }) => {
  const [currentPost, setCurrentPost] = useState(post.data);
  const [error, setError] = useState(false);
  const classes = useFullFormStyles();

  useEffect(() => {
    setCurrentPost(post.data);
  }, [post]);

  const handleChange = (key, value) =>
    setCurrentPost((prev) => ({ ...prev, [key]: value }));

  const handleSave = () => {
    if (currentPost.plannedFrom && currentPost.plannedTo) {
      if (
        moment(formatTime(currentPost.plannedTo)).isBefore(
          moment(formatTime(currentPost.plannedFrom))
        )
      ) {
        setError(true);
        return;
      }
    }
    setError(false);
    saveEdit(currentPost, post.id);
  };
  return (
    <Dialog open={opened} onClose={closeEdit}>
      <DialogTitle>{t("facilityMain:posts.plan.title")}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container direction="row" spacing={1}>
            {/* TODO: errors */}
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                ampm={false}
                clearable
                okLabel={t("timeEditor:ok")}
                cancelLabel={t("timeEditor:cancel")}
                clearLabel={t("timeEditor:clear")}
                className={classes.formEntry}
                format="dd.MM.yyyy HH:mm"
                inputVariant="outlined"
                id="plannedFrom"
                label={t("addPost:items.planning.plannedFrom")}
                onChange={(date) => handleChange("plannedFrom", date)}
                value={
                  currentPost && currentPost.plannedFrom
                    ? formatTime(currentPost.plannedFrom)
                    : null
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                clearable
                okLabel={t("timeEditor:ok")}
                cancelLabel={t("timeEditor:cancel")}
                clearLabel={t("timeEditor:clear")}
                ampm={false}
                className={classes.formEntry}
                format="dd.MM.yyyy HH:mm"
                inputVariant="outlined"
                id="plannedTo"
                label={t("addPost:items.planning.plannedTo")}
                onChange={(date) => handleChange("plannedTo", date)}
                value={
                  currentPost && currentPost.plannedTo
                    ? formatTime(currentPost.plannedTo)
                    : null
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          className={classes.formEntry}
        >
          {t("facilityMain:posts.plan.save")}
        </Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        <Snackbar open={error}>
          <Alert severity="error">{t("addPost:wrongPlanDate")}</Alert>
        </Snackbar>
      </div>
    </Dialog>
  );
};

export default PlanModal;
