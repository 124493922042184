import {Button, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom/cjs/react-router-dom";
import ExportImageList from "../menuEdit/components/ExportImageList";
import ExportImageDetailModal from "../menuEdit/components/ExportImageDetailModal";
import {COLORS} from "../../constants/colors";
import {useTranslation} from "react-i18next";

const Export = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const images = location.state;

    const [hasImages, setHasImages] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState(false);

    useEffect(() => {
        setHasImages(
            [
                ...images?.WeekPrint,
                ...images?.InstagramPost,
                ...images?.InstagramStory,
                ...images?.GoogleMapsPost,
                ...images?.FacebookPost,
            ].filter((img) => {
                return img !== null && img !== undefined;
            }).length !== 0
        );
    }, [images]);

    const onOpen = (images) => {
        setSelectedImages(images);
        setOpen(true);
    };

    return (
        <div>
            <Button
                variant="contained"
                style={{
                    position: "fixed",
                    right: 30,
                    margin: 10,
                    backgroundColor: "rgb(0, 41, 60)",
                    color: COLORS.white,
                    fontWeight: "bold",
                }}
                onClick={() => history.goBack()}
            >
                {t("menuEdit:back")}
            </Button>
            <Typography variant="h1" style={{textTransform: 'capitalize'}}>{t("menuEdit:export")}</Typography>
            <Typography variant="body1" style={{paddingBottom: '15px', maxWidth: '880px'}}>{t("menuEdit:exportPageDescription")}</Typography>
            {hasImages ? (
                <>
                    <ExportImageList
                        title={t("menuEdit:print")}
                        images={images?.WeekPrint}
                        onOpen={onOpen}
                        weekMenuRequestData={images.WeekMenuRequestData}
                    />
                    <ExportImageList
                        title={`${t("menuEdit:menuDays")} ${t("menuEdit:instagramStory")}`}
                        images={images?.InstagramStory?.filter((img) => {
                            return img !== null;
                        })}
                        onOpen={onOpen}
                    />
                    <ExportImageList
                        title={`${t("menuEdit:menuDays")} ${t("menuEdit:instagramPost")}`}
                        images={images?.InstagramPost?.filter((img) => {
                            return img !== null;
                        })}
                        onOpen={setOpen}
                    />
                    <ExportImageList
                        title={`${t("menuEdit:menuDays")} Google`}
                        images={images?.GoogleMapsPost?.filter((img) => {
                            return img !== null;
                        })}
                        onOpen={onOpen}
                    />
                    <ExportImageList
                        title={`${t("menuEdit:menuDays")} Facebook`}
                        images={images?.FacebookPost?.filter((img) => {
                            return img !== null;
                        })}
                        onOpen={onOpen}
                    />
                    <ExportImageList
                        title={`${t("menuEdit:menuDays")} Tisk`}
                        individualDaysRequestData={images?.IndividualDaysRequestData}
                        images={images?.Print?.filter((img) => {
                            return img !== null;
                        })}
                        onOpen={onOpen}
                    />
                </>
            ) : (
                <Typography variant="h4">{t("menuEdit:exportMissing")}</Typography>
            )}
            <ExportImageDetailModal
                images={selectedImages}
                open={open}
                setOpen={setOpen}
            />
        </div>
    );
};

export default Export;
