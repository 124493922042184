import React from "react";

import { useTranslation } from "react-i18next";
import { DatePicker } from "@material-ui/pickers";

const CustomDatePicker = ({
  id,
  className,
  label,
  onChange,
  value,
  error,
  fullWidth = false,
}) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      id={id}
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      error={error}
      fullWidth={fullWidth}
      clearable
      okLabel={t("timeEditor:ok")}
      cancelLabel={t("timeEditor:cancel")}
      clearLabel={t("timeEditor:clear")}
      ampm={false}
      format="dd.MM.yyyy"
      inputVariant="outlined"
    />
  );
};

export default CustomDatePicker;
