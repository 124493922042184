import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CodeIcon from '@material-ui/icons/Code';

import { COLORS } from '../constants/colors';


const InDevel = () => {
    const { t } = useTranslation();

    return (
        <Paper
            style={{
                minHeight: '5rem',
                backgroundColor: COLORS.lightGrey,
                padding: '1rem',
                marginTop: '1rem'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <CodeIcon style={{ color: COLORS.white, opacity: '0.8' }} />
                <Typography variant='h6' style={{ color: COLORS.white, opacity: '0.8', marginLeft: '1rem' }}>
                    {t('inDevel:title')}
                </Typography>
            </div>
            <div>
                <Typography variant='body2' style={{ color: COLORS.white, opacity: '0.8', marginTop: '1rem' }} >
                    {t('inDevel:desc')}
                </Typography>

            </div>
        </Paper>
    );

};

export default InDevel;