import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { CircularProgress, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { loaderStyle } from "./PicPreview";

// based on this example: https://codesandbox.io/s/mmrp44okvj

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 ${grid}px 0 0`,
  boxShadow: isDragging ? "5px 5px 15px 0px rgba(132,132,132,0.75)" : "",
  position: "relative",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#dcdcdc" : "transparent",
  display: "flex",
  padding: grid,
  overflow: "auto",
});

export default class DraggableGallery extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onLoad = this.onLoad.bind(this);

    this.state = {
      loadedPics: new Set(),
    };
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    this.props.setItems(items);
  }

  onDelete(item) {
    const items = this.props.items.filter((i) => i !== item);
    this.props.setItems(items);
  }

  onLoad(item) {
    this.setState({
      ...this.state,
      loadedPics: new Set([...this.state.loadedPics, item]),
    });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              id={this.props.id}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {this.props.items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <img
                        src={this.props.urlMapper(item, "tiny")}
                        style={{
                          width: 320,
                          height: this.state.loadedPics.has(item) ? 180 : 0,
                          objectFit: "cover",
                        }}
                        onLoad={() => this.onLoad(item)}
                      />
                      {!this.state.loadedPics.has(item) && (
                        <div
                          style={{
                            width: 320,
                            height: 180,
                            ...loaderStyle,
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                      <IconButton
                        onClick={() => this.onDelete(item)}
                        size="small"
                        style={{
                          color: "#808080",
                          backgroundColor: "white",
                          position: "absolute",
                          right: 7,
                          top: 7,
                          border: "1px solid #808080",
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
