import { firestore, firebaseAppAuth } from "../utils/firebase";

/**
 * Listens for updates on the post types collection and
 * passes the fresh data to the callback each time the data changes.
 * @param {Function} callback function that accepts the data array as an input
 * @returns {Function} unsubscribe handler
 */
export const subscribePostTypes = (callback) => {
  return firestore
    .collection("postTypes")
    .where("active", "==", true)
    .onSnapshot((querySnapshot) => {
      const types = [];
      querySnapshot.forEach((doc) => {
        const type = {
          ...doc.data(),
          id: doc.id,
        };
        types.push(type);
      });
      callback(types);
    });
};

/**
 * Adds a new post type into the collection.
 * @param {String} sysid
 * @param {String} name
 *
 * @returns {Promise}
 */
export const addPostType = (sysid, name, description) => {
  return firestore
    .collection("postTypes")
    .doc()
    .set({ sysid, name, description, active: true });
};

/**
 * Marks the post type as inactive.
 * @param {String} id
 *
 * @returns {Promise}
 */
export const deletePostType = (id) => {
  return firestore.collection("postTypes").doc(id).update({ active: false });
};

/**
 * Updates the post type data.
 * @param {String} id
 * @param {String} sysid
 * @param {String} name
 * @param {String} description
 *
 * @returns {Promise}
 */
export const updatePostType = (id, sysid, name, description) => {
  return firestore
    .collection("postTypes")
    .doc(id)
    .update({ sysid, name, description });
};


export const getPostTypes = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("postTypes")
      .where("active", "==", true)
      .get()
      .then((data) => {
        const types = [];
        data.forEach((entry) => types.push({ id: entry.id, ...entry.data() }));
        resolve(types);
      })
      .catch((error) => reject(error));
  });
};

export const savePost = (post, facilityId) => {
  const actualPost = {
    facility: facilityId,
    created: new Date(),
    deleted: false,
    notified: false,
    couponActive: true,
  };
  for (const key in post) {
    if (post[key] !== null && post[key] !== undefined){
      actualPost[key] = post[key];
    }
  }
  return firestore
    .collection('posts')
    .doc()
    .set(actualPost);
};

export const getAllPostsForFacility = facilityId => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('posts')
      .get()
      .then(data => {
        const result = [];
        data.forEach(entry => {
          const post = entry.data();
          if (post.facility === facilityId && !post.deleted) {
            result.push({ id: entry.id, data: post });
          }
          resolve(result);
        })
      })
      .catch(error => reject(error));
  });
};

export const deletePost = id => {
  return firestore.collection("posts").doc(id).update({ deleted: true });
};

export const saveEditPost = (post, id) => {
  return firestore
    .collection('posts')
    .doc(id)
    .update({ ...post });
};