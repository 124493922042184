import React, { useState } from "react";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import PostPhotosPicker from "../PostPhotosPicker";
import { DescriptionField } from "../../../components/facility";
import CustomDatePicker from "../../../components/CustomDatePicker";

const WizardStepOne = ({
  classes,
  changeStep,
  post,
  handleChange,
  t,
  postTypes,
  errors,
}) => {
  const [warning, setWarning] = useState("");

  const handleStepChange = () => {
    if (post.from && post.to && post.from > post.to) {
      return setWarning(t("addPost:errors.toAfterFrom2"));
    }

    setWarning("");
    changeStep(1);
  };

  return (
    <>
      <TextField
        className={classes.formEntry}
        value={post.title}
        onChange={(event) => handleChange("title", event.target.value)}
        type="text"
        variant="outlined"
        fullWidth
        name="title"
        error={errors ? !!errors.title : false}
        label={t("addPost:items.title")}
      />
      <Select
        id="type"
        className={classes.formEntry}
        value={post.type}
        variant="outlined"
        error={errors ? !!errors.type : false}
        onChange={(event) => handleChange("type", event.target.value)}
        displayEmpty
        fullWidth
        renderValue={
          !!post.type
            ? undefined
            : () => (
                <div style={{ opacity: "0.87" }}>{t("addPost:items.type")}</div>
              )
        }
      >
        {postTypes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((type) => (
            <MenuItem value={type.id} key={type.id}>
              {type.name}
            </MenuItem>
          ))}
      </Select>
      {!!post.type && (
        <div style={{ marginLeft: 15, marginRight: 15 }}>
          <Typography variant="caption">
            {postTypes.find((p) => p.id === post.type).description}
          </Typography>
        </div>
      )}
      <Typography className={classes.formEntry}>
        <b>{t("addPost:items.photos")}</b>
      </Typography>
      <PostPhotosPicker
        urls={post.photos}
        setUrls={(newPhotos) => handleChange("photos", newPhotos)}
      />
      <div
        className={classes.formEntry}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          color="primary"
          checked={post.useProfile}
          onChange={(event) => handleChange("useProfile", event.target.checked)}
        />
        <Typography>{t("facilityMain:posts.useProfile")}</Typography>
      </div>
      <DescriptionField
        value={post.description}
        setValue={(val) => handleChange("description", val)}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomDatePicker
          id="dateFrom"
          className={classes.formEntry}
          label={t("addPost:items.dateFrom")}
          onChange={(date) => handleChange("from", date)}
          value={post.from}
          error={errors ? !!errors.dateFrom : false}
        />
        <CustomDatePicker
          id="dateTo"
          className={classes.formEntry}
          label={t("addPost:items.dateTo")}
          onChange={(date) => handleChange("to", date)}
          value={post.to}
          error={errors ? !!errors.dateTo : false}
        />
      </div>
      {warning && (
        <div style={{ color: "red", margin: 10 }}>
          <Typography variant="body2">{warning}</Typography>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleStepChange}
          className={classes.formEntry}
        >
          {t("addPost:continue")}
        </Button>
      </div>
    </>
  );
};

export default WizardStepOne;
