import React from "react";

import { getPostPicture, uploadPostPhoto } from "../../api/storage";

import { PhotosPicker } from "../../components";

const PostPhotosPicker = ({ urls, setUrls }) => {
  return (
    <PhotosPicker
      urls={urls}
      setUrls={setUrls}
      urlMapper={getPostPicture}
      uploadHandler={uploadPostPhoto}
      variant="minimal"
    />
  );
};

export default PostPhotosPicker;
