import React from "react";

import useCountries from "./useCountries";
import useFacilityTypes from "./useFacilityTypes";
import useLocalities from "./useLocalities";
import useMembershipTypes from "./useMembershipTypes";

let BasicDataContext;
let { Provider } = (BasicDataContext = React.createContext());

export const useBasicDataContext = () => React.useContext(BasicDataContext);

export default function BasicDataProvider({ addditionalData, children }) {
  const [countries] = useCountries();
  const facilityTypes = useFacilityTypes();
  const membershipTypes = useMembershipTypes();
  const localities = useLocalities();

  return (
    <Provider
      value={{
        countries,
        facilityTypes,
        membershipTypes,
        localities,
        ...addditionalData,
      }}
    >
      {children}
    </Provider>
  );
}
