import React from 'react';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from "react-i18next";

import { COLORS } from '../../../constants/colors';


const SuccessDraft = ({ opened, onClose }) => {
    const { t } = useTranslation();
    const mobileView = window.innerWidth < 650;
    const marginTop = mobileView ? '1rem' : '2rem';
    return (
        <Dialog
            open={opened}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '80rem',
                    height: '40rem',
                    borderRadius: '10px'
                }
            }}
            maxWidth={false}
            style={{
                zIndex: 20000
            }}
        >
            <Grid
                container
                direction='row'
                align='center'
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: COLORS.blue }} fontSize='large' />
                        </IconButton>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <Typography variant={mobileView ? 'h3' : 'h1'} style={{ color: COLORS.blue, fontWeight: 'bolder' }}>
                        {t('newFacility:success.title')}
                    </Typography>
                    <CheckCircleIcon
                        color='primary'
                        style={{
                            fontSize: `${mobileView ? '6' : '10'}rem`,
                            marginTop: marginTop
                        }}
                    />
                    <Typography
                        variant={mobileView ? 'h6' : 'h5'}
                        style={{
                            color: COLORS.blue,
                            marginTop: marginTop,
                            maxWidth: '60%',
                            textAlign: 'center'
                        }}
                    >
                            {t('newFacility:success.desc1')}
                            <span style={{ color: COLORS.primary, fontWeight: 'bolder' }}>
                                {t('newFacility:success.desc2')}
                            </span>
                            {t('newFacility:success.desc3')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <img
                        src='logo_color_dark.svg'
                        style={{
                            height: marginTop,
                            marginRight: marginTop,
                            marginBottom: mobileView ? '0.5rem' : '1.5rem',
                            marginTop: marginTop
                        }}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default SuccessDraft;