import React from 'react';
import { useLocation } from 'react-router-dom';

import ChangePassword from './ChangePassword';
import ConfirmEmail from './ConfirmEmail';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const EmailHandler = () => {
    let query = useQuery();

    const mode = query.get('mode');
    const oobCode = query.get('oobCode');

    if (mode === 'resetPassword') {
        return <ChangePassword oobCode={oobCode} />;
    }

    return <ConfirmEmail oobCode={oobCode} />;
};

export default EmailHandler;