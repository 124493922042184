import {Button, Dialog, IconButton, Typography} from "@material-ui/core";
import {CancelRounded} from "@material-ui/icons";
import React from "react";
import {COLORS} from "../../../constants/colors";

const ConfirmDialog = (props) => {
    const {open, onClose, onAgree, t, text} = props;
    return (
        <Dialog
            // chybova hlaska: React does not recognize the `BackdropStyle` prop on a DOM element.
            // BackdropStyle={{
            //     style: {
            //         opacity: 0.5,
            //         boxShadow: "none",
            //     },
            // }}
            open={open}
            onClose={onClose}
            style={{
                display: "block",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "space-between",
                }}
            >
                <Typography style={{marginRight: 64}} variant="h6" dangerouslySetInnerHTML={{__html: text}}>
                </Typography>
                <IconButton
                    style={{
                        height: 32,
                        width: 32,

                        color: COLORS.blue,
                    }}
                    onClick={() => {
                        onClose();
                    }}
                >
                    <CancelRounded style={{fontSize: 32}} />
                </IconButton>
            </div>
            <div style={{justifyContent: "right", display: "flex", margin: 20}}>
                { onClose &&
                    <Button
                        variant="contained"
                        style={{
                            margin: 10,
                            backgroundColor: COLORS.primary,
                            color: COLORS.white,
                            fontWeight: "bold",
                        }}
                        onClick={() => onClose()}
                    >
                        {t("menuEdit:disagree")}
                    </Button>
                }
                <Button
                    variant="contained"
                    style={{
                        margin: 10,
                        backgroundColor: COLORS.primary,
                        color: COLORS.white,
                        fontWeight: "bold",
                    }}
                    onClick={() => onAgree()}
                >
                    {onClose ? t("menuEdit:agree") : 'OK'}
                </Button>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
