import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useFullFormStyles } from "../../styles/form";

const Option = ({ name, checked, onChange }) => {
  return (
    <Typography variant="body2">
      <input type="checkbox" checked={checked} onChange={onChange} /> {name}
    </Typography>
  );
};

const TypeField = ({ options, selectedOptions, setOption }) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  return (
    <div className={classes.formEntry} style={{marginBottom: 20}}>
      <Typography variant="body2" style={{marginBottom: 10}}>{t("typeField:title")}</Typography>
      <Grid container>
        {options.map((ft) => (
          <Grid key={ft.id} item xs={12} sm={6} md={4}>
            <Option
              name={ft.name}
              checked={selectedOptions.includes(ft.id)}
              onChange={(e) => setOption(ft.id, e.target.checked)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TypeField;
