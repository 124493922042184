import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import SectionHeader from '../../components/SectionHeader';
import { COLORS } from '../../constants/colors';
import AddEditNewsCard from './components/AddEditNewsCard';
import { getNewsById } from '../../api/news';


const AddEditNews = ({}) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [news, setNews] = useState();

    useEffect(() => {
        if (id) {
            getNewsById(id)
                .then(data => setNews(data))
                .catch(error => console.log('error', error));
        }
    },[id])

    return (
        <>
            <SectionHeader>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                            {t('news:add.header.title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '1rem' }}>
                            {t('news:add.header.desc')}
                        </Typography>
                    </Grid>
                </Grid>
            </SectionHeader>
            <AddEditNewsCard news={news} isEditing={!!news} />
        </>
    );
};

export default AddEditNews;