import React from 'react';

import { useBasicDataContext } from '../../../hooks/useBasicData';
import { createLocalityAddButton } from '../../LocalityAddButton';
import LocalityTreeNode from '../../LocalityTreeNode';
import CardTemplate from '../../CardTemplate';


const LocalityCard = ({ title, onChange, facility, t, desc }) => {
    const { countries } = useBasicDataContext();

    // TODO choose a country for locality (rn it's CZ)
    const defaultCountry = countries && countries.length > 0 ? countries[0].id : undefined;

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            {defaultCountry && (
                <LocalityTreeNode
                    country={defaultCountry}
                    path={[]}
                    data={{ name: t("newFacility:add.allLocalities") }}
                    extraButton={createLocalityAddButton(
                        facility.localities,
                        localities => onChange('localities', localities)
                    )}
                />
            )}
        </CardTemplate>
    );
};

export default LocalityCard;