import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";

import { ADMIN_ROUTER_LINKS, MANAGER_ROUTER_LINKS } from './links';
import { COLORS } from '../../constants/colors';


const MobileMenu = ({ isAdmin, close, anchorEl, signOut, isManager, isEditor }) => {
    const { t } = useTranslation();

    const links = isAdmin ? ADMIN_ROUTER_LINKS : MANAGER_ROUTER_LINKS; //TODO: change this after more roles

    const menuDisabled = !isAdmin && !isManager && !isEditor;

    return (
        <Menu
            id='menu'
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={close}
            MenuListProps={{
                style: {
                    backgroundColor: COLORS.blue,
                }
            }}
        >
            {
                links.map(link => (
                    <MenuItem onClick={close} key={link.key}>
                        <Link to={menuDisabled ? '/' : link.to} style={{ textDecoration: "none" }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <link.Icon style={{ opacity: menuDisabled ? '0.5' : 1 }} />
                                <Typography
                                    variant='body2'
                                    style={{
                                        color: COLORS.white,
                                        marginLeft: '1rem',
                                        opacity: menuDisabled ? '0.5' : 1,
                                    }}
                                >
                                    {t(link.tPath)}
                                </Typography>
                            </div>
                        </Link>
                    </MenuItem>
                ))
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Button
                    onClick={signOut}
                    style={{
                        fontWeight: 'bold',
                        color: COLORS.white,
                        textTransform: 'none'
                    }}
                >
                    {t('main:signOut')}
                </Button>
            </div>
        </Menu>
    );
};

export default MobileMenu;