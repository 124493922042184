import { useState, useEffect } from "react";

import { isManager } from "../api/user";
import useLoader from "./useLoader";

const useManager = (user) => {
  const [isUserManager, setIsUserManager] = useState(null);
  const { startLoading, endLoading } = useLoader();

  useEffect(() => {
    startLoading();
    isManager()
      .then((result) => setIsUserManager(result))
      .catch((error) => {
        console.error(error);
        setIsUserManager(null);
      })
      .finally(() => endLoading());
  }, [setIsUserManager, user]);

  return isUserManager;
};

export default useManager;
