import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";

import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";

const PicPreview = ({ src, alt, className, style = {} }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!src || loading) {
      return;
    }

    setLoading(true);
  }, [src]);

  if (!src) {
    return (
      <div className={className} style={loaderStyle}>
        <PhotoSizeSelectActualIcon />
      </div>
    );
  }

  return (
    <>
      <img
        src={src}
        className={className}
        style={{ ...style, height: loading ? 0 : style.height }}
        alt={alt}
        onLoad={() => setLoading(false)}
      />
      {loading && (
        <div className={className} style={loaderStyle}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PicPreview;

export const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
