import React from "react";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline, Cancel } from "@material-ui/icons";

const LOCALITIES_LIMIT = 3;

export const createLocalityAddButton = (localities, setLocalities) => {
  const LocalityAddButton = ({ path }) => {
    if (path.length === 0) {
      return null;
    }

    const locId = path[path.length - 1];

    const handleDelete = () =>
      setLocalities(localities.filter((p) => p !== locId));
    const handleAdd = () => setLocalities([...localities, locId]);

    if (localities.includes(locId)) {
      return (
        <IconButton onClick={handleDelete}>
          <Cancel />
        </IconButton>
      );
    }

    if (localities.length >= LOCALITIES_LIMIT) {
      return null;
    }

    return (
      <IconButton onClick={handleAdd}>
        <AddCircleOutline />
      </IconButton>
    );
  };

  return LocalityAddButton;
};
