import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { COLORS } from "../../../constants/colors";
import AllergenCheckbox from "./AllergenCheckbox";
import { Done } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

//TO DO ->>>>>>>>>>>>> alergeny do eng !!!

const AllergenSelector = (props) => {
  const { setIsOpen, allergens, onSave } = props;
  const { t } = useTranslation();
  const [selectedAllergens, setSelectedAllergens] = useState(allergens);

  const alergeny = [
    "Gluten",
    "Crustaceans",
    "Eggs",
    "Fish",
    "Peanuts",
    "Soy",
    "Milk",
    "TreeNuts",
    "Celery",
    "Mustard",
    "Sesame",
    "SulphurDioxideAndSulphites",
    "Lupin",
    "Molluscs",
  ];

  return (
    <Box border={1} width={"100%"} borderRadius={24}>
      <Typography
        variant="h6"
        style={{
          backgroundColor: COLORS.orange,
          width: "fit-content",
          padding: "0 8px",
          margin: "16px 16px",
          borderRadius: 16,
        }}
      >
        {t("menuEdit:foodContainsAllergens")}
      </Typography>
      <Grid container>
        {alergeny.map((alergen, allergenId) => {
          return (
            <Grid item xs={4} md={3}>
              <AllergenCheckbox
                id={allergenId}
                title={t(`menuEdit:${alergen}`)}
                checked={selectedAllergens.includes(alergen)}
                onChange={(e, value) => {
                  value
                    ? setSelectedAllergens((prev) => [...prev, alergen])
                    : setSelectedAllergens((prev) => [
                        ...prev.filter(
                          (selectedAllergen) => selectedAllergen !== alergen
                        ),
                      ]);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Button
        variant="contained"
        style={{
          margin: "1rem  2rem",
          float: "right",
          backgroundColor: COLORS.primary,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => {
          onSave(selectedAllergens);
          setIsOpen(false);
        }}
      >
        <Done style={{ color: COLORS.white, marginRight: 6 }} />
        {t("menuEdit:save")}
      </Button>
    </Box>
  );
};

export default AllergenSelector;
