export const COLORS = {
    red: "#de3838",
    green: "#36b036",
    blue: '#00293c',
    primary: '#ff7b60',
    black: 'rgba(0, 0, 0, 0.87)',
    grey: '#222',
    white: '#fff',
    lightGrey: '#333',
    superLightGrey: '#EAEAEA',
    orange: '#d56d5a',
    surface: '#1111110f'
};
