import { firestore, firebaseAppAuth } from "../utils/firebase";

/**
 * Listens for updates on the membership types collection and
 * passes the fresh data to the callback each time the data changes.
 * @param {Function} callback function that accepts the data array as an input
 * @returns {Function} unsubscribe handler
 */
export const subscribeMembershipTypes = (callback) => {
  return firestore
    .collection("membershipTypes")
    .where("active", "==", true)
    .onSnapshot((querySnapshot) => {
      const types = [];
      querySnapshot.forEach((doc) => {
        const type = {
          ...doc.data(),
          id: doc.id,
        };
        types.push(type);
      });
      callback(types);
    });
};

/**
 * Returns a promise with the facility types.
 */
export const getMembershipTypes = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("membershipTypes")
      .where("active", "==", true)
      .get()
      .then((data) => {
        const types = [];
        data.forEach((entry) => types.push({ id: entry.id, ...entry.data() }));
        resolve(types);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Adds a new membership type into the collection.
 * @param {String} sysid
 * @param {String} name
 * @param {String} color
 *
 * @returns {Promise}
 */
export const addMembershipType = (sysid, name, description, color) => {
  return firestore
    .collection("membershipTypes")
    .doc()
    .set({ sysid, name, description, color, active: true });
};

/**
 * Marks the membership type as inactive.
 * @param {String} id
 *
 * @returns {Promise}
 */
export const deleteMembershipType = (id) => {
  return firestore
    .collection("membershipTypes")
    .doc(id)
    .update({ active: false });
};

/**
 * Updates the membership type data.
 * @param {String} id
 * @param {String} sysid
 * @param {String} name
 * @param {String} color
 * @param {string} description
 *
 * @returns {Promise}
 */
export const updateMembershipType = (id, sysid, name, color, description) => {
  return firestore
    .collection("membershipTypes")
    .doc(id)
    .update({ sysid, name, color, description });
};
