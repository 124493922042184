import { useState, useEffect, useCallback } from 'react';

import { getAllFacilities, getUserFacilities } from '../api/facility';
import { useBasicDataContext } from './useBasicData';
import useLoader from './useLoader';


const useFacilities = () => {
    const [facilities, setFacilities] = useState({});
    const { endLoading, startLoading } = useLoader()

    const { isAdmin } = useBasicDataContext()

    const loadAllFacilities = useCallback(() => {
        startLoading();
        if (!isAdmin) {
            getUserFacilities()
                .then((data) => setFacilities(data))
                .finally(() => endLoading)
        }

        getAllFacilities()
            .catch((error) => { console.log(error) })
            .then((data) => { setFacilities(data) });
        endLoading();
    }, [isAdmin])

    useEffect(() => {
        loadAllFacilities();

    }, [loadAllFacilities]);

    return { facilities, revalidate: loadAllFacilities };
};

export default useFacilities
