import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";

import CardTemplate from "../../../components/CardTemplate";
import { PhotosPicker } from "../../../components";
import { getNewsPicture, uploadNewsPhoto } from "../../../api/storage";
import { saveEditNews, saveNews } from "../../../api/news";

const MIN_ROWS = 3;

const getDescriptionRows = (text) => {
  const rows = text.split("\n").length;
  return Math.max(MIN_ROWS, rows);
};

const formatTime = (time) => (time && time.seconds ? time.toDate() : time);

const validate = ({ title, text, plannedFrom }) => {
  if (!title) {
    return "news:add.card.errors.noTitle";
  }
  if (!text) {
    return "news:add.card.errors.noDesc";
  }
  if (!plannedFrom) {
    return "news:add.card.errors.noPlanned";
  }
};

const AddEditNewsCard = ({ news, isEditing }) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (isEditing) {
      setTitle(news.data.title);
      setPhotos(news.data.photos);
      setText(news.data.text);
      setPlannedFrom(formatTime(news.data.plannedFrom));
    }
  }, [news]);

  const [title, setTitle] = useState("");
  const [photos, setPhotos] = useState([]);
  const [text, setText] = useState("");
  const [plannedFrom, setPlannedFrom] = useState(null);
  const [error, setError] = useState();

  const changeTitle = (e) => setTitle(e.target.value);
  const changePics = (pics) => setPhotos(pics);
  const changeText = (e) => setText(e.target.value);
  const changePlanned = (date) => setPlannedFrom(date);

  const closeError = () => setError();

  const handleSave = () => {
    const validationError = validate({ title, text, plannedFrom });
    if (validationError) {
      setError(validationError);
      return;
    }
    if (isEditing) {
      saveEditNews({ title, photos, text, plannedFrom }, news.id)
        .then(() => history.push("/news"))
        .catch((error) => {
          console.log("error", error);
          setError("news:add.card.errors.other");
        });
    } else {
      saveNews({ title, photos, text, plannedFrom })
        .then(() => history.push("/news"))
        .catch((error) => {
          console.log("error", error);
          setError("news:add.card.errors.other");
        });
    }
  };

  return (
    <CardTemplate
      title={t(`news:${isEditing ? "edit" : "add"}.card.title`)}
      desc={t(`news:${isEditing ? "edit" : "add"}.card.desc`)}
    >
      <Snackbar open={!!error} onClose={closeError}>
        <Alert severity="error" onClose={closeError}>
          {t(error)}
        </Alert>
      </Snackbar>
      <Grid container spacing={5} justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            name="title"
            onChange={changeTitle}
            label={t("news:add.card.name")}
            variant="outlined"
            value={title}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PhotosPicker
            urls={photos}
            setUrls={changePics}
            urlMapper={getNewsPicture}
            uploadHandler={uploadNewsPhoto}
            showText={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            name="content"
            onChange={changeText}
            label={t("news:add.card.text")}
            variant="outlined"
            value={text}
            style={{ width: "100%" }}
            multiline
            rows={getDescriptionRows(text)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DateTimePicker
            ampm={false}
            clearable
            okLabel={t("timeEditor:ok")}
            cancelLabel={t("timeEditor:cancel")}
            clearLabel={t("timeEditor:clear")}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            id="plannedFrom"
            label={t("news:add.card.plan")}
            onChange={changePlanned}
            value={plannedFrom}
          />
        </Grid>
        <Grid item>
          <Button color="primary" onClick={handleSave} variant="contained">
            {t(`news:${isEditing ? "edit" : "add"}.card.save`)}
          </Button>
        </Grid>
      </Grid>
    </CardTemplate>
  );
};

export default AddEditNewsCard;
