import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { subscribeFacilityTypes, deleteFacilityType } from "../../api/facility";
import useLoader from "../../hooks/useLoader";
import { useLoginFormStyles } from "../../styles/form";
import { ConfirmDialog } from "../../components";
import { FacilityTypesForm } from "../../components/form";

const FacilityTypes = () => {
  const [types, setTypes] = useState(null);
  const { startLoading, endLoading } = useLoader();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState({ open: false });
  const [form, setForm] = useState({ open: false });

  useEffect(() => {
    const callback = (newTypes) => {
      setTypes((prev) => {
        if (prev === null) {
          endLoading();
        }
        return newTypes;
      });
    };

    startLoading();
    const unsubscribe = subscribeFacilityTypes(callback);

    return () => unsubscribe();
  }, [setTypes]);

  const classes = useLoginFormStyles();

  const handleDeleteType = (id) => {
    const closeDialog = () => setDialog({ open: false });

    setDialog({
      title: t("facilityTypes:delete.title"),
      text: t("facilityTypes:delete.description"),
      open: true,
      onClose: closeDialog,
      onDecline: closeDialog,
      onAccept: () => {
        closeDialog();
        startLoading();
        deleteFacilityType(id)
          .catch((error) => {
            console.error(error);
            alert(t("facilityTypes:delete.error"));
          })
          .finally(() => endLoading());
      },
    });
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Typography variant="h6">{t("facilityTypes:title")}</Typography>
      <div className={classes.formEntry}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setForm({ open: true, edit: false })}
        >
          {t("facilityTypes:add.title")}
        </Button>
      </div>
      {types && (
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>{t("facilityTypes:name.raw")}</b>
                </TableCell>
                <TableCell>
                  <b>{t("facilityTypes:sysid.raw")}</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {types
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((type) => (
                  <TableRow key={type.sysid}>
                    <TableCell>{type.name}</TableCell>
                    <TableCell>{type.sysid}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setForm({ open: true, edit: true, values: type })
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteType(type.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ConfirmDialog {...dialog} />
      <FacilityTypesForm
        {...form}
        onClose={() => setForm({ ...form, open: false })}
      />
    </div>
  );
};

export default FacilityTypes;
