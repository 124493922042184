import React, { useState } from "react";

import { signIn } from "../../hooks/useAuth";
import { getErrorMessage } from "../../utils/authErrors";
import DefaultBackground from "./components/DefaultBackground";
import MainCard from "./components/MainCard";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    setError("");
    signIn(email, password).catch((error) =>
      setError(getErrorMessage(error.code))
    );
  };

  return (
    <form onSubmit={handleLogin}>
      <DefaultBackground>
        <MainCard
          isLogin={true}
          error={error}
          values={{
            email: email,
            password: password,
          }}
          setters={{
            setEmail: setEmail,
            setPassword: setPassword
          }}
        />
      </DefaultBackground>
    </form>
  );
};

export default Login;
