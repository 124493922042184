import React from 'react';

import CardTemplate from '../../CardTemplate';
import { FacilityPhotosPicker } from '../../facility';


const PicCard = ({ title, onChange, facility, desc }) => {

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <FacilityPhotosPicker
                urls={facility.photos}
                setUrls={photos => onChange('photos', photos)}
                showText={false}
            />
        </CardTemplate>
    );
};

export default PicCard;