import React from 'react';
import { Typography } from '@material-ui/core';


const TitleValue = ({ title, value, variant = 'body1', style }) => (
    <div
        key={`${title}_${value}`}
        style={{
            ...style,
            display: 'flex',
        }}
    >
        <Typography
            key='title'
            variant={variant}
            style={{
                fontWeight: 'bolder'
            }}
        >
            {title}:
        </Typography>
        <Typography
            key='value'
            variant={variant}
            style={{
                marginLeft: '1%'
            }}
        >
            {value}
        </Typography>
    </div>
);

export default TitleValue;