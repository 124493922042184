import React from "react";

import { Button, Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import FilesPicker from "../../../components/FilesPicker";

const NewMessageArea = ({
  idSuffix = "",
  isMobile,
  text,
  setText,
  images,
  setImages,
  files,
  setFiles,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "1rem" }}>
      <TextField
        value={text}
        onChange={setText}
        rows={isMobile ? 3 : 4}
        name="messageContent"
        label={t("messages:conversation.textArea")}
        variant="outlined"
        fullWidth
        multiline
      />
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: isMobile ? "center" : "space-between",
          marginTop: 10,
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: isMobile ? "space-between" : undefined }}
          >
            <Grid item>
              <FilesPicker
                id={"multipic-input" + idSuffix}
                text="messages:conversation.images"
                urls={images}
                setUrls={(newImages) => setImages(newImages)}
                showFilenames={!isMobile}
                style={{ marginLeft: 0 }}
              />
            </Grid>
            <Grid item>
              <FilesPicker
                id={"multifile-input" + idSuffix}
                text="messages:conversation.files"
                urls={files}
                setUrls={(newFiles) => setFiles(newFiles)}
                showFilenames={!isMobile}
                style={{ marginLeft: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={!text && !files.length && !images.length}
          >
            {t("messages:conversation.send")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewMessageArea;
