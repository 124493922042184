import { useState, useEffect } from 'react';

import useLoader from './useLoader';
import { getAllFacilities } from '../api/facility';


const useFacilitiesNames = () => {
    const [facilitiesNames, setFacilitiesNames] = useState({});
    const { startLoading, endLoading } = useLoader();

    const loadAllFacilitiesNames = async () => {
        startLoading();
        const result = {};
        const allFacilities = await getAllFacilities();
        for (const fac of allFacilities) {
            result[fac.id] = fac.data.name;
        }
        setFacilitiesNames(result);
        endLoading();
    }

    useEffect(() => {
        loadAllFacilitiesNames();

    }, [setFacilitiesNames]);

    return facilitiesNames;
};

export default useFacilitiesNames;
