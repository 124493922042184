import { useState, useEffect } from "react";

import { isEditor } from "../api/user";
import useLoader from "./useLoader";

const useEditor = (user) => {
  const [isUserEditor, setIsUserEditor] = useState(null);
  const { startLoading, endLoading } = useLoader();

  useEffect(() => {
    startLoading();
    isEditor()
      .then((result) => setIsUserEditor(result))
      .catch((error) => {
        console.error(error);
        setIsUserEditor(null);
      })
      .finally(() => endLoading());
  }, [setIsUserEditor, user]);

  return isUserEditor;
};

export default useEditor;
