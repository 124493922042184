import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FacilityTab from "./components/FacilityTab";
import useFacilities from "../../hooks/useFacilities";

const Menu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { facilities } = useFacilities();

  useEffect(() => {
    if (facilities.length === 1) {
      history.push(`/menuEdit/${facilities[0].id}`);
    }
  }, [facilities]);

  return (
    <>
      {facilities.length ? (
        facilities.map((f, id) => <FacilityTab key={id} data={f} />)
      ) : (
        <Typography>{t("menu:noFacility")}</Typography>
      )}
    </>
  );
};

export default Menu;
