import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";

import { COLORS } from '../../../constants/colors';


const EditFacilityHeader = ({ }) => {
    const { t } = useTranslation();

    const mobileView = window.innerWidth < 650;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: mobileView ? 'column' : 'row',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    //minWidth: '70%',
                    //maxWidth: '80%'
                }}
            >
                <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                    {t('editFacility:title')}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '1rem'
                    }}
                >
                    <Typography variant="body2" style={{ color: COLORS.blue }}>
                        {t('editFacility:desc.part1')}
                        <strong>{t('editFacility:desc.part2')}</strong>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default EditFacilityHeader;