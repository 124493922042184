import React from "react";

import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { Edit, Visibility, VisibilityOff } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { COLORS } from "../constants/colors";
import { useBasicDataContext } from "../hooks/useBasicData";

import CardTemplate from "./CardTemplate";
import LikesInfo from "../pages/facilityMain/components/LikesInfo";

const FacilityCard_New = ({ data, onShow, onHide }) => {
  const { t } = useTranslation();

  const { name, profilePhoto, localities, id, likes, pins, potential } = data;
  const { types, membership, hidden, countryId } = data;

  const {
    isAdmin,
    facilityTypes,
    membershipTypes,
    localities: localitySource,
  } = useBasicDataContext();

  const membershipType = membershipTypes.find((m) => m.id === membership);
  const membershipName = membershipType ? membershipType.name : "";

  return (
    <Link
      to={`/facilities/${id}`}
      style={{ cursor: "pointer", textDecoration: "none" }}
    >
      <CardTemplate
        title={
          <>
            {name}
            {!!isAdmin && (
              <>
                (
                <span style={{ color: COLORS.primary }}>
                  {likes ? likes : 0}
                </span>{" "}
                {t("facilityCard:followers2p")})
              </>
            )}
          </>
        }
        horizontalPadding="1rem"
        specialStyles={{ content: { paddingTop: "2rem" } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <LikesInfo
              facilityID={id}
              likes={likes}
              pins={pins}
              potential={potential}
              variant="body1"
              style={{ color: COLORS.lightGrey }}
              align="left"
            />
            <Typography
              variant="body1"
              style={{ color: COLORS.lightGrey }}
              align="left"
            >
              {`${t("facilityCard:status")}: ${membershipName}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {hidden ? (
                <IconButton onClick={onShow} title={t("facilityCard:show")}>
                  <Visibility style={{ color: COLORS.blue }} />
                </IconButton>
              ) : (
                <IconButton onClick={onHide} title={t("facilityCard:hide")}>
                  <VisibilityOff style={{ color: COLORS.blue }} />
                </IconButton>
              )}
              <Link
                to={`/facilityEdit/${id}`}
                style={{ textDecoration: "none" }}
              >
                <IconButton title={t("facilityMain:edit")}>
                  <Edit style={{ color: COLORS.blue }} />
                </IconButton>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Link to={`/addPost/${id}`} style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary">
                  {t("addPost:buttonTitle")}
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </CardTemplate>
    </Link>
  );
};

export default FacilityCard_New;
