import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { removeEditorFromFacility } from "../../../api/editors";
import { COLORS } from "../../../constants/colors";

const EditorCard = ({ id, editorsData, facility, handleRemove }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);

  const mobileView = window.innerWidth < 650;
  const margin = mobileView ? 0 : "calc(4rem - 10px)";

  const formatEditor = (id) => {
    const editorObj = editorsData.find((e) => e.id === id);
    if (!editorObj) {
      return "---";
    }

    return (
      <div>
        <Typography variant="body2">
          {t("contactField:justName")}: <strong>{editorObj.name}</strong>
        </Typography>
        <Typography variant="body2">
          {t("contactField:justEmail")}: <strong>{editorObj.email}</strong>
        </Typography>
      </div>
    );
  };

  return (
    <Paper
      style={{
        margin: 10,
        marginLeft: margin,
        marginRight: margin,
        padding: 10,
      }}
    >
      <Grid container justify="space-between">
        <Grid item>{formatEditor(id)}</Grid>
        <Grid item>
          {
            showConfirm ?
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '3rem'
              }}
            >
              <Typography variant='body1'>
                {t('editors:remove.yaSure')}
              </Typography>
              <Button
                onClick={() => removeEditorFromFacility(id, facility.id).then(() => handleRemove(id))}
                style={{
                  border: `2px solid ${COLORS.green}`,
                  marginLeft: '5px'
                }}
              >
                  {t('editors:remove.yes')}
              </Button>
              <Button
                  onClick={() => setShowConfirm(false)}
                  style={{
                    border: `2px solid ${COLORS.red}`,
                    marginLeft: '5px'
                  }}
              >
                {t('editors:remove.no')}
              </Button>
            </div>
            :
            <IconButton
              onClick={() => setShowConfirm(true)}
            >
              <Delete />
            </IconButton>
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditorCard;
