import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import { COLORS } from '../../../constants/colors';


const DefaultBackground = ({ children }) => {
    const [pic, setPic] = useState();

    useEffect(() => {
        const img = new Image();
        img.src = '/login_background.jpg';
        img.onload = () => setPic('/login_background.jpg');
      }, [])

    return (
        <Paper
            style={{
                width: '100%',
                position: 'absolute',
                height: '100%',
                background: 'url("/login_placeholder.jpg") no-repeat center center',
                backgroundSize: 'cover',
                borderRadius: 0,
                fill: '#3f566f'
            }}
        >
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backdropFilter: "blur(10px)",
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }}
            />
            {
                (pic) &&
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: 'url("/login_background.jpg") no-repeat center center',
                        backgroundSize: 'cover',
                        position: 'absolute',
                        top: '0px',
                        left: '0px'
                    }}
                />
            }
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: COLORS.blue,
                    opacity: '0.5'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: '0px',
                    left: '0px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {children}
            </div>
        </Paper>
    );
};

export default DefaultBackground;