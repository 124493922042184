export const facilityDefaults = {
  name: "",
  profilePhoto: "",
  photos: [],
  street: "",
  number: "",
  city: "",
  zipCode: "",
  country: "",
  countryId: "",
  localities: [],
  derivedLocalities: [],
  types: [],
  description: "",
  website: "",
  openingHours: [
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
  ],
  manager: "",
  hidden: false,
  deleted: false,
};

export const facilityDraftDefaults = {
  name: "",
  profilePhoto: "",
  photos: [],
  street: "",
  number: "",
  city: "",
  zipCode: "",
  country: "",
  countryId: "",
  localities: [],
  derivedLocalities: [],
  types: [],
  description: "",
  website: "",
  openingHours: [
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
    { from: "10:00", to: "22:00", closed: false },
  ],
  declined: false,
  contactName: "",
  contactEmail: "",
  gdpr: false,
  terms: false,
};

export const postDefaults = {
  title: "",
  type: "",
  photos: [],
  description: "",
  from: null,
  to: null,
  plannedFrom: null,
  plannedTo: null,
  deleted: false,
  created: null,
  useProfile: false,
};
