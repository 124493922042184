import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { addPostType, updatePostType } from "../../api/post";
import useLoader from "../../hooks/useLoader";
import { useLoginFormStyles } from "../../styles/form";
import { FormDialog } from "../../components";
import { DescriptionField } from "../../components/facility";

const PostTypesForm = ({ edit = false, open = false, onClose, values }) => {
  const classes = useLoginFormStyles();
  const { startLoading, endLoading } = useLoader();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sysid, setSysid] = useState("");
  useEffect(() => {
    setName(values ? values.name : "");
    setSysid(values ? values.sysid : "");
    setDescription(values ? values.description : "");
  }, [values]);

  const handleAddType = () => {
    startLoading();
    addPostType(sysid, name, description)
      .then(() => {
        setName("");
        setSysid("");
        setDescription("");
        onClose();
      })
      .catch((error) => {
        console.error(error);
        alert(t("postTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const handleEditType = () => {
    startLoading();
    updatePostType(values.id, sysid, name, description)
      .then(() => onClose())
      .catch((error) => {
        console.error(error);
        alert(t("postTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const onSave = edit ? handleEditType : handleAddType;

  return (
    <FormDialog
      title={
        edit ? t("postTypes:edit.formTitle") : t("postTypes:add.formTitle")
      }
      open={open}
      onClose={onClose}
      onSave={onSave}
    >
      <div className={classes.formEntry}>
        <TextField
          label={t("postTypes:sysid.fancy")}
          name="sysid"
          type="text"
          value={sysid}
          onChange={(e) => setSysid(e.target.value)}
          variant="outlined"
        />
      </div>
      <div className={classes.formEntry}>
        <TextField
          label={t("postTypes:name.fancy")}
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
        />
      </div>
      <DescriptionField value={description} setValue={setDescription} />
    </FormDialog>
  );
};

export default PostTypesForm;
