import React from 'react';
import { TextField } from '@material-ui/core';

import CardTemplate from '../../CardTemplate';
import { DescriptionField, TypeField } from '../../facility';
import { useBasicDataContext } from '../../../hooks/useBasicData';


const TYPES_LIMIT = 3;

const BasicInfoCard = ({ title, onChange, facility, t, desc }) => {
    const { facilityTypes } = useBasicDataContext();

    const handleTypeClick = (type, checked) => {
        const newTypes = facility.types.filter((t) => t !== type);
        if (checked && newTypes.length < TYPES_LIMIT) {
            newTypes.push(type);
        }
        onChange('types', newTypes);
    };

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <TextField
                name="name"
                onChange={e => onChange('name', e.target.value)}
                label={t('nameField:title')}
                variant='outlined'
                value={facility.name}
                style={{ width: '100%' }}
            />
            <TypeField
                options={facilityTypes}
                selectedOptions={facility.types}
                setOption={handleTypeClick}
            />
            <TextField
                name="website"
                onChange={e => onChange('website', e.target.value)}
                label={t('websiteField:title')}
                variant='outlined'
                value={facility.website}
                style={{ width: '100%' }}
            />
            <DescriptionField
                value={facility.description}
                setValue={value => onChange('description', value)}
            />
        </CardTemplate>
    );
};

export default BasicInfoCard;