import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, Snackbar, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import SectionHeader from '../../components/SectionHeader';
import { COLORS } from '../../constants/colors';
import { getNewsPageAfterDoc, removeNews } from '../../api/news';
import NewsCard from './components/NewsCard';
import useAuth from '../../hooks/useAuth';
import useAdmin from '../../hooks/useAdmin';
import { getUnseenNewsIds } from '../../api/manager';
import NewsDetail from './components/NewsDetail';


const News = ({ changeNewsCount }) => {
    const user = useAuth();
    const isAdmin = useAdmin(user);

    const { t } = useTranslation();
    const [render, setRender] = useState(0);
    const [news, setNews] = useState([]);
    const [lastSeenNews, setLastSeenNews] = useState();
    const [fstSeenNews, setFstSeenNews] = useState();
    const [isThereMore, setIsThereMore] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState();
    const [unseen, setUnseen] = useState([]);
    const scrollRef = useRef(null);

    const [newsDetail, setNewsDetail] = useState();

    useEffect(() => {
        getNewsPageAfterDoc(lastSeenNews, 'desc', isAdmin)
            .then(data => {
                setNews(data.data);
                setLastSeenNews(data.lastDoc);
                setFstSeenNews(data.fstDoc);
                setIsThereMore(data.isThereMore);
            })
            .catch(error => console.log('error', error));
    }, [render]);

    useEffect(() => {
        if (isAdmin !== null && isAdmin !== true) {
            getUnseenNewsIds()
                .then(ids => setUnseen(ids))
                .catch(error => console.log('error', error));
        }
    }, [isAdmin, render, newsDetail]);

    const doRemoveNews = id => {
        removeNews(id)
            .then(() => setRender(render + 1)) // to avoid full page refresh
            .catch(error => {
                console.log('error', error);
                setError('news:remove.error');
            });
    };

    const nextPageClick = () => {
        getNewsPageAfterDoc(lastSeenNews, 'desc', isAdmin)
            .then(data => {
                setNews(data.data);
                setLastSeenNews(data.lastDoc);
                setFstSeenNews(data.fstDoc);
                setIsThereMore(data.isThereMore);
                setPage(page + 1);
                scrollTop();
            })
            .catch(error => console.log('error', error));
    };

    const prevPageClick = () => {
        if (page === 1) {
            return; // one can never be too sure
        }
        getNewsPageAfterDoc(fstSeenNews, 'asc', isAdmin)
            .then(data => {
                setNews(data.data);
                setLastSeenNews(data.lastDoc);
                setFstSeenNews(data.fstDoc);
                setIsThereMore(true);
                setPage(page - 1);
            })
            .catch(error => console.log('error', error));
    }

    const scrollTop = () => scrollRef.current.scrollIntoView();

    const closeError = () => setError();

    const RemoveNews = ({ newsId }) => (
        <IconButton onClick={() => doRemoveNews(newsId)}>
            <DeleteForeverIcon
                fontSize='small'
                style={{
                    color: COLORS.red,
                }}
            />
        </IconButton>
    );

    const EditNews = ({ newsId }) => (
        <Link to={`/newsAdd/${newsId}`} style={{ textDecoration: "none" }}>
            <IconButton>
                <EditIcon
                    fontSize='small'
                    style={{
                        color: COLORS.white,
                        marginLeft: '1rem',
                    }}
                />
            </IconButton>
        </Link>
    );

    const openDetail = (clicked) => setNewsDetail(clicked);

    const closeDetail = () => setNewsDetail();

    const Content = () => (
        <>
            <SectionHeader>
                <Grid container ref={scrollRef}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                            {t('news:header.title')}
                        </Typography>
                    </Grid>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '1rem' }}>
                                {t('news:header.desc')}
                            </Typography>
                        </Grid>
                        {
                            isAdmin &&
                            <Grid item>
                                <Link to="/newsAdd" style={{ textDecoration: "none" }}>
                                    <Button variant="contained" color="primary">
                                        {t('news:header.add')}
                                    </Button>
                                </Link>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </SectionHeader>
            <Snackbar open={!!error} onClose={closeError}>
                <Alert severity='error' onClose={closeError}>{t(error)}</Alert>
            </Snackbar>
            {
                news.map((newsItem, index) =>
                    <NewsCard
                        changeNewsCount={changeNewsCount}
                        news={newsItem}
                        key={index}
                        highlight={unseen.includes(newsItem.id)}
                        openDetail={openDetail}
                        additionalComponents={isAdmin ?
                            [
                                () => <EditNews newsId={newsItem.id} />,
                                () => <RemoveNews newsId={newsItem.id} />,
                            ]
                            :
                            []
                        }
                    />
                )
            }
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '2rem'
                }}
            >
                <IconButton onClick={prevPageClick} disabled={page === 1}>
                    <ChevronLeftIcon style={{ fontSize: '2rem', color: page === 1 ? COLORS.white : COLORS.blue }} />
                </IconButton>
                <Typography variant='body2' style={{ color: COLORS.blue }}>
                    {page}
                </Typography>
                <IconButton onClick={nextPageClick} disabled={!isThereMore}>
                    <ChevronRightIcon style={{ fontSize: '2rem', color: isThereMore ? COLORS.blue : COLORS.white }} />
                </IconButton>
            </div>
        </>
    );

    return (
        newsDetail ?
            <NewsDetail
                backTextPath='news:detail.back'
                news={newsDetail}
                closeDetail={closeDetail}
            /> :
            <Content />
    );
};

export default News;