
const useDayMenu = (weekMenuData, setWeekMenuData, dayId) => {

    const shiftUp = (id, isEveryDayMeal) => {
        if (id === 0) {
            return
        }

        if (isEveryDayMeal) {
            setWeekMenuData((prev) => {
                const days = prev.days.map((day, _dayId) => {
                    if (_dayId === dayId) {
                        day.everyDayMeals.splice(id - 1, 0, day.everyDayMeals.splice(id, 1)[0])
                        return {
                            ...day, everyDayMeals: day.everyDayMeals
                        }
                    }
                    return day
                })
                return { ...prev, days };
            });
            return
        }

        setWeekMenuData((prev) => {
            const days = prev.days.map((day, _dayId) => {
                if (_dayId === dayId) {
                    day.meals.splice(id - 1, 0, day.meals.splice(id, 1)[0])
                    return {
                        ...day, meals: day.meals
                    }
                }
                return day
            })
            return { ...prev, days };
        });

    };

    const shiftDown = (id, isEveryDayMeal) => {
        if (isEveryDayMeal) {

            if (id === weekMenuData.days[dayId].everyDayMeals.length - 1) {
                return
            }
            setWeekMenuData((prev) => {
                const days = prev.days.map((day, _dayId) => {
                    if (_dayId === dayId) {
                        day.everyDayMeals.splice(id + 1, 0, day.everyDayMeals.splice(id, 1)[0])
                        return {
                            ...day, everyDayMeals: day.everyDayMeals
                        }
                    }
                    return day
                })
                return { ...prev, days };
            });
            return
        }
        if (id === weekMenuData.days[dayId].meals.length - 1) {
            return
        }
        setWeekMenuData((prev) => {

            const days = prev.days.map((day, _dayId) => {
                if (_dayId === dayId) {
                    day.meals.splice(id + 1, 0, day.meals.splice(id, 1)[0])
                    return {
                        ...day, meals: day.meals
                    }
                }
                return day
            })
            return { ...prev, days };
        });

    };

    const propagateEveryDayMeal = (meal) => {     //prvat not saved na ostatní dny
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return day;
                const canPropagateToDay = id > dayId && id < 5 && !day.noDailyMenu && !day.everyDayMeals.map(e => e.description).includes(meal.description)
                if (!canPropagateToDay) return day;
                return { ...day, everyDayMeals: [...day.everyDayMeals, { ...meal, everyDay: true }] }
            })
            return { ...prev, days };
        });
    }

    const removePropagatedMealFromAllDays = (meal) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                return { ...day, everyDayMeals: [...day.everyDayMeals].filter((_meal) => _meal.description !== meal.description) };
            })
            return { ...prev, days };
        });
    }

    const onChangeMealEveryDay = (meal, value) => {
        if (value) {
            setWeekMenuData((prev) => {
                const days = prev.days.map((day, id) => {
                    if (id === dayId) return { ...day, meals: [...day.meals].filter((_meal) => JSON.stringify(_meal) !== JSON.stringify(meal)), everyDayMeals: [...day.everyDayMeals, { ...meal, everyDay: true }] };
                    return day;
                })
                return { ...prev, days };
            });
            return
        }
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, meals: [...day.meals, { ...meal, everyDay: false }], everyDayMeals: [...day.everyDayMeals].filter((_meal) => JSON.stringify(_meal) !== JSON.stringify(meal)) };
                return day;
            })
            return { ...prev, days };
        });

    }


    const addSoup = () => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, meals: [...day.meals, { type: "soup", name: "", description: "", price: 0, everyDay: false, allergens: [], isPropagated: false }] };
                return day;
            })
            return { ...prev, days };
        });
    };

    const addMeal = () => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, meals: [...day.meals, { type: "meal", name: "", description: "", price: 0, everyDay: false, allergens: [], isPropagated: false }] };
                return day;
            })
            return { ...prev, days };
        });
    };

    const updateMeal = (id, meal) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, _dayId) => {
                if (_dayId === dayId) return {
                    ...day, meals: [...day.meals].map((item, index) => {
                        if (index === id) return meal;
                        return item;
                    })
                }
                return day
            })
            return { ...prev, days };
        });
    }

    const updateEveryDayMeal = (id, meal) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, _dayId) => {
                if (_dayId === dayId) return {
                    ...day, everyDayMeals: [...day.everyDayMeals].map((item, index) => {
                        if (index === id) return meal;
                        return item;
                    })
                }
                return day
            })
            return { ...prev, days };
        });
    }

    const setSoupInPrice = (value) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, soupInPrice: value };
                return day;
            })
            return { ...prev, days };
        });
    }

    const setNoDailyMenu = (value) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, noDailyMenu: value };
                return day;
            })
            return { ...prev, days };
        });
    }

    const removeAllMeals = () => {
        setMeals([]);
        setEveryDayMeals([]);
    }

    const setMeals = (value) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, meals: value };
                return day;
            })
            return { ...prev, days };
        });
    }

    const setEveryDayMeals = (value) => {
        setWeekMenuData((prev) => {
            const days = prev.days.map((day, id) => {
                if (id === dayId) return { ...day, everyDayMeals: value };
                return day;
            })
            return { ...prev, days };
        });
    }

    const removeMeal = (id, isEveryDayMeal) => {
        if (isEveryDayMeal) {
            setWeekMenuData((prev) => {
                const days = prev.days.map((day, _dayId) => {
                    if (_dayId === dayId) return {
                        ...day, everyDayMeals: [...day.everyDayMeals].filter((item, index) => {
                            return index !== id;
                        })
                    }
                    return day
                })
                return { ...prev, days };
            })
            return
        }

        setWeekMenuData((prev) => {
            const days = prev.days.map((day, _dayId) => {
                if (_dayId === dayId) return {
                    ...day, meals: [...day.meals].filter((item, index) => {
                        return index !== id;

                    })

                }
                return day
            })
            return { ...prev, days };
        })

    };

    return {
        shiftUp,
        shiftDown,
        removeMeal,
        addMeal,
        addSoup,
        onChangeMealEveryDay,
        propagateEveryDayMeal,
        removePropagatedMealFromAllDays,
        setEveryDayMeals,
        setMeals,
        setNoDailyMenu,
        setSoupInPrice,
        updateMeal,
        updateEveryDayMeal,
        removeAllMeals
    };
}
export default useDayMenu
