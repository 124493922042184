import { firestore, firebaseAppAuth } from "../utils/firebase";
import { addManager, getOwnedFacilities } from "./manager";
import { getEditedFacilities } from "./editors";

/**
 * Listens for updates on the facility types collection and
 * passes the fresh data to the callback each time the data changes.
 * @param {Function} callback function that accepts the data array as an input
 * @returns {Function} unsubscribe handler
 */
export const subscribeFacilityTypes = (callback) => {
  return firestore
    .collection("facilityTypes")
    .where("active", "==", true)
    .onSnapshot((querySnapshot) => {
      const types = [];
      querySnapshot.forEach((doc) => {
        const type = {
          ...doc.data(),
          id: doc.id,
        };
        types.push(type);
      });
      callback(types);
    });
};

/**
 * Returns a promise with the facility types.
 */
export const getFacilityTypes = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilityTypes")
      .where("active", "==", true)
      .get()
      .then((data) => {
        const types = [];
        data.forEach((entry) => types.push({ id: entry.id, ...entry.data() }));
        resolve(types);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Adds a new facility type into the collection.
 * @param {String} sysid
 * @param {String} name
 *
 * @returns {Promise}
 */
export const addFacilityType = (sysid, name) => {
  return firestore
    .collection("facilityTypes")
    .doc()
    .set({ sysid, name, active: true });
};

/**
 * Marks the facility type as inactive.
 * @param {String} id
 *
 * @returns {Promise}
 */
export const deleteFacilityType = (id) => {
  return firestore
    .collection("facilityTypes")
    .doc(id)
    .update({ active: false });
};

/**
 * Updates the facility type data.
 * @param {String} id
 * @param {String} sysid
 * @param {String} name
 *
 * @returns {Promise}
 */
export const updateFacilityType = (id, sysid, name) => {
  return firestore.collection("facilityTypes").doc(id).update({ sysid, name });
};

export const saveFacilityDraft = (draft, id) => {
  const user = firebaseAppAuth.currentUser;
  return firestore
    .collection("facilityDrafts")
    .doc(id || user.uid)
    .set(draft, { ignoreUndefinedProperties: true });
};

export const saveFacility = (facility, id) => {
  return firestore
    .collection("facilities")
    .doc(id)
    .set(facility, { ignoreUndefinedProperties: true });
};

export const getFacilityDraft = () => {
  const user = firebaseAppAuth.currentUser;
  return firestore.collection("facilityDrafts").doc(user.uid).get();
};

export const getFacilityDraftById = (id) => {
  return firestore.collection("facilityDrafts").doc(id).get();
};

export const isFacilityDeclined = () => {
  const user = firebaseAppAuth.currentUser;
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilityDrafts")
      .doc(user.uid)
      .get()
      .then((data) => {
        const info = data.data();
        if (!info) {
          resolve(false);
        }
        resolve(info.declined);
      })
      .catch((error) => reject(error));
  });
};

export const getAllFacilityDrafts = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilityDrafts")
      .get()
      .then(async (data) => {
        let result = [];
        data.forEach((entry) =>
          result.push({ id: entry.id, data: entry.data() })
        );
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

export const declineFacilityDraft = (id) => {
  return firestore
    .collection("facilityDrafts")
    .doc(id)
    .update({ declined: true });
};

const DEFAULT_MEMBERSHIP_SYSID = "standardtrial";

/**
 * Returns the default membership specified by the DEFAULT_MEMBERSHIP_SYSID value.
 * @returns the default membership object
 */
export const getDefaultMembership = async () => {
  const snapshot = await firestore
    .collection("membershipTypes")
    .where("sysid", "==", DEFAULT_MEMBERSHIP_SYSID)
    .get();

  if (snapshot.size === 1) {
    const membership = snapshot.docs[0];
    return { ...membership.data(), id: membership.id };
  }

  return null;
};

export const acceptFacilityDraft = async (values) => {
  const originalDraft = (await getFacilityDraftById(values.id)).data();
  const managerId = values.id;

  await addManager(values.id, {
    name: originalDraft.contactName,
    email: originalDraft.contactEmail,
  });

  const defaultMembership = await getDefaultMembership();
  const newFacility = {
    membership: defaultMembership ? defaultMembership.id : null,
    manager: managerId,
    editors: [],
    hidden: false,
    deleted: false,
    created: new Date(),
  };

  Object.keys(originalDraft).forEach((key) => {
    if (key !== "contactName" && key !== "contactEmail" && key !== "declined") {
      newFacility[key] = originalDraft[key];
    }
  });
  await firestore.collection("facilities").add(newFacility);
  return firestore.collection("facilityDrafts").doc(values.id).delete();
};

export const hideFacility = (id) => {
  return firestore.collection("facilities").doc(id).update({ hidden: true });
};

export const showFacility = (id) => {
  return firestore.collection("facilities").doc(id).update({ hidden: false });
};

export const deleteFacility = (id) => {
  return firestore.collection("facilities").doc(id).update({ deleted: true });
};

export const addFacility = (data) => {
  const user = firebaseAppAuth.currentUser;

  for (const key of Object.keys(data)) {
    if (typeof data[key] === undefined) {
      delete data[key];
    }
  }

  return new Promise((resolve, reject) => {
    firestore
      .collection("facilities")
      .add({ ...data, deleted: false, manager: user.uid, created: new Date() })
      .then((doc) => {
        resolve({
          ...data,
          id: doc.id,
        });
      })
      .catch((error) => reject(error));
  });
};

export const getAllFacilities = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilities")
      .get()
      .then((data) => {
        let result = [];
        data.forEach((entry) =>
          result.push({ id: entry.id, data: entry.data() })
        );
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

export const updateFacilityMembership = (facilityId, newMembershipId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilities")
      .doc(facilityId)
      .update({ membership: newMembershipId })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const getFacilityById = (id) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("facilities")
      .doc(id)
      .get()
      .then((data) => resolve(data.data()))
      .catch((error) => reject(error));
  });
};

export const getUserFacilities = async () => {
  const user = firebaseAppAuth.currentUser;

  if (!user) {
    return [];
  }

  const managerFacilities = await getOwnedFacilities();
  const editorFacilities = await getEditedFacilities();

  const allFacilities = [...managerFacilities];
  editorFacilities.forEach((facility) => {
    if (allFacilities.find((f) => f.id === facility.id)) {
      return;
    }

    allFacilities.push(facility);
  });

  return allFacilities.map((f) => ({ id: f.id, data: f }));
};
