import React, { useState } from 'react';
import { Card, CardContent, CardHeader, IconButton, Paper, Popover, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { COLORS } from '../constants/colors';


const GENERAL_CARD_PADDING = '4rem';

const PopoverContent = ({ desc }) => (
    <Paper
        style={{
            padding: '2rem',
            maxWidth: '30rem'
        }}
    >
        <Typography variant='body2'>
            {desc}
        </Typography>
    </Paper>
);


const CardTitle = ({ title, desc, mobileView, additionalComponents }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const onExit = () => {
        setAnchorEl(null);
    }

    return (
        <div
            style={{
                paddingLeft: mobileView ? "1.3rem" : GENERAL_CARD_PADDING,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
            }}
        >
            <Popover
                id='info'
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={onExit}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <PopoverContent desc={desc} />
            </Popover>
            <Typography
                variant='h6'
                style={{ color: COLORS.white }}
            >
                {title}
            </Typography>
            {
                desc &&
                <IconButton onClick={onClick}>
                    <InfoIcon
                        fontSize='small'
                        style={{
                            color: COLORS.white,
                            marginLeft: '0.5rem'
                        }}
                    />
                </IconButton>
            }
            {
                additionalComponents ?
                    additionalComponents.map((TheComponent, index) =>
                        <TheComponent key={index} />
                        ) :
                        null
            }
        </div>
    );
};


const CardTemplate = ({ title, children, desc, additionalComponents, specialStyles = {}, horizontalPadding = GENERAL_CARD_PADDING }) => {

    const mobileView = window.innerWidth < 650;

    return (
        <div
            style={{
                width: mobileView ? "100%" : `calc(100% - ${horizontalPadding} - ${horizontalPadding})`,
                paddingLeft: mobileView ?  undefined : horizontalPadding,
                paddingRight: mobileView ? undefined : horizontalPadding
            }}
        >
            <Card
                style={{
                    borderRadius: '10px',
                    marginTop: specialStyles ? specialStyles.marginTop || '3rem' : '3rem',
                    width: '100%',
                    ...specialStyles.card
                }}
            >
                <CardHeader
                    disableTypography
                    title={<CardTitle title={title} desc={desc} mobileView={mobileView} additionalComponents={additionalComponents} />}
                    style={{
                        backgroundColor: COLORS.blue,
                        paddingLeft: 0,
                        ...specialStyles.header
                    }}
                />
                <CardContent
                    style={{
                        paddingLeft: mobileView ?  undefined : GENERAL_CARD_PADDING,
                        paddingRight: mobileView ?  undefined : GENERAL_CARD_PADDING,
                        paddingBottom: '3rem',
                        paddingTop: '3rem',
                        ...specialStyles.content
                    }}
                >

                    {children}
                </CardContent>
            </Card>

        </div>
    );
};

export default CardTemplate;