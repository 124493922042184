import React, { useState } from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { COLORS } from '../../../constants/colors';
import NewConversationModal from './NewConversationModal';
import AdminNewConversationModal from './AdminNewConversationModal';
import { chooseSender, formatTime, showConversationTitle } from './utils';


const Conversations = ({
    isMobile, personal, setIsPersonal, t, userMessages, isAdmin, doSetConversation, newConversationCallback,
    userFacilities, selectedId
}) => {
    const [openModal, setOpenModal] = useState(false);

    const highlight = (msg) => {
        if (isAdmin) {
            if (msg.seenAdmin) {
                return false;
            }
            return true;
        }
        return !msg.seenUser;
    };

    const chooseMessages = () => {
        if (personal) {
            return userMessages.filter(msg => !msg.data.isFacility);
        }
        return userMessages.filter(msg => msg.data.isFacility);
    }

    const highlightSection = section => {
        const convs = userMessages.filter(conv => section === 'personal' ? !conv.data.isFacility : conv.data.isFacility);
        if (isAdmin) {
            return convs?.some(msg => !msg.data.seenAdmin);
        }
        return convs?.some(msg => !msg.data.seenUser);
    }

    const doOpenModal = () => setOpenModal(true);

    const closeModal = id => {
        setOpenModal(false);
        newConversationCallback(id);
    }

    return (
        <>
            {
                isAdmin ?
                    <AdminNewConversationModal
                        opened={openModal}
                        close={closeModal}
                        isMobile={isMobile}
                        t={t}
                    /> :
                    <NewConversationModal
                        opened={openModal}
                        close={closeModal}
                        isMobile={isMobile}
                        t={t}
                        userFacilities={userFacilities}
                    />
            }
            <div
                style={{
                    height: '1rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={doOpenModal}
            >
                <AddCircleOutlineIcon
                    style={{
                        color: COLORS.blue
                    }}
                />
                <Typography
                    variant='body1'
                    style={{
                        fontWeight: 'bolder',
                        color: COLORS.blue,
                        marginLeft: '0.5rem'
                    }}
                >
                    {t('messages:conversations.new')}
                </Typography>
            </div>
            <Card
                style={{
                    borderRadius: '10px',
                    marginTop: isMobile ? '1rem' : '2rem',
                    width: '100%',
                    maxHeight: '75vh'
                }}
            >
                <CardContent style={{ padding: 0 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '4rem'
                        }}
                    >
                        <div
                            style={{
                                width: '50%',
                                backgroundColor: personal ? COLORS.blue : COLORS.white,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                flexDirection: 'column'
                            }}
                            onClick={() => setIsPersonal(true)}
                        >
                            <Typography
                                variant='h6'
                                style={{
                                    color: highlightSection('personal') ? COLORS.primary : personal ? COLORS.white : COLORS.blue,
                                    fontWeight: 'bolder'
                                }}
                            >
                                {t('messages:conversations.personal')}
                            </Typography>
                            <Typography
                                variant='body2'
                                style={{
                                    color: highlightSection('personal') ? COLORS.primary : personal ? COLORS.white : COLORS.blue,
                                }}
                            >
                                {t('messages:conversations.onlyYou')}
                            </Typography>
                        </div>
                        <div
                            style={{
                                width: '50%',
                                backgroundColor: personal ? COLORS.white : COLORS.blue,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                flexDirection: 'column'
                            }}
                            onClick={() => setIsPersonal(false)}
                        >
                            <Typography
                                variant='h6'
                                style={{
                                    color: highlightSection() ? COLORS.primary : personal ? COLORS.blue : COLORS.white,
                                    fontWeight: 'bolder'
                                }}
                            >
                                {t('messages:conversations.nonPersonal')}
                            </Typography>
                            <Typography
                                variant='body2'
                                style={{
                                    color: highlightSection() ? COLORS.primary : personal ? COLORS.blue : COLORS.white,
                                }}
                            >
                                {t('messages:conversations.everyone')}
                            </Typography>
                        </div>
                    </div>
                    <Divider
                        style={{
                            backgroundColor: COLORS.blue,
                            height: '2px'
                        }}
                    />
                    <div
                        style={{
                            overflow: 'auto',
                            height: 'calc(75vh - 4rem)'
                        }}
                    >
                        <Grid
                            container
                            justify='center'
                        >
                            {
                                chooseMessages().map((msg, index) =>
                                    <React.Fragment key={index}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{
                                                minHeight: '7rem',
                                                height: '15vh',
                                                cursor: 'pointer',
                                                backgroundColor: selectedId === msg.id ? COLORS.superLightGrey : undefined,
                                                overflow: "hidden",
                                            }}
                                        >
                                            <div
                                                onClick={() => doSetConversation(msg)}
                                                style={{
                                                    padding: '1.5rem',
                                                    paddingBottom: "0.5rem"
                                                }}
                                            >
                                                <Grid container style={{justifyContent: "space-between"}}>
                                                    <Grid item style={{maxWidth: "75%"}}>
                                                        <Typography
                                                            variant='body2'
                                                            style={{
                                                                fontWeight: 'bolder',
                                                                color: highlight(msg.data) ? COLORS.primary : COLORS.blue,
                                                                wordBreak: "break-word"
                                                            }}
                                                        >
                                                            {showConversationTitle(msg.data.title, t)}
                                                            {msg.data.userName ? ` - ${msg.data.userName}` : ''}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant='body2'
                                                            style={{ color: highlight(msg.data) ? COLORS.primary : COLORS.blue }}
                                                        >
                                                            {
                                                                msg.data.messages && msg.data.messages.length ?
                                                                    formatTime(msg.data.messages[msg.data.messages.length - 1].timestamp, t) :
                                                                    null
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    </Grid>
                                                <Typography
                                                    variant='body2'
                                                    style={{
                                                        color: COLORS.blue,
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {
                                                        msg.data.messages && msg.data.messages.length ?
                                                            <>
                                                                <i>
                                                                    {chooseSender(
                                                                        isAdmin,
                                                                        msg.data.messages[msg.data.messages.length - 1],
                                                                        msg.data.userName,
                                                                        t
                                                                    )}
                                                                    :
                                                                    &nbsp;
                                                                </i>
                                                                {
                                                                    msg
                                                                        .data
                                                                        .messages[msg.data.messages.length - 1]
                                                                        .content
                                                                        .slice(0, 60)
                                                                }
                                                            </> :
                                                            null
                                                    }
                                                </Typography>
                                            </div>
                                        </Grid>
                                        {
                                            msg.data.messages && index !== userMessages.length - 1 ?
                                                <Divider
                                                    style={{
                                                        color: COLORS.grey,
                                                        width: '90%',
                                                        height: '2px'
                                                    }}
                                                /> :
                                                null
                                        }
                                    </React.Fragment>
                                )
                            }
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default Conversations;
