import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "../constants/colors";

export const useCardStyles = makeStyles({
  facilityProfile: {
    padding: 10,
    marginTop: 10,
  },
  facilityCard: {
    backgroundColor: "#f3f3f3",
    padding: 10,
    marginTop: 10,
  },
  cardImage: {
    width: 320,
    height: 180,
    objectFit: "cover",
  },
  cardContent: {
    padding: 10,
    paddingLeft: 20,
    color: COLORS.black,
  },
  cardDetails: {
    marginTop: 10,
  },
  editButtonLink: {
    textDecoration: "none",
    marginTop: 10,
  },
  editButton: {
    display: "inline",
    marginLeft: 5,
    textTransform: "lowercase",
  },
  messageImage: {
    width: "100%",
    objectFit: "cover",
  },
});
