import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { sendResetPassword } from "../../hooks/useAuth";
import DefaultBackground from "./components/DefaultBackground";
import MainCard from "./components/MainCard";


const ResetPwd = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const { t } = useTranslation();

    const handleReset = async (e) => {
        e.preventDefault();
        try {
            await sendResetPassword(email);
            setError("");
            setSuccess(true);
        } catch (error) {
            setError(t('login:resetPwd.error'));
            setSuccess(false);
        }
    }
    return (
      <form onSubmit={handleReset}>
        <DefaultBackground>
          <MainCard
            isReset={true}
            error={error}
            values={{
              email: email
            }}
            setters={{
              setEmail: setEmail
            }}
            success={success}
          />
        </DefaultBackground>
      </form>
    );
  };

  export default ResetPwd;