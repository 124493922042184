import React from 'react';

import { OpeningHoursField } from '../../facility';
import CardTemplate from '../../CardTemplate';


const OpeningHoursCard = ({ title, onChange, facility, desc }) => {

    const handleOpeningHoursChange = (day, key, value) => {
        const newOpeningHours = [ ...facility.openingHours ];
        newOpeningHours[day][key] = value;
        onChange('openingHours', newOpeningHours);
    };

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <OpeningHoursField
                openingHours={facility.openingHours}
                handleChange={handleOpeningHoursChange}
            />
        </CardTemplate>
    );
};

export default OpeningHoursCard;