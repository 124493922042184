import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './translations/cs.json';
import en from './translations/en.json';

i18n
//   .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: 'cs',
    resources: {
        cs: cs,
        en: en
    }
  });


export default i18n;