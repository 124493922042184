import {IconButton} from "@material-ui/core";
import {GetApp} from "@material-ui/icons";
import React, {useState} from "react";
import {COLORS} from "../../../constants/colors";

const CustomImage = (props) => {
    const {onDownload, src, id, isDownloadPdf} = props;

    const [isHover, setIsHover] = useState(false);

    return (
        <IconButton
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
            variant="contained"
            onClick={onDownload}
            style={{backgroundColor: "transparent"}}
        >
            <img
                style={{
                    border: "1px solid black",
                    maxWidth: "200px",
                }}
                height={124}
                key={id}
                src={src}
                alt={src}
            />

            {isHover && (
                isDownloadPdf ? <>
                    <img src={'/pdf.svg'} alt={'stáhnout jako PDF'}
                         style={{
                             backgroundColor: COLORS.blue,
                             color: COLORS.white,
                             borderRadius: "3rem",
                             padding: "1rem",
                             position: "absolute",
                             height: '32px'
                         }}
                    />
                </> : <GetApp
                    style={{
                        backgroundColor: COLORS.blue,
                        color: COLORS.white,
                        borderRadius: "3rem",
                        padding: "1rem",
                        position: "absolute",
                    }}
                />
            )}
        </IconButton>
    );
};

export default CustomImage;
