import React, { useState } from 'react';
import { AppBar, Button, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";

import { COLORS } from '../../constants/colors';
import MobileMenu from './MobileMenu';


const TopMenu = ({ isAdmin, authenticated, signOut, mobileView, isManager, isEditor }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    }

    const closeMenu = () => {
        setAnchorEl(null);
    }

    return (
        <AppBar
            color="default"
            position="static"
            variant="outlined"
            style={{
                height: "64px",
                backgroundColor: "#00293c",
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                padding: '0px 30px',
                alignItems: 'center',
                border: 'none',
                maxWidth: '100vw',
                width: '100vw'
            }}
        >
            <Link to="/" style={{ width: '10rem'}}>
                <img
                    src="/logo_color.svg"
                    style={{
                        maxHeight: '30px',
                        objectFit: 'contain'
                    }}
                    href="/"
                />
            </Link>
            {
                authenticated ?
                    <>
                        {
                            mobileView ?
                                <>
                                    <IconButton onClick={openMenu}>
                                        <MenuIcon style={{ color: COLORS.white }}/>
                                    </IconButton>
                                    <MobileMenu
                                        isAdmin={isAdmin}
                                        close={closeMenu}
                                        anchorEl={anchorEl}
                                        signOut={signOut}
                                        isManager={isManager}
                                        isEditor={isEditor}
                                    />
                                </>:
                                <Button
                                    onClick={signOut}
                                    style={{
                                        fontWeight: 'bold',
                                        color: COLORS.white,
                                        textTransform: 'none'
                                    }}
                                >
                                    {t('main:signOut')}
                                </Button>
                        }
                    </> : null
            }
        </AppBar>
    )
};

export default TopMenu;