import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import moment from "moment";

import { Button, Divider } from "@material-ui/core";

import { getFacilityById } from "../../api/facility";
import useFacilityTypes from "../../hooks/useFacilityTypes";
import useLoader from "../../hooks/useLoader";
import useLocalities from "../../hooks/useLocalities";
import useMembershipTypes from "../../hooks/useMembershipTypes";
import { useCardStyles } from "../../styles/card";
import { useBasicDataContext } from "../../hooks/useBasicData";
import { getAllPostsForFacility, saveEditPost } from "../../api/post";
import FacilityInfo from "./components/FacilityInfo";
import EditModal from "./components/EditModal";
import PostCard from "./components/PostCard";
import PlanModal from "./components/PlanModal";
import CouponModal from "./components/CouponModal";

const FacilityMain = () => {
  const { id } = useParams();
  const [facility, setFacility] = useState();
  const [posts, setPosts] = useState([]);
  const [loadPosts, setLoadPosts] = useState(true);
  const [edit, setEdit] = useState(false);
  const [plan, setPlan] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [editedPost, setEditedPost] = useState({});
  const { startLoading, endLoading } = useLoader();
  const localities = useLocalities();
  const types = useFacilityTypes();
  const memberships = useMembershipTypes();
  const { t } = useTranslation();
  const classes = useCardStyles();

  const resolveEverything = (facility) => {
    const memship = memberships.find(
      (memship) => memship.id === facility.membership
    );
    const newTypes = types
      .filter((type) => facility.types.includes(type.id))
      .map((type) => type.name);
    const newLocalities = [];
    for (const countryId in localities) {
      for (const locality of localities[countryId]) {
        if (facility.localities.includes(locality.id)) {
          newLocalities.push(locality.name);
        }
      }
    }
    return {
      ...facility,
      localities: newLocalities,
      membership: memship ? memship.name : "",
      types: newTypes,
    };
  };

  useEffect(() => {
    startLoading();
    getFacilityById(id)
      .then((data) => {
        setFacility(resolveEverything({ ...data, id: id }));
      })
      .catch((error) => console.log("error", error))
      .finally(() => endLoading());
  }, [localities, types, memberships, id]);

  useEffect(() => {
    if (loadPosts) {
      startLoading();
      getAllPostsForFacility(id)
        .then((data) => setPosts(data))
        .catch((error) => console.log("error", error))
        .finally(() => {
          endLoading();
          setLoadPosts(false);
        });
    }
  }, [loadPosts, id]);

  const reloadPosts = () => setLoadPosts(true);

  const { user, isAdmin, isManager, isEditor } = useBasicDataContext();
  if (!facility || !user) {
    return null;
  }
  if (
    !isAdmin &&
    (!isManager || facility.manager !== user.uid) &&
    (!isEditor || !facility.editors || !facility.editors.includes(user.uid))
  ) {
    return <div>{t("error:permissionDenied")}</div>;
  }

  const openEdit = (post) => {
    setEditedPost(post);
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
    setEditedPost({});
  };

  const openPlan = (post) => {
    setEditedPost(post);
    setPlan(true);
  };

  const closePlan = () => {
    setPlan(false);
    setEditedPost({});
  };

  const openCoupon = (post) => {
    setEditedPost(post);
    setCoupon(true);
  };

  const closeCoupon = () => {
    setCoupon(false);
    setEditedPost({});
  };

  const handleSaveEdit = (newPost, id) => {
    saveEditPost(newPost, id)
      .then(() => {
        closeEdit();
        closePlan();
        closeCoupon();
        reloadPosts();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <EditModal
        closeEdit={closeEdit}
        facilityProfile={facility.profilePhoto}
        opened={edit}
        post={editedPost}
        saveEdit={handleSaveEdit}
        t={t}
      />
      <PlanModal
        closeEdit={closePlan}
        opened={plan}
        post={editedPost}
        saveEdit={handleSaveEdit}
        t={t}
      />
      <CouponModal
        closeEdit={closeCoupon}
        opened={coupon}
        post={editedPost}
        saveEdit={handleSaveEdit}
        t={t}
      />
      <FacilityInfo
        facility={facility}
        t={t}
        classes={classes}
        showEditorsButton={
          isAdmin || (isManager && facility.manager === user.uid)
        }
      />
      <Divider />
      <RouterLink to={`/addPost/${id}`} style={{ textDecoration: "none" }}>
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: "1rem" }}
        >
          {t("addPost:buttonTitle")}
        </Button>
      </RouterLink>
      {posts
        .sort((fst, snd) =>
          moment(fst.data.created.toDate()).isBefore(snd.data.created.toDate())
            ? 1
            : -1
        )
        .map((post) => (
          <PostCard
            facility={facility}
            key={post.id}
            reloadPosts={reloadPosts}
            post={post}
            t={t}
            openEdit={openEdit}
            openPlan={openPlan}
            openCoupon={openCoupon}
          />
        ))}
      <div style={{ width: 1, height: 30 }}></div>
    </>
  );
};

export default FacilityMain;
