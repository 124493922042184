import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useCountries from "../hooks/useCountries";

import LocalityTreeNode from "../components/LocalityTreeNode";

const Localities = () => {
  const { t } = useTranslation();

  // TODO add, edit and remove countries
  const [countries, setCountries] = useCountries();

  return (
    <div style={{ marginBottom: 20 }}>
      <Typography variant="h5">{t('localities:title')}</Typography>
      {countries.map((country) => (
        <div key={country.id} style={{ marginTop: 10 }}>
          <Typography variant="h6">{country.name}</Typography>
          <LocalityTreeNode
            country={country.id}
            path={[]}
            data={{ name: t('localities:all') }}
            edit
          />
        </div>
      ))}
    </div>
  );
};

export default Localities;
