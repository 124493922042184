import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { sendEmail, signUp } from "../../hooks/useAuth";
import { getErrorMessage } from "../../utils/authErrors";
import DefaultBackground from "./components/DefaultBackground";
import MainCard from "./components/MainCard";


const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const handleRegistration = (event) => {
    event.preventDefault();
    if (password !== passwordAgain) {
      setError(t('login:register.passwordMismatch'));
      return;
    }
    setError("");
    signUp(email, password).
      then(() => {
        sendEmail().then(() => console.log('sent')).catch(e => console.log('e', e));
      }).
      catch((error) =>
      setError(getErrorMessage(error.code))
    );
  };

  return (
    <form onSubmit={handleRegistration}>
      <DefaultBackground>
        <MainCard
          isLogin={false}
          error={error}
          values={{
            email: email,
            password: password,
            passwordAgain: passwordAgain
          }}
          setters={{
            setEmail: setEmail,
            setPassword: setPassword,
            setPasswordAgain: setPasswordAgain
          }}
        />
      </DefaultBackground>
    </form>
  );
};

export default Register;
