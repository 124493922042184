import { useState, useEffect } from 'react';

import { getAllEditorsAndManagers, isAdmin, isManager, isEditor } from '../api/user';
import useLoader from './useLoader';

/**
 * currently all admins are equal and indistinguishable, so we only check for editors and managers
 */
const useUsernames = () => {
    const [usernames, setUsernames] = useState({});
    const { startLoading, endLoading } = useLoader();

    const loadAllUsernames = async () => {
        startLoading();
        const amIAdmin = await isAdmin();
        const amIManager = await isManager();
        const amIEditor = await isEditor();
        const result = {};
        const allUsers = await getAllEditorsAndManagers(amIAdmin, amIManager, amIEditor);
        for (const user of allUsers) {
            result[user.id] = user.data.name;
        }
        setUsernames(result);
        endLoading();
    }

    useEffect(() => {
        loadAllUsernames();
    }, [setUsernames]);

    return usernames;
};

export default useUsernames;
