import { Route, Switch, Redirect } from "react-router-dom";

import {
  AdminMain,
  FacilityMain,
  ManagerMain,
  Localities,
  Login,
  Register,
  Types,
  NotFound,
  WaitingRoom,
  NewRegistrations,
  NewFacility,
  FacilitiesManager,
  FacilityEdit,
  FacilityEditors,
  AddPost,
} from "../../pages";
import Dashboard from "../../pages/dashboard/Dashboard";
import Help from "../../pages/Help";
import EmailHandler from "../../pages/loginRegister/EmailHandler";
import ResetPwd from "../../pages/loginRegister/ResetPwd";
import Membership from "../../pages/Membership";
import Menu from "../../pages/menu/Menu";
import MenuEdit from "../../pages/menuEdit/MenuEdit";
import Messages from "../../pages/messages/Messages";
import AddEditNews from "../../pages/news/AddEditNews";
import NewsDetail from "../../pages/news/components/NewsDetail";
import News from "../../pages/news/News";
import Other from "../../pages/Other";
import Settings from "../../pages/settings/Settings";
import Support from "../../pages/Support";
import Export from "../../pages/export/Export";

export const LoginRouter = () => (
  <Switch>
    <Route exact path="/reset" component={ResetPwd} />
    <Route exact path="/register" component={Register} />
    <Route path="/emailHandler" component={EmailHandler} />
    <Route component={Login} />
  </Switch>
);

export const BlankPageRouter = () => (
  <Route>
    <p />
  </Route>
);

export const WaitingRoomRouter = () => (
  <Switch>
    <Route path="/emailHandler" component={EmailHandler} />
    <Route component={WaitingRoom} />;
  </Switch>
);

export const AdminRouter = () => (
  <Switch>
    <Route exact path="/register">
      <Redirect to="/" />
    </Route>
    <Route exact path="/" component={AdminMain} />
    <Route exact path="/types/" component={Types} />
    <Route exact path="/localities/" component={Localities} />
    <Route exact path="/newRegistrations/" component={NewRegistrations} />
    <Route exact path="/facilitiesManager/" component={FacilitiesManager} />
    <Route path="/facilities/:id" component={FacilityMain} />
    <Route path="/facilityEdit/:id" component={FacilityEdit} />
    <Route path="/facilityEditors/:id" component={FacilityEditors} />
    <Route path="/addPost/:facilityId" component={AddPost} />
    <Route path="/settings" component={Settings} />
    <Route exact path="/news" component={News} />
    <Route exact path="/newsAdd" component={AddEditNews} />
    <Route path="/newsAdd/:id" component={AddEditNews} />
    <Route path="/emailHandler" component={EmailHandler} />
    <Route path="/messages" component={Messages} />
    <Route exect path="/menu" component={Menu} />
    <Route path="/menuEdit/:id" component={MenuEdit} />
    <Route exect path="/export" component={Export} />
    <Route component={NotFound} />
  </Switch>
);

export const ManagerRouter = ({ changeNewsCount }) => (
  <Switch>
    <Route exact path="/register">
      <Redirect to="/" />
    </Route>
    <Route
      exact
      path="/"
      render={(props) => (
        <Dashboard {...props} changeNewsCount={changeNewsCount} />
      )}
    />
    <Route exact path="/facilities" component={ManagerMain} />
    <Route exact path="/newFacility" component={NewFacility} />
    <Route path="/facilities/:id" component={FacilityMain} />
    <Route path="/facilityEdit/:id" component={FacilityEdit} />
    <Route path="/facilityEditors/:id" component={FacilityEditors} />
    <Route path="/addPost/:facilityId" component={AddPost} />
    <Route path="/membership" component={Membership} />
    <Route path="/help" component={Help} />
    <Route path="/support" component={Support} />
    <Route path="/other" component={Other} />
    <Route path="/settings" component={Settings} />
    <Route
      exact
      path="/news"
      render={(props) => <News {...props} changeNewsCount={changeNewsCount} />}
    />
    <Route path="/emailHandler" component={EmailHandler} />
    <Route path="/messages" component={Messages} />
    <Route exect path="/menu" component={Menu} />
    <Route path="/menuEdit/:id" component={MenuEdit} />
    <Route exect path="/export" component={Export} />
    <Route component={NotFound} />
  </Switch>
);

export const EditorRouter = ({ changeNewsCount }) => (
  <Switch>
    <Route exact path="/register">
      <Redirect to="/" />
    </Route>
    <Route
      exact
      path="/"
      render={(props) => (
        <Dashboard {...props} changeNewsCount={changeNewsCount} />
      )}
    />
    <Route exact path="/facilities" component={ManagerMain} />
    <Route path="/facilities/:id" component={FacilityMain} />
    <Route path="/facilityEdit/:id" component={FacilityEdit} />
    <Route path="/addPost/:facilityId" component={AddPost} />
    <Route path="/membership" component={Membership} />
    <Route path="/help" component={Help} />
    <Route path="/support" component={Support} />
    <Route path="/other" component={Other} />
    <Route path="/settings" component={Settings} />
    <Route
      exact
      path="/news"
      render={(props) => <News {...props} changeNewsCount={changeNewsCount} />}
    />
    <Route path="/emailHandler" component={EmailHandler} />
    <Route path="/messages" component={Messages} />
    <Route exect path="/menu" component={Menu} />
    <Route path="/menuEdit/:id" component={MenuEdit} />
    <Route exect path="/export" component={Export} />
    <Route component={NotFound} />
  </Switch>
);
