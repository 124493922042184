import React from "react";

import { AddFacility } from "../components";


const NewFacility = () => {
  return (
      <AddFacility addingDraft={false} />
  );
};

export default NewFacility;
