import { firestore, firebaseAppAuth } from "../utils/firebase";

/**
 * Resolves as true if the current user is a system administrator.
 */
export const isAdmin = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve(null);
    }

    firestore
      .collection("admins")
      .doc(user.uid)
      .get()
      .then((doc) => resolve(doc.exists))
      .catch((error) => reject(error));
  });
};

/**
 * Resolves to true if the user is manager.
 */
export const isManager = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve(null);
    }

    firestore
      .collection("managers")
      .doc(user.uid)
      .get()
      .then((doc) => resolve(doc.exists))
      .catch((error) => reject(error));
  });
};

/**
 * Resolves to true if the current user is a restaurant editor.
 */
export const isEditor = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve(null);
    }

    firestore
      .collection("editors")
      .doc(user.uid)
      .get()
      .then((doc) => resolve(doc.exists))
      .catch((error) => reject(error));
  });
};

/**
 * Resolves id to username, regardless whether the user is admin, editor or manager
 * TODO: firebase does not ensure that admins/editors/managers do not have duplicite IDs, but hopefully
 * it's highly unprobable for now
 */
export const resolveUsername = async id => {
  const editor = await firestore.collection('editors').doc(id).get();
  if (editor.data()) {
    return editor.data().name;
  }
  const manager = await firestore.collection('managers').doc(id).get();
  if (manager.data()) {
    return manager.data().name;
  }
  const admin = await firestore.collection('admins').doc(id).get();
  return admin ? admin.data().name : '';
};

/**
 * gets all managers and editors
*/
export const getAllEditorsAndManagers = async (isAdmin, isManager, isEditor) => {
  const result = [];
  let managers = [];
  let editors = [];
  if (isAdmin || isEditor) {
    managers = await firestore.collection('managers').get();
  }
  if (isAdmin || isManager || isEditor) {
    editors = await firestore.collection('editors').get();
  }
  managers.forEach(manager => {
    result.push({ id: manager.id, data: { role: 'manager', ...manager.data() } });
  });
  editors.forEach(editor => {
    result.push({ id: editor.id, data: { role: 'editor', ...editor.data() } });
  });
  return result;
};
