import { useEffect, useState } from "react";
import firebase from "firebase/app";

import { firebaseAppAuth } from "../utils/firebase";

// inspired by
// https://johnwcassidy.medium.com/firebase-authentication-hooks-and-context-d0e47395f402

const onAuthStateChanged = (callback) => {
  return firebaseAppAuth.onAuthStateChanged((user) => {
    callback(user ? user : null);
  });
};

export const signIn = (email, password) => {
  return firebaseAppAuth.signInWithEmailAndPassword(email, password);
};

export const signUp = (email, password) => {
  return firebaseAppAuth.createUserWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return firebaseAppAuth.signOut();
};

export const emailVerified = () => {
  if (!firebaseAppAuth || !firebaseAppAuth.currentUser) {
    return false;
  }
  return firebaseAppAuth.currentUser.emailVerified;
}

export const sendEmail = async () => {
  return firebaseAppAuth.currentUser.sendEmailVerification();
};

export const sendResetPassword = async email => {
  return firebaseAppAuth.sendPasswordResetEmail(email);
}

export const resetPassword = async (password, actionCode) => {
  return new Promise((resolve, reject) => {
    firebaseAppAuth.
      verifyPasswordResetCode(actionCode).
      then(() => {
        firebaseAppAuth.
          confirmPasswordReset(actionCode, password).
          then(() => resolve()).
          catch(error => reject(error))
      }).
      catch(error => reject(error));
  });
};

export const changePassword = async (oldPwd = '', newPwd) => {
  const credential = firebase.auth.EmailAuthProvider.credential(firebaseAppAuth.currentUser.email, oldPwd);
  return new Promise((resolve, reject) => {
      firebaseAppAuth.
      currentUser.
      reauthenticateWithCredential(credential).
      then(() => {
        firebaseAppAuth.currentUser.updatePassword(newPwd).
          then(() => resolve()).
          catch(error => {
            console.log('error', error);
            reject();
          })
      }).
      catch(error => {
        console.log('erorr', error);
        reject();
      });
  });
};

export const verifyEmail = async (actionCode) => {
  return new Promise((resolve, reject) => {
    firebaseAppAuth.
      applyActionCode(actionCode).
      then(() => resolve()).
      catch(error => {
        console.log('error', error);
        reject();
      });
  });
}

const useAuth = () => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return user;
};

export default useAuth;
