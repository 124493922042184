import React from 'react';
import { TextField } from '@material-ui/core';

import CardTemplate from '../../CardTemplate';


const ContactCard = ({ title, onChange, facility, t, desc }) => {

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <TextField
                name="contactName"
                onChange={e => onChange('contactName', e.target.value)}
                label={t('contactField:name')}
                variant='outlined'
                value={facility.contactName}
                style={{ width: '100%' }}
            />
            <TextField
                name="contactEmail"
                onChange={e => onChange('contactEmail', e.target.value)}
                label={t('contactField:email')}
                variant='outlined'
                value={facility.contactEmail}
                style={{ width: '100%', marginTop: '1rem' }}
            />
        </CardTemplate>
    );
};

export default ContactCard;