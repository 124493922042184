import React from 'react';
import { Grid, TextField } from '@material-ui/core';

import CardTemplate from '../../CardTemplate';

import { useBasicDataContext } from "../../../hooks/useBasicData";

const AddressCard = ({ title, onChange, facility, t, desc }) => {

    const { isAdmin } = useBasicDataContext();

    return (
        <CardTemplate
            title={title}
            desc={desc}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="street"
                        onChange={e => onChange('street', e.target.value)}
                        label={t('addressField:street')}
                        variant='outlined'
                        value={facility.street}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="number"
                        onChange={e => onChange('number', e.target.value)}
                        label={t('addressField:number')}
                        variant='outlined'
                        value={facility.number}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="city"
                        onChange={e => onChange('city', e.target.value)}
                        label={t('addressField:city')}
                        variant='outlined'
                        value={facility.city}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="zipCode"
                        onChange={e => onChange('zipCode', e.target.value)}
                        label={t('addressField:zipCode')}
                        variant='outlined'
                        value={facility.zipCode}
                        style={{ width: '100%' }}
                    />
                </Grid>
                {isAdmin && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="latitude"
                            type="number"
                            min="0"
                            max="90"
                            onChange={e => onChange('latitude', e.target.value)}
                            label={t('addressField:latitude')}
                            variant='outlined'
                            value={facility.latitude ? facility.latitude : ""}
                            style={{ width: '100%' }}
                        />
                    </Grid>)}
                {isAdmin && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="longitude"
                            type="number"
                            min="0"
                            max="180"
                            onChange={e => onChange('longitude', e.target.value)}
                            label={t('addressField:longitude')}
                            variant='outlined'
                            value={facility.longitude ? facility.longitude : ""}
                            style={{ width: '100%' }}
                        />
                    </Grid>)}
            </Grid>
        </CardTemplate>
    );
};

export default AddressCard;
