import { useState, useEffect } from 'react';

import { getPostTypes } from '../api/post';
import useLoader from './useLoader';


const usePostTypes = () => {
    const [types, setTypes] = useState([]);
    const { startLoading, endLoading } = useLoader();

    useEffect(() => {
      startLoading();
      getPostTypes()
        .then((data) => setTypes(data))
        .catch((error) => console.error(error))
        .finally(() => endLoading());
    }, [setTypes]);

    return types;
};

export default usePostTypes;