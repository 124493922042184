import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle } from "@material-ui/core";

import FirstStage from "./editorsModal/FirstStage";

const EditorsModal = ({ facility, open, onClose, onEditorAdd }) => {
  const { t } = useTranslation();

  const [CurrentStage, setStage] = useState(() => FirstStage);
  const [stageData, setStageData] = useState({
    email: "",
    name: "",
    uid: null,
  });

  useEffect(() => {
    if (open) {
      setStage(() => FirstStage);
      setStageData({ email: "", name: "", uid: null });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("editors:addTitle")}</DialogTitle>
      <CurrentStage
        onClose={onClose}
        data={stageData}
        setStage={setStage}
        setStageData={setStageData}
        facility={facility}
        onEditorAdd={onEditorAdd}
      />
    </Dialog>
  );
};

export default EditorsModal;
