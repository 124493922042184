import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Dialog,
    FormControl, RadioGroup, FormControlLabel, Radio, Link
} from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';


import {
    deleteFacility, getAllFacilities, hideFacility, showFacility, updateFacilityMembership
} from '../api/facility';
import useFacilityTypes from '../hooks/useFacilityTypes';
import useLoader from '../hooks/useLoader';
import useLocalities from '../hooks/useLocalities';
import useMembershipTypes from '../hooks/useMembershipTypes';
import { getAllManagers } from '../api/manager';
import { COLORS } from '../constants/colors';
import { ConfirmDialog } from '../components';


const TableHeader = ({ t }) => (
    <TableHead>
        <TableRow>
            <TableCell
                style={{
                    minWidth: '10rem'
                }}
            >
                <b>{t('facilitiesManager:columns.name')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.types')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.address')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.localities')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.contact')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.membership')}</b>
            </TableCell>
            <TableCell>
                <b>{t('facilitiesManager:columns.created')}</b>
            </TableCell>
            <TableCell />
        </TableRow>
    </TableHead>
);

const TableContent = ({
    data, openEditMembershipDialog, managers, doDeleteFacility, doHideFacility, doShowFacility
}) => {
    const managersObj = {};
    for (const manager of managers) {
        managersObj[manager.id] = manager.data;
    }
    return (
        data.map(facility => (
            <TableRow
                key={facility.id}
            >
                <TableCell>
                    <RouterLink
                        to={`/facilities/${facility.id}`}
                        style={{
                            textDecoration: "none" ,
                            color: COLORS.primary
                        }}
                    >
                        {facility.data.name}
                    </RouterLink>
                </TableCell>
                <TableCell>
                    {facility.data.types.join(', ')}
                </TableCell>
                <TableCell>
                    {`${facility.data.street} ${facility.data.number}, ${facility.data.city}`}
                </TableCell>
                <TableCell>
                    {facility.data.localities.map(locality => locality).join(', ')}
                </TableCell>
                <TableCell>
                    {
                        managersObj[facility.data.manager] ?
                            <>
                                <a
                                    href={`mailto:${managersObj[facility.data.manager].email}`}
                                    style={{
                                        color: COLORS.black
                                    }}
                                >
                                    {managersObj[facility.data.manager].name}
                                </a>
                            </> :
                            ''
                    }
                </TableCell>
                <TableCell>
                    {
                        <Link
                            onClick={() => openEditMembershipDialog(facility.id)}
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: COLORS.black
                            }}
                        >
                            {facility.data.membership}
                        </Link>
                    }
                </TableCell>
                <TableCell>
                    {facility.data.created ? moment(facility.data.created.toDate()).format('DD. MM.') : ''}
                </TableCell>
                <TableCell
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <IconButton
                        onClick={facility.data.hidden ?
                            () => doShowFacility(facility.id) :
                            () => doHideFacility(facility.id)
                        }
                    >
                        {
                            facility.data.hidden ?
                                <VisibilityIcon style={{ color: COLORS.blue }} /> :
                                <VisibilityOffIcon style={{ color: COLORS.blue }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => doDeleteFacility(facility.id)} >
                        <DeleteIcon style={{ color: COLORS.red }} />
                    </IconButton>
                </TableCell>
            </TableRow>
        ))
    );
};

const EditMembershipDialog = ({ onClose, submit, opened, memberships, t }) => (
    <Dialog
        onClose={onClose}
        open={opened}
    >
         <FormControl
            component="fieldset"
            style={{
                display: 'flex',
                width: '15vw',
                height: '30vh',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Typography
                variant='subtitle1'
                style={{
                    marginBottom: '1rem'
                }}
            >
                <b>{t('facilitiesManager:edit.membership.title')}</b>
            </Typography>
            <RadioGroup onChange={submit}>
                {
                    memberships.map(membership => (
                        <FormControlLabel
                            value={membership.id}
                            control={<Radio />}
                            label={membership.name}
                            key={membership.id}
                        />
                    ))
                }
            </RadioGroup>
            </FormControl>
    </Dialog>
);

const FacilitiesManager = () => {
    const [facilities, setFacilities] = useState([]);
    const [managers, setManagers] = useState([]);
    const [chosenId, setChosenId] = useState(null);
    const [editDialogOpened, setEditDialogOpened] = useState(false);
    const [reloadData, setReloadData] = useState(true);
    const [dialog, setDialog] = useState({ open: false });
    const { startLoading, endLoading } = useLoader();
    const { t } = useTranslation();
    const localities = useLocalities();
    const types = useFacilityTypes();
    const memberships = useMembershipTypes();

    const resolveEverything = (facilitiesData) => {
        return facilitiesData.map(facility => {
            const memship = memberships.find(memship => memship.id === facility.data.membership);
            const newTypes = types.filter(type => facility.data.types.includes(type.id)).map(type => type.name);
            const newLocalities = [];
            for (const countryId in localities) {
                for (const locality of localities[countryId]) {
                    if (facility.data.localities.includes(locality.id)) {
                        newLocalities.push(locality.name);
                    }
                }
            }
            return {
                id: facility.id,
                data: {
                    ...facility.data,
                    localities: newLocalities,
                    membership: memship ? memship.name : '',
                    types: newTypes
                }
            };
        });
    }

    useEffect(() => {
        if (reloadData) {
            startLoading()
            getAllFacilities()
                .then(facilitiesData => {
                    getAllManagers()
                        .then(managersData => {
                            setManagers(managersData);
                            setFacilities(resolveEverything(facilitiesData, managersData));
                            setReloadData(false);
                        })
                })
                .catch(error => console.log('error', error))
                .finally(() => endLoading());
        }
    }, [localities, types, memberships, reloadData]);


    const openEditMembershipDialog = (id) => {
        setChosenId(id);
        setEditDialogOpened(true);
    }

    const closeEditMembershipDialog = () => {
        setChosenId(null);
        setEditDialogOpened(false);
    }

    const changeMembership = async event => {
        await updateFacilityMembership(chosenId, event.target.value);
        closeEditMembershipDialog();
        setReloadData(true);
    }

    const closeDialog = () => setDialog({ open: false });

    const doDeleteFacility = id => {
        setDialog({
          title: t('facilitiesManager:edit.delete.title'),
          text: t('facilitiesManager:edit.delete.desc'),
          open: true,
          onClose: closeDialog,
          onDecline: closeDialog,
          onAccept: () => {
            startLoading();
            deleteFacility(id)
                .then(() => setReloadData(true))
                .catch(error => console.log('error', error))
                .finally(() => endLoading());
            closeDialog();
          },
        });
    }

    const doHideFacility = id => {
        setDialog({
            title: t('facilitiesManager:edit.hide.title'),
            text: t('facilitiesManager:edit.hide.desc'),
            open: true,
            onClose: closeDialog,
            onDecline: closeDialog,
            onAccept: () => {
                startLoading();
                hideFacility(id)
                    .then(() => setReloadData(true))
                    .catch(error => console.log('error', error))
                    .finally(() => endLoading());
              closeDialog();
            },
          });
    }

    const doShowFacility = id => {
        setDialog({
            title: t('facilitiesManager:edit.show.title'),
            text: t('facilitiesManager:edit.show.desc'),
            open: true,
            onClose: closeDialog,
            onDecline: closeDialog,
            onAccept: () => {
                startLoading();
                showFacility(id)
                    .then(() => setReloadData(true))
                    .catch(error => console.log('error', error))
                    .finally(() => endLoading());
              closeDialog();
            },
          });
    }

    const comparator = (a, b) => (a.data.name.localeCompare(b.data.name));
    const currentSelectedFacility = facilities.find(facility => facility.id === chosenId);

    return (
        <>
            <ConfirmDialog {...dialog} />
            <EditMembershipDialog
                onClose={closeEditMembershipDialog}
                submit={changeMembership}
                opened={editDialogOpened}
                memberships={memberships || []}
                currentValue={currentSelectedFacility ? currentSelectedFacility.membership : ''}
                t={t}
            />
            <Typography variant='h6'>
                {t('facilitiesManager:title')}
            </Typography>
            <TableContainer
                component={Paper}
                style={{
                    marginTop: '2rem'
                }}
            >
                <Table>
                    <TableHeader t={t} />
                    <TableBody>
                        <TableContent
                            managers={managers}
                            data={facilities.filter(fac => !fac.data.deleted).sort(comparator)}
                            openEditMembershipDialog={openEditMembershipDialog}
                            doDeleteFacility={doDeleteFacility}
                            doHideFacility={doHideFacility}
                            doShowFacility={doShowFacility}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default FacilitiesManager;