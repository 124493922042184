import React from "react";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { Typography, Box } from "@material-ui/core";

const AllergenCheckbox = (props) => {
  const { title, onChange, checked, disabled } = props;

  return (
    <Box style={{ display: "flex", marginLeft: "1rem" }}>
      <CustomCheckbox
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default AllergenCheckbox;
