import {useCallback, useEffect, useMemo, useState} from "react";
import useFacility from "./useFacility";
import moment from "moment";
import useLoader from "./useLoader";
import {createMenuWeek, getMenuWeek, updateMenuWeek} from "../api/menuWeek";
import {debounce} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import confirmDialog from "../components/ConfirmDialog";
import {getWeekDayNameByIndex} from "../utils/dateHelper";

const useWeekMenu = (showValidationError) => {
    const {facility} = useFacility()
    const [weekMenuData, setWeekMenuData] = useState();
    const [originalWeekMenuData, setOriginalWeekMenuData] = useState();
    const [hasUnsavedData, setHasUnsavedData] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState(moment().utc().startOf("isoWeek"));
    const [isLoading, setIsLoading] = useState(false);
    const {startLoading, endLoading} = useLoader();

    const {t} = useTranslation();

    const getMenu = useCallback(() => {
        startLoading();
        getMenuWeek(facility.id, selectedWeek.toDate())
            .then((data) => {
                if (!data) {
                    createMenuWeek(
                        selectedWeek.toDate(),
                        facility.id,
                        facility,
                        Array(7).fill({
                            meals: [],
                            everyDayMeals: [],
                            noDailyMenu: false,
                            soupInPrice: facility.menuPreferences?.soupAlwaysInPrice ?? false,
                        })
                    )
                        .catch((error) => console.log("error", error))
                        .finally(() => endLoading());
                    getMenu(facility.id, selectedWeek.toDate());
                } else {
                    let mw = data?.data();
                    mw.days.forEach((day, index) => {
                        if (day.meals == null) mw.days[index].meals = [];
                        if (day.everyDayMeals == null) mw.days[index].everyDayMeals = [];
                    });
                    setWeekMenuData({id: data?.id, ...mw});
                    setOriginalWeekMenuData({id: data?.id, ...mw});
                    setHasUnsavedData(false);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                endLoading();
                setIsLoading(false);
            });
    }, [selectedWeek, facility]);

    useEffect(() => {
        getMenu();
    }, [getMenu, selectedWeek]);

    const backupFilledData = () => {
        if (!hasUnsavedData) return;
        saveToFirestore(true);
        setHasUnsavedData(false);
    };

    const debouncedChangeHandler = debounce(backupFilledData, 4000);

    useEffect(() => {
        if (weekMenuData && JSON.stringify(weekMenuData) !== JSON.stringify(originalWeekMenuData)) {
            setHasUnsavedData(true);
            debouncedChangeHandler();
        } else {
            setHasUnsavedData(false);
        }
    }, [weekMenuData]);

    const validateAndSave = () => {
        const errors = [];

        const validation = (dayIndex, mealIndex, everyDay) => {
            const meal = everyDay ? weekMenuData.days[dayIndex].everyDayMeals[mealIndex] : weekMenuData.days[dayIndex].meals[mealIndex];
            if (meal.type === "soup") meal.name = t("menuEdit:soup");
            if (!meal.name || !meal.description || (!meal.price && (meal.type !== "soup" || !weekMenuData.days[dayIndex].soupInPrice)))
                errors.push({
                    dayIndex,
                    mealIndex,
                })
        };

        weekMenuData.days.forEach((day, dayIndex) => {
            day.meals.forEach((meal, mealIndex) => {
                validation(dayIndex, mealIndex, false);
            });
            day.everyDayMeals.forEach((meal, mealIndex) => {
                validation(dayIndex, mealIndex, true);
            });
        });

        if (errors.length > 0) {
            showValidationError('Některá pole nejsou vyplněna.<br /><br />' +
                errors.map((error) => {
                    return `${getWeekDayNameByIndex(error.dayIndex)} - ${error.mealIndex + 1}. jídlo`
                }).join('<br />')
            );
            return;
        }


        setHasUnsavedData(false);
        saveToFirestore();
    };


    const nextWeek = () => {
        setWeekMenuData(null);
        setIsLoading(true);
        setSelectedWeek((prev) => prev.clone().add(1, "weeks").startOf("isoWeek"));
    };

    const prevWeek = () => {
        setWeekMenuData(null);
        setIsLoading(true);
        setSelectedWeek((prev) =>
            prev.clone().subtract(1, "weeks").startOf("isoWeek")
        );
    };

    const saveToFirestore = (shouldBeSilent = false) => {
        if (!shouldBeSilent) {
            setIsLoading(true);
        }
        if (weekMenuData) {
            updateMenuWeek(
                selectedWeek.toDate(),
                facility.id,
                weekMenuData.days,
                weekMenuData.id
            ).finally(() => {
                if (!shouldBeSilent) {
                    setIsLoading(false)
                }
            });
        }
    };

    return {nextWeek, prevWeek, weekMenuData, selectedWeek, setWeekMenuData, hasUnsavedData, setHasUnsavedData, isLoading, setIsLoading, validateAndSave}
}

export default useWeekMenu
