import React from "react";

const design = {
  // top right bottom left
  padding: "3px 8px 3px 8px",
  borderRadius: 10,
  marginRight: 5,
};

const Tag = ({
  children,
  color = "white",
  backgroundColor = "gray",
  style,
}) => (
  <span style={{ ...design, backgroundColor, color, ...style }}>
    {children}
  </span>
);

export default Tag;
