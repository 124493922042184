import React, {useEffect, useState} from "react";
import {Card, CardContent, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {COLORS} from "../../../constants/colors";
import {getFilledUntil} from "../../../api/menuWeek";
import moment from "moment";
import useLoader from "../../../hooks/useLoader";

//to do translation

const FacilityTab = ({data}) => {
    const {name} = data.data;
    const {id} = data;
    const {startLoading, endLoading} = useLoader();
    const {t} = useTranslation();
    const [filledUntil, setFilledUntil] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        startLoading();
        getFilledUntil(id)
            .then((data) => {
                setFilledUntil(data);
                setLoaded(true);
            })
            .catch((error) => console.log("error", error))
            .finally(() => endLoading());
    }, [data]);

    return (
        <Card
            onClick={(e) => {
            }}
            className={'zoom-on-hover'}
            style={{
                background: COLORS.surface,
                boxShadow: 'none',
                borderRadius: "16px",
                margin: "8px 0",
                cursor: "pointer",
            }}
        >
            <RouterLink
                style={{textDecoration: "none"}}
                to={"/menuEdit/" + id}
            >
                <CardContent
                    style={{
                        margin: "0 24px",
                        padding: 8,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "bold",
                            color: COLORS.orange,
                        }}
                    >
                        {name.length ? name : 'Beze jména O_o'}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "bold",
                            color: filledUntil ? COLORS.green : COLORS.red,
                        }}
                    >
                        {loaded ? (filledUntil
                            ? `${t("menu:filledUntil")}: ${moment(filledUntil).format(
                                "D.M.Y"
                            )}`
                            : t("menuEdit:notFilled")) : ''}
                    </Typography>
                </CardContent>
            </RouterLink>
        </Card>
    );
};

export default FacilityTab;
