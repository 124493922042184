import { useState } from "react";

const useLoader = () => {
  const [loadingTasks, setLoadingTasks] = useState(0);
  const startLoading = () => {
    setLoadingTasks((prev) => prev + 1);
  };
  const endLoading = () => {
    setLoadingTasks((prev) => prev - 1);
  };

  return {
    isLoading: loadingTasks !== 0,
    startLoading,
    endLoading,
  };
};

export default useLoader;
