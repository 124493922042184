import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { Edit, People } from "@material-ui/icons";

import { PicPreview } from "../../../components";
import { Tag } from "../../../components";

import { getRestaurantProfilePicture } from "../../../api/storage";
import LikesInfo from "./LikesInfo";

const FacilityInfo = ({ facility, t, classes, showEditorsButton }) => {
  const photoUrl = facility.profilePhoto
    ? getRestaurantProfilePicture(facility.profilePhoto, "tiny")
    : null;

  const photoAlt = `${t("facilityCard:profilePic")} '${
    facility ? facility.name : ""
  }'`;

  return (
    <Grid container className={classes.facilityProfile}>
      <Grid item>
        <PicPreview
          src={photoUrl}
          alt={photoAlt}
          className={classes.cardImage}
          style={{
            filter: facility && facility.hidden ? "grayscale(100%)" : undefined,
          }}
        />
      </Grid>
      <Grid item className={classes.cardContent}>
        <Typography variant="h5" style={{ fontSize: 20, fontWeight: "bold" }}>
          {facility.name}{" "}
          {facility.hidden ? (
            <span style={{ color: "gray" }}>{t("facilityMain:notShown")}</span>
          ) : null}
        </Typography>
        <Typography variant="body2" className={classes.cardDetails}>
          {facility.localities && facility.localities.length
            ? facility.localities
                .sort((fst, snd) => (fst > snd ? 1 : -1))
                .join("; ")
            : ""}
        </Typography>
        <Typography variant="body2" className={classes.cardDetails}>
          {facility.types.map((type) => (
            <Tag key={type}>{type}</Tag>
          ))}
        </Typography>
        <LikesInfo
          facilityID={facility.id}
          likes={facility.likes}
          pins={facility.pins}
          potential={facility.potential}
          className={classes.cardDetails}
        />
        <Typography variant="body2" className={classes.cardDetails}>
          {`${t("facilityCard:status")}: ${facility.membership}`}
        </Typography>
        <div className={classes.cardDetails}>
          <RouterLink
            to={`/facilityEdit/${facility.id}`}
            className={classes.editButtonLink}
          >
            <IconButton>
              <Edit />
              <Typography variant="body2" className={classes.editButton}>
                {t("facilityMain:edit")}
              </Typography>
            </IconButton>
          </RouterLink>
          {!!showEditorsButton && (
            <RouterLink
              to={`/facilityEditors/${facility.id}`}
              className={classes.editButtonLink}
            >
              <IconButton>
                <People />
                <Typography variant="body2" className={classes.editButton}>
                  {t("editors:editors")} (
                  {facility.editors ? facility.editors.length : 0})
                </Typography>
              </IconButton>
            </RouterLink>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default FacilityInfo;
