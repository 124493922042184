import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";

import { addEditorToFacility } from "../../api/editors";

const ExistingEditorStage = ({ data, onClose, facility, onEditorAdd }) => {
  const { t } = useTranslation();

  const handleFormSubmit = async () => {
    await addEditorToFacility(data.uid, facility);
    onEditorAdd(data.uid);
    onClose();
  };

  return (
    <>
      <DialogContent>
        <Typography variant="body2">
          {t("editors:addEditorQuestion")}
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>{t("contactField:justName")}:</strong> {data.name}
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>{t("contactField:justEmail")}:</strong> {data.email}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("confirmDialog:cancel")}
        </Button>
        <Button color="primary" onClick={handleFormSubmit}>
          {t("confirmDialog:accept")}
        </Button>
      </DialogActions>
    </>
  );
};

export default ExistingEditorStage;
