import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircle from "@material-ui/icons/AddCircle";

import { getAllEditorsAndManagers } from "../../../api/user";
import { COLORS } from "../../../constants/colors";
import { adminCreateConversation } from "../../../api/messages";
import { getAllFacilities } from "../../../api/facility";
import NewMessageArea from "./NewMessageArea";

const DEFAULT_PROPS = {
  title: "",
  recievers: [{}],
  allEditors: false,
  allManagers: false,
  allFacilities: false,
  message: "",
  images: [],
  files: [],
};

const AdminNewConversationModal = ({ opened, close, t, isMobile }) => {
  const [usersFacilities, setUsersFacilities] = useState([]);
  const [props, setProps] = useState(DEFAULT_PROPS);

  useEffect(() => {
    getAllEditorsAndManagers(true)
      .then((data) => {
        const users = data;
        getAllFacilities()
          .then((facilities) => setUsersFacilities([...users, ...facilities]))
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
  }, []);

  const onClose = (id) => {
    setProps(DEFAULT_PROPS);
    close(id);
  };

  const changeTitle = (e) => setProps({ ...props, title: e.target.value });
  const changeReciever = (index, value = {}) =>
    setProps({
      ...props,
      recievers: [
        ...props.recievers.slice(0, index),
        value ? value : {},
        ...props.recievers.slice(index + 1),
      ],
    });
  const addReciever = () =>
    setProps({ ...props, recievers: [...props.recievers, {}] });
  const changeManagers = (e) =>
    setProps({ ...props, allManagers: e.target.checked });
  const changeEditors = (e) =>
    setProps({ ...props, allEditors: e.target.checked });
  const changeFacilities = (e) =>
    setProps({ ...props, allFacilities: e.target.checked });
  const changeMessage = (e) => setProps({ ...props, message: e.target.value });
  const setImages = (imgs) => setProps({ ...props, images: imgs });
  const setFiles = (fls) => setProps({ ...props, files: fls });

  const getUnusedRecievers = () => {
    return usersFacilities.filter((user) =>
      props.recievers.every((rec) => rec.id !== user.id)
    );
  };

  const doSendMessage = () => {
    const actualRecievers = props.recievers.filter((rec) => rec.id); // filter out the empty object
    if (props.allEditors || props.allManagers) {
      for (const user of usersFacilities) {
        const alreadyInRecievers = actualRecievers.some(
          (rec) => rec.id === user.id
        );
        if (
          props.allEditors &&
          user.data.role === "editor" &&
          !alreadyInRecievers
        ) {
          actualRecievers.push(user);
        }
        if (
          props.allManagers &&
          user.data.role === "manager" &&
          !alreadyInRecievers
        ) {
          actualRecievers.push(user);
        }
        if (props.allFacilities && user.data.countryId && !alreadyInRecievers) {
          // fuck that's an ulgy way how to determine facility from person
          actualRecievers.push(user);
        }
      }
    }
    adminCreateConversation({
      recievers: actualRecievers,
      message: props.message,
      title: props.title || t("messages:conversations.add.defaultSubject"),
      files: props.files,
      images: props.images,
    })
      .then((id) => onClose(id))
      .catch((error) => console.log("error", error));
  };

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: isMobile ? "100vw" : "50vw",
          margin: 10,
        },
      }}
    >
      <DialogTitle>{t("messages:conversations.add.title")}</DialogTitle>
      <div
        style={{
          minWidth: isMobile ? undefined : "20rem",
          minHeight: "10rem",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          variant="outlined"
          value={props.title}
          label={t("messages:conversations.add.textField")}
          onChange={changeTitle}
          style={{
            width: "100%",
            marginBottom: "2rem",
          }}
        />

        {props.recievers.map((reciever, index) => (
          <Autocomplete
            options={getUnusedRecievers()}
            value={reciever}
            getOptionLabel={(option) => (option.data ? option.data.name : "")}
            key={index}
            onChange={(event, newValue) => changeReciever(index, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("messages:conversations.add.reciever")}
                variant="outlined"
              />
            )}
            style={{
              marginTop: "1rem",
            }}
          />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={addReciever}>
            <AddCircle
              style={{
                color: COLORS.primary,
                fontSize: "2rem",
              }}
            />
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={props.allManagers}
            onChange={changeManagers}
            color="primary"
          />
          <Typography
            variant="body2"
            style={{
              marginLeft: "0.5rem",
            }}
          >
            {t("messages:conversations.add.allManagers")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={props.allEditors}
            onChange={changeEditors}
            color="primary"
          />
          <Typography
            variant="body2"
            style={{
              marginLeft: "0.5rem",
            }}
          >
            {t("messages:conversations.add.allEditors")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={props.allFacilities}
            onChange={changeFacilities}
            color="primary"
          />
          <Typography
            variant="body2"
            style={{
              marginLeft: "0.5rem",
            }}
          >
            {t("messages:conversations.add.allFacilities")}
          </Typography>
        </div>
        <NewMessageArea
          idSuffix="-admin"
          isMobile={isMobile}
          text={props.message}
          setText={changeMessage}
          images={props.images}
          setImages={setImages}
          files={props.files}
          setFiles={setFiles}
          onSubmit={doSendMessage}
        />
      </div>
    </Dialog>
  );
};

export default AdminNewConversationModal;
