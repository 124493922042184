import React from "react";

import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import { useFullFormStyles } from "../../styles/form";

import { Close } from "@material-ui/icons";

const OpeningHoursField = ({ openingHours, handleChange }) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  const days = getDays(t);

  return (
    <div className={classes.formEntry}>
      {openingHours.map((day, index) => (
        <Grid
          key={days[index]}
          container
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12} md={1}>
            <Typography variant="body2">{days[index]}:</Typography>
          </Grid>
          {!day.closed && (
            <>
              <Grid item xs={12} md={2}>
                <TextField
                  label={t('openingHoursField:since')}
                  name={`from-${index}`}
                  type="time"
                  value={day.from}
                  onChange={(e) => handleChange(index, "from", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  label={t('openingHoursField:to')}
                  name={`to-${index}`}
                  type="time"
                  value={day.to}
                  onChange={(e) => handleChange(index, "to", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <IconButton onClick={() => handleChange(index, "closed", true)}>
                  <Close />
                </IconButton>
              </Grid>
            </>
          )}
          {day.closed && (
            <Grid item xs={12} md={3}>
              <Typography variant="body2">
                {`${t('openingHoursField:closed')} `}
                <IconButton
                  onClick={() => handleChange(index, "closed", false)}
                >
                  <Close />
                </IconButton>
              </Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </div>
  );
};

export default OpeningHoursField;

const getDays = t => [0,1,2,3,4,5,6].map(index => t(`days:${index}`));
