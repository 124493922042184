import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { sendMessage } from "../../../api/messages";
import CardTemplate from "../../../components/CardTemplate";
import { COLORS } from "../../../constants/colors";
import { displayFileName } from "../../../components/FilesPicker";
import { getMultipleMsgFiles } from "../../../api/storage";
import { PicPreview } from "../../../components";
import { useCardStyles } from "../../../styles/card";
import { chooseSender, formatTime, showConversationTitle } from "./utils";
import NewMessageArea from "./NewMessageArea";

const URL_REGEX =
  /(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

const formatMessageContent = (msg) => {
  const urls = msg.match(URL_REGEX) || [];
  const words = msg.split(" ");
  const resultElements = [];
  if (!words) {
    return "";
  }
  words.forEach((word) => {
    if (urls.includes(word.trim())) {
      resultElements.push({ type: "url", content: word });
    } else {
      resultElements.push({ type: "text", content: word });
    }
  });
  return (
    <>
      {resultElements.map((elem, index) => {
        switch (elem.type) {
          case "url":
            return (
              <span key={index}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    elem.content.startsWith("http")
                      ? elem.content
                      : `https://${elem.content}`
                  }
                >
                  {elem.content}
                </a>{" "}
              </span>
            );
          case "text":
          default:
            return <span key={index}>{elem.content} </span>;
        }
      })}
    </>
  );
};

const MessageLine = ({ isAdmin, message, t, userName, humanSender }) => {
  const [files, setFiles] = useState([]);
  const [pics, setPics] = useState([]);

  const classes = useCardStyles();

  useEffect(() => {
    getMultipleMsgFiles(message.files)
      .then((data) => setFiles(data))
      .catch((error) => console.log("error", error));
  }, [message]);

  useEffect(() => {
    getMultipleMsgFiles(message.pictures)
      .then((data) => setPics(data))
      .catch((error) => console.log("error", error));
  }, [message]);

  const getMsgCardProps = () => {
    const result = {
      backgroundColor: "",
      fontColor: "",
      justify: "",
    };
    if (
      (isAdmin && message.sender === "admin") ||
      (!isAdmin && message.sender === "user")
    ) {
      result.backgroundColor = COLORS.blue;
      result.fontColor = COLORS.white;
      result.justify = "flex-end";
      if (message.sender === "user" && message.humanSender) {
        result.opacity = "0.7";
      }
      return result;
    }
    result.backgroundColor = COLORS.superLightGrey;
    return result;
  };

  const msgCardProps = getMsgCardProps();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: msgCardProps.justify,
          padding: "0.5rem",
        }}
      >
        <div style={{ width: "60%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: COLORS.blue,
                fontWeight: "bolder",
              }}
            >
              {chooseSender(isAdmin, message, userName, t, humanSender)}
            </Typography>
            <Typography variant="body2" style={{ color: COLORS.blue }}>
              {formatTime(message.timestamp, t)}
            </Typography>
          </div>
          <Card
            style={{
              borderRadius: "10px",
              marginTop: "0.5rem",
            }}
          >
            <CardContent
              style={{
                backgroundColor: msgCardProps.backgroundColor,
                color: msgCardProps.fontColor,
                opacity: msgCardProps.opacity,
              }}
            >
              <Typography variant="body2">
                {formatMessageContent(message.content)}
                {pics.map((pic) => (
                  <PicPreview
                    key={pic.url}
                    src={pic.url}
                    alt="picture"
                    className={classes.messageImage}
                  />
                ))}
                {files.length ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {files.map((url) => (
                      <a
                        key={url.url}
                        href={url.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: msgCardProps.fontColor,
                        }}
                      >
                        {displayFileName(url.filename)}
                      </a>
                    ))}
                  </div>
                ) : null}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

const Conversation = ({
  conversation,
  t,
  isAdmin,
  isMobile /*newMsgCallback*/,
}) => {
  const [messageText, setMessageText] = useState("");
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imWritting, setImWritting] = useState(false);

  useEffect(() => {
    if (!imWritting) {
      setMessageText("");
    }
    setFiles([]);
    setImages([]);
    const scroll = document.getElementById("conversationMessages");
    scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
  }, [conversation]);

  const changeText = (e) => {
    if (e.target.value) {
      setImWritting(true);
    }
    setMessageText(e.target.value);
  };

  const doSendMessage = () => {
    setImWritting(false);
    sendMessage({
      convId: conversation.id,
      text: messageText,
      isAdmin,
      files,
      images,
      isFacility: conversation.data.isFacility,
    })
      .then(() => {
        // newMsgCallback();
        setMessageText("");
        setFiles([]);
        setImages([]);
      })
      .catch((e) => console.log("error", e));
  };

  return (
    <CardTemplate
      title={
        conversation
          ? `${showConversationTitle(conversation.data.title, t)}${
              conversation.data.userName
                ? ` - ${conversation.data.userName}`
                : ""
            }`
          : t("messages:conversation.empty")
      }
      specialStyles={{
        marginTop: isMobile ? "1rem" : undefined,
        content: {
          paddingBottom: "1rem",
          paddingTop: "1rem",
          paddingLeft: isMobile ? undefined : "1rem",
          paddingRight: isMobile ? undefined : "1rem",
        },
      }}
    >
      <div
        style={{
          height: "calc(75vh - 6rem)",
          display: "flex",
          flexFlow: "column",
        }}
      >
        <div // actual messages window, scrolling
          style={{
            flexGrow: 1,
            overflow: "auto",
          }}
          id={"conversationMessages"}
        >
          <Grid container>
            {conversation && conversation.data && conversation.data.messages
              ? conversation.data.messages.map((msg, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <MessageLine
                      userName={conversation.data.userName}
                      humanSender={msg.humanSender}
                      message={msg}
                      isAdmin={isAdmin}
                      t={t}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        </div>
        {!!conversation && (
          <NewMessageArea
            isMobile={isMobile}
            text={messageText}
            setText={changeText}
            images={images}
            setImages={setImages}
            files={files}
            setFiles={setFiles}
            onSubmit={doSendMessage}
          />
        )}
      </div>
    </CardTemplate>
  );
};

export default Conversation;
