import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../../components/SectionHeader';
import { COLORS } from '../../constants/colors';
import { getNewsPageAfterDoc } from '../../api/news';
import NewsCard from '../news/components/NewsCard';
import NewsDetail from '../news/components/NewsDetail';
import { getUnseenNewsIds } from '../../api/manager';
import useManagedFacilities from '../../hooks/useManagedFacilities';
import FacilityCard_New from '../../components/FacilityCard_new';
import { hideFacility, showFacility } from '../../api/facility';


const Dashboard = ({ changeNewsCount }) => {
    const { t } = useTranslation();
    const [news, setNews] = useState([]);
    const [unseen, setUnseen] = useState([]);
    const [newsDetail, setNewsDetail] = useState();
    const [facilities, setFacilities] = useManagedFacilities();

    const mobileView = window.innerWidth < 650;


    useEffect(() => {
        getNewsPageAfterDoc(undefined, 'desc', undefined, false, 3)
            .then(data => {
                setNews(data.data);
            })
            .catch(error => console.log('error', error));
    }, []);

    useEffect(() => {
        getUnseenNewsIds()
            .then(ids => setUnseen(ids))
            .catch(error => console.log('error', error));
    }, [newsDetail]);

    const closeDetail = () => setNewsDetail();

    const openDetail = clicked => setNewsDetail(clicked);

    const handleShowFacility = async (id) => {
        await showFacility(id);
        const newFacilities = [...facilities];
        newFacilities.find((f) => f.id === id).hidden = false;
        setFacilities(newFacilities);
    };

      const handleHideFacility = async (id) => {
        await hideFacility(id);
        const newFacilities = [...facilities];
        newFacilities.find((f) => f.id === id).hidden = true;
        setFacilities(newFacilities);
    };

    const Facilities = () => (
        <>
            <Typography
                align='left'
                variant='h6'
                style={{
                    color: COLORS.blue,
                    fontWeight: 'bold',
                    paddingLeft: mobileView ?  undefined : '1rem',
                }}
            >
                {t('dashboard:facilities')}
            </Typography>
            {
                facilities.slice(0, 3).map(facility =>
                    <FacilityCard_New
                        key={facility.id}
                        data={facility}
                        // onDelete={event => { event.preventDefault(); handleDelete(facility.id); }}
                        onShow={event => { event.preventDefault(); handleShowFacility(facility.id); }}
                        onHide={event => { event.preventDefault(); handleHideFacility(facility.id); }}
                    />
                )
            }
        </>
    );

    const News = () => (
        <>
            <Typography
                align='left'
                variant='h6'
                style={{
                    color: COLORS.blue,
                    fontWeight: 'bold',
                    paddingLeft: mobileView ?  undefined : '1rem',
                }}
            >
                {t('dashboard:news')}
            </Typography>
            {
                news.map((newsItem, index) =>
                    <NewsCard
                        changeNewsCount={changeNewsCount}
                        news={newsItem}
                        key={index}
                        highlight={unseen.includes(newsItem.id)}
                        openDetail={openDetail}
                        forceMobileView={true}
                        horizontalPadding="1rem"
                    />
                )
            }
        </>
    );

    const Content = () => (
        <>
            <SectionHeader>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                            {t('dashboard:header.title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '1rem' }}>
                            {t('dashboard:header.desc')}
                        </Typography>
                    </Grid>
                </Grid>
            </SectionHeader>
            <Grid container justify='center' align='center'>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{
                        marginTop: '2rem'
                    }}
                >
                    { mobileView ? <Facilities /> : <News />}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{
                        marginTop: '2rem'
                    }}
                >
                    { mobileView ? <News /> : <Facilities /> }
                </Grid>
            </Grid>
        </>
    );

    return (
        newsDetail ?
            <NewsDetail
                backTextPath='dashboard:back'
                news={newsDetail}
                closeDetail={closeDetail}
                horizontalPadding="1rem"
            /> :
            <Content />
    );
};

export default Dashboard;