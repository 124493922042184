import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Modal, Card, CardHeader,
    CardContent, Typography, Grid, Button
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';

import { getAllFacilityDrafts, declineFacilityDraft, acceptFacilityDraft } from '../api/facility';
import useLoader from '../hooks/useLoader';
import { COLORS } from '../constants/colors';
import TitleValue from '../components/TitleValue';
import useLocalities from '../hooks/useLocalities';
import useFacilityTypes from '../hooks/useFacilityTypes';
import { AddFacility } from '../components';


const ACCEPT = 'accept';
const DECLINE = 'decline';

const TableHeader = ({ t }) => (
    <TableHead>
        <TableRow>
            <TableCell>
                <b>{t('newRegistrations:props.name')}</b>
            </TableCell>
            <TableCell>
                <b>{t('newRegistrations:props.types')}</b>
            </TableCell>
            <TableCell>
                <b>{t('newRegistrations:props.address')}</b>
            </TableCell>
            <TableCell>
                <b>{t('newRegistrations:props.localities')}</b>
            </TableCell>
            <TableCell>
                <b>{t('newRegistrations:props.contact')}</b>
            </TableCell>
            <TableCell>
                <b>{t('newRegistrations:action.title')}</b>
            </TableCell>
        </TableRow>
    </TableHead>
);

const DefaultActions = ({ detailClicked, id, actionClicked, openEdit, showDecline }) => (
    <React.Fragment>
        <IconButton
            onClick={() => detailClicked(id)}
            color="primary"
        >
            <InfoIcon />
        </IconButton>
        <IconButton
            onClick={() => actionClicked(ACCEPT, id)}
            style={{
                color: COLORS.green
            }}
        >
            <CheckCircleIcon />
        </IconButton>
        {
            showDecline ?
                <IconButton
                    onClick={() => actionClicked(DECLINE, id)}
                    style={{
                        color: COLORS.red
                    }}
                >
                    <CancelIcon />
                </IconButton> :
                null
        }
        <IconButton
            onClick={() => openEdit(id)}
            style={{ color: 'rgb(0, 41, 60)' }}
        >
            <EditIcon />
        </IconButton>
    </React.Fragment>
);

const ConfirmActions = ({ confirmClicked, t }) => (
    <React.Fragment>
        <Typography variant='body1'>
            {t('newRegistrations:action.confirm')}
        </Typography>
        <Button
            onClick={() => confirmClicked(true)}
            style={{
                border: `2px solid ${COLORS.green}`,
                marginLeft: '5px'
            }}
        >
            {t('newRegistrations:action.yes')}
        </Button>
        <Button
            onClick={() => confirmClicked(false)}
            style={{
                border: `2px solid ${COLORS.red}`,
                marginLeft: '5px'
            }}
        >
            {t('newRegistrations:action.no')}
        </Button>
    </React.Fragment>
);

const TableContent = ({ data, detailClicked, actionClicked, confirm, confirmClicked, chosenId, openEdit, t }) => (
    data.map(draft => (
        <TableRow
            key={draft.id}
        >
            <TableCell>
                {draft.data.name}
            </TableCell>
            <TableCell>
                {draft.data.types.join(', ')}
            </TableCell>
            <TableCell>
                {`${draft.data.street} ${draft.data.number}, ${draft.data.city}`}
            </TableCell>
            <TableCell>
                {draft.data.localities.map(locality => locality).join(', ')}
            </TableCell>
            <TableCell>
                {
                    <>
                        <a
                            href={`mailto:${draft.data.contactEmail}`}
                            style={{
                                color: COLORS.black
                            }}
                        >
                            {draft.data.contactName}
                        </a>
                    </>
                }
            </TableCell>
            <TableCell>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '2rem',
                        width: '10rem'
                    }}
                >
                    {
                        confirm && draft.id === chosenId ?
                            <ConfirmActions confirmClicked={confirmClicked} t={t} /> :
                            <DefaultActions
                                id={draft.id}
                                detailClicked={detailClicked}
                                actionClicked={actionClicked}
                                openEdit={openEdit}
                                showDecline={!draft.data.declined}
                            />
                    }
                </div>
            </TableCell>
        </TableRow>
    ))
);

const indexToDay = (t, index) => t(`days:${index}`);


const DetailModal = ({ opened, draft, closeModal, actionClicked, confirmClicked, confirm, t }) => {
    return (
        <Modal
            open={opened}
            onClose={closeModal}
        >
            {
                draft && draft.data ?
                <Card
                    style={{
                        width: '70vw',
                        height: '50vh',
                        position: 'absolute',
                        marginTop: '15vh',
                        marginLeft: '15vw'
                    }}
                >
                    <CardHeader
                        title={t('newRegistrations:props.detail')}
                        action={
                            <IconButton onClick={closeModal}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent
                        style={{
                            height: 'calc(100% - 64px)'
                        }}
                    >
                        <Grid
                            container
                            style={{
                                height: '80%'
                            }}
                        >
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                            >
                                <TitleValue
                                    title={t('newRegistrations:props.person')}
                                    value={draft.data.contactName}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.contact')}
                                    value={draft.data.contactEmail}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.name')}
                                    value={draft.data.name}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.types')}
                                    value={draft.data.types.join(',')}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.desc')}
                                    value={draft.data.description}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.address')}
                                    value={[
                                        `${draft.data.street} ${draft.data.number}`,
                                        `${draft.data.city}`,
                                        `${draft.data.zipCode}`,
                                        `${draft.data.country}`
                                    ].join(', ')}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.localities')}
                                    value={draft.data.localities.map(item => item).join(',')}
                                    style={{ marginTop: '1rem' }}
                                />
                                <TitleValue
                                    title={t('newRegistrations:props.web')}
                                    value={draft.data.website}
                                    style={{ marginTop: '1rem' }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                            >
                                <Typography
                                    key='openingHours'
                                    variant='body1'
                                    style={{
                                        fontWeight: 'bolder',
                                        marginTop: '1rem'
                                    }}
                                >
                                    {t('newRegistrations:props.openingHours')}
                                </Typography>
                                {
                                    draft.data.openingHours.map((day, index) =>
                                        <TitleValue
                                            key={index}
                                            title={indexToDay(t, index)}
                                            value={
                                                day.closed ?
                                                    t('newRegistrations:props.closed') :
                                                    `${day.from} - ${day.to}`
                                            }
                                            style={{
                                                marginLeft: '2rem'
                                            }}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {
                                confirm ?
                                <>
                                    <Typography
                                        variant='body1'
                                        style={{
                                            fontWeight: 'bolder',
                                            marginRight: '1rem'
                                        }}
                                    >
                                        {t('newRegistrations:action.confirm')}
                                    </Typography>
                                    <Button
                                        key={ACCEPT}
                                        onClick={() => confirmClicked(true)}
                                        style={{
                                            border: `2px solid ${COLORS.green}`,
                                            marginRight: '1rem'
                                        }}
                                    >
                                        {t('newRegistrations:action.yes')}
                                    </Button>
                                    <Button
                                        key={DECLINE}
                                        onClick={() => confirmClicked(false)}
                                        style={{
                                            border: `2px solid ${COLORS.red}`,
                                            marginLeft: '1rem'
                                        }}
                                    >
                                        {t('newRegistrations:action.no')}
                                    </Button>
                                </>
                                :
                                <>
                                    <Button
                                        key={ACCEPT}
                                        onClick={() => actionClicked(ACCEPT, draft.id)}
                                        style={{
                                            border: `2px solid ${COLORS.green}`,
                                            marginRight: '1rem'
                                        }}
                                    >
                                        {t('newRegistrations:action.accept')}
                                    </Button>
                                    {
                                        draft.data.declined ? null :
                                        <Button
                                            key={DECLINE}
                                            onClick={() => actionClicked(DECLINE, draft.id)}
                                            style={{
                                                border: `2px solid ${COLORS.red}`,
                                                marginLeft: '1rem'
                                            }}
                                        >
                                            {t('newRegistrations:action.deny')}
                                        </Button>
                                    }
                                </>
                            }
                        </div>
                    </CardContent>
                </Card> : <></>
            }
        </Modal>
    );
};

const BOX_SHADOW = '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)';

const EditForm = ({ id, closeEdit, confirmClicked, t, showDecline }) => {

    const mobileView = window.innerWidth < 650;

    const adminAddons = [
        <Button
            onClick={() => confirmClicked(true, ACCEPT)}
            variant='contained'
            key='confirm'
            style={{
                marginTop: '1rem',
                marginLeft: mobileView ? 0 : '1rem',
                backgroundColor: COLORS.green,
                color: COLORS.white
            }}
        >
            {t('newRegistrations:action.accept')}
        </Button>
    ];

    if (showDecline) {
        adminAddons.push(
            <Button
                onClick={() => confirmClicked(true, DECLINE)}
                key='decline'
                variant='contained'
                style={{
                    marginTop: '1rem',
                    marginLeft: mobileView ? 0 : '1rem',
                    backgroundColor: COLORS.red,
                    color: COLORS.white
                }}
            >
                {t('newRegistrations:action.deny')}
            </Button>
        );
    }

    adminAddons.push(
        <Button
            onClick={closeEdit}
            key='back'
            variant='contained'
            style={{
                marginTop: '1rem',
                marginLeft: mobileView ? 0 : '1rem',
                backgroundColor: COLORS.blue,
                color: COLORS.white
            }}
        >
            {t('newRegistrations:action.back')}
        </Button>
    );

    return (
        <>
            <AddFacility
                addingDraft={true}
                openedByAdmin={true}
                id={id}
                adminAddons={adminAddons}
            />
        </>
    );
};

const NewRegistrations = () => {
    const [drafts, setDrafts] = useState(null);
    const [modal, setModal] = useState(false);
    const [chosenId, setChosenId] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [action, setAction] = useState(false);
    const [edit, setEdit] = useState(false);
    const { startLoading, endLoading } = useLoader();
    const { t } = useTranslation();
    const localities = useLocalities();
    const types = useFacilityTypes();

    const resolveLocalities = data => {
        return data.map(draft => {
            const newLocalities = [];
            for (const countryId in localities) {
                for (const locality of localities[countryId]) {
                    if (draft.data.localities.includes(locality.id)) {
                        newLocalities.push(locality.name);
                    }
                }
            }
            return {
                id: draft.id,
                data: {
                    ...draft.data,
                    localities: newLocalities
                }
            };
        });
    }

    const resolveTypes = data => {
        return data.map(draft => {
            const newTypes = [];
            for (const type of types) {
                if (draft.data.types.includes(type.id)) {
                    newTypes.push(type.name);
                }
            }
            return {
                id: draft.id,
                data: {
                    ...draft.data,
                    types: newTypes
                }
            };
        });
    }

    useEffect(() => {
        startLoading();
        getAllFacilityDrafts()
            .then(data => {
                setDrafts(resolveLocalities(resolveTypes(data)));
            })
            .catch(error => {
                console.log('error', error);
            })
            .finally(() =>
                endLoading()
            );
      }, [localities, types]);

    useEffect(() => {
        if (action) {
            startLoading();
            getAllFacilityDrafts()
                .then(data => {
                    setModal(false);
                    setDrafts(resolveLocalities(resolveTypes(data)));
                    setAction(false);
                })
                .catch(error => console.log('error', error))
                .finally(() => endLoading());
        }
    }, [action, localities, types]);

    const closeModal = () => {
        setConfirm(undefined);
        setModal(false);
    }

    const detailClicked = id => {
        setConfirm(undefined);
        setChosenId(id);
        setModal(true);
    }

    const actionClicked = (action, id) => {
        setChosenId(id);
        setConfirm(action);
    }

    const openEdit = id => {
        setConfirm(undefined);
        setChosenId(id);
        setEdit(true);
    }

    const closeEdit = () => {
        setEdit(null);
        setChosenId(null);
        setAction(true);
    }

    const confirmClicked = (chosen, fromEdit) => {
        if (chosen) {
            switch (fromEdit ? fromEdit : confirm) {
            case ACCEPT:
                startLoading();
                acceptFacilityDraft(drafts.find(item => item.id === chosenId))
                    .then(() => setAction(true))
                    .catch(error => console.log('error', error))
                    .finally(() => endLoading());
                break;
            case DECLINE:
                startLoading();
                declineFacilityDraft(chosenId)
                    .then(() => setAction(true))
                    .catch(error => console.log('error', error))
                    .finally(() => endLoading());
                break;
            default:
                // throw error?
            }
        }
        closeModal();
        setEdit(false);
        setChosenId(null);
    }

    if (edit) {
        return (
            <EditForm
                t={t}
                id={chosenId}
                closeEdit={closeEdit}
                confirmClicked={confirmClicked}
                showDecline={!drafts.find(draft => draft.id == chosenId).data.declined}
            />
        );
    }

    const comparator = (a, b) => (a.data.name.localeCompare(b.data.name));

    return (
        <>
            <DetailModal
                opened={modal}
                draft={drafts && chosenId !== null ? drafts.find(item => item.id === chosenId) : {}}
                closeModal={closeModal}
                actionClicked={actionClicked}
                confirmClicked={confirmClicked}
                confirm={confirm}
                t={t}
            />
            <Typography variant='h6'>
                {t('newRegistrations:pending')}
            </Typography>
            <TableContainer
                component={Paper}
                style={{
                    marginTop: '2rem'
                }}
            >
                <Table>
                    <TableHeader t={t} />
                    <TableBody>
                        <TableContent
                            data={drafts ? drafts.filter(draft => !draft.data.declined).sort(comparator) : []}
                            detailClicked={detailClicked}
                            actionClicked={actionClicked}
                            confirmClicked={confirmClicked}
                            openEdit={openEdit}
                            chosenId={chosenId}
                            confirm={confirm}
                            t={t}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='h6' style={{ marginTop: '2rem'}}>
                {t('newRegistrations:declined')}
            </Typography>
            <TableContainer
                component={Paper}
                style={{
                    marginTop: '2rem'
                }}
            >
                <Table>
                    <TableHeader t={t} />
                    <TableBody>
                        <TableContent
                            data={drafts ? drafts.filter(draft => draft.data.declined).sort(comparator) : []}
                            detailClicked={detailClicked}
                            actionClicked={actionClicked}
                            confirmClicked={confirmClicked}
                            openEdit={openEdit}
                            chosenId={chosenId}
                            confirm={confirm}
                            t={t}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default NewRegistrations;