import React, { useRef, useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  getRestaurantProfilePicture,
  uploadRestaurantProfile,
} from "../../api/storage";

import { useFullFormStyles } from "../../styles/form";

const SinglePhotoPicker = ({ url, setUrl, showText = true }) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  const [isLoaded, setLoaded] = useState(false);

  const inputRef = useRef(null);
  const handleUpload = async (event) => {
    const files = inputRef.current.files;
    if (!files || files.length < 1) {
      return;
    }
    setLoaded(false);
    const newUrl = await uploadRestaurantProfile(files[0]);
    setUrl(newUrl);
    event.target.value = null;
  };

  const handleOpenPicker = () =>
    document.getElementById("singlefile-input").click();

  return (
    <>
      {showText && (
        <div className={classes.formEntry}>
          <Typography variant="h6">
            {t("newFacility:add.profilePic")}
          </Typography>
          <Typography variant="body2">
            {t("newFacility:add.profilePicDesc")}
          </Typography>
          <Typography variant="body2">
            {t("newFacility:add.picDesc")}
          </Typography>
        </div>
      )}
      <div className={classes.formEntry}>
        {!!url && (
          <>
            <div className={classes.profilePicFrame}>
              <img
                src={getRestaurantProfilePicture(url, "small")}
                className={classes.profilePicPrev}
                style={{ height: !isLoaded ? 0 : undefined }}
                onLoad={() => setLoaded(true)}
              />
            </div>
            {!isLoaded && (
              <div className={classes.profilePicFrame} style={{ height: 360 }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
        <div className={classes.formEntry}>
          <input
            id="singlefile-input"
            className={classes.inputFile}
            type="file"
            accept="image/jpeg,image/png"
            ref={inputRef}
            onChange={handleUpload}
          />
          {!url && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenPicker}
            >
              {t("newFacility:add.profilePicButton")}
            </Button>
          )}
          {!!url && (
            <Typography variant="body2">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenPicker();
                }}
              >
                {t("newFacility:add.profilePicChange")}
              </a>
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default SinglePhotoPicker;
