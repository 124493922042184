const errorCodes = {
  "auth/email-already-exists": "Zadaná e-mailová adresa je již registrována.",
  "auth/invalid-display-name": "Zadané uživatelské jméno nesmí být prázdné.",
  "auth/invalid-email":
    "Zadaná e-mailová adresa není platná. Zadejte prosím platnou e-mailovou adresu.",
  "auth/invalid-password":
    "Zadané heslo nje příliš slabé. Zadejte prosím heslo o minimálně šesti znacích.",
  "auth/internal-error": "Došlo k chybě, zkuste to prosím znovu.",
  default: "Došlo k chybě, zkuste to prosím znovu.",
};

export const getErrorMessage = (code) => {
  const message = errorCodes[code];
  if (!message) {
    return errorCodes.default;
  }

  return message;
};
