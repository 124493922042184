import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const formatDateString = (from, to, t) => {
  if (!from && !to) {
    return t("facilityMain:posts.plan.unknown");
  }
  const currentYear = moment().format("YYYY");
  if (!to) {
    return `${t("facilityMain:posts.plan.from")} ${moment(from).format(
      `D. M.${moment(from).format("YYYY") === currentYear ? "" : " YYYY"}`
    )}`;
  }
  if (!from) {
    return `${t("facilityMain:posts.plan.to")} ${moment(to).format(
      `D. M.${moment(to).format("YYYY") === currentYear ? "" : " YYYY"}`
    )}`;
  }
  const momentFrom = moment(from);
  const momentTo = moment(to);
  if (momentFrom.isSame(momentTo)) {
    return `${momentFrom.format(
      `D. M.${momentFrom.format("YYYY") === currentYear ? "" : " YYYY"}`
    )}`;
  }
  const showYear = momentFrom.format("YYYY") !== momentTo.format("YYYY");
  return `${momentFrom.format(
    `D. M.${showYear ? " YYYY" : ""}`
  )} - ${momentTo.format(`D. M.${showYear ? " YYYY" : ""}`)}`;
};

const PostPreview = ({ image, title, location, dateFrom, dateTo, onClick }) => {
  const { t } = useTranslation();
  const hasDates = !!dateFrom || !!dateTo;
  return (
    <>
      <Card
        style={{
          width: "342px",
          borderRadius: "10px",
          minHeight: hasDates ? "262px" : "245px",
          boxShadow: "0px 3px 15px #00000029",
        }}
      >
        <CardActionArea onClick={onClick}>
          {image ? (
            <CardMedia
              image={image}
              title="Post photo"
              style={{
                height: "160px",
              }}
            />
          ) : (
            <div
              style={{
                height: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{t("facilityMain:posts.noPhoto")}</Typography>
            </div>
          )}
          <CardContent
            style={{
              paddingTop: "11px",
              paddingLeft: "21px",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
                color: "#00293C",
              }}
            >
              <b>{title}</b>
            </Typography>
            <Divider
              style={{
                width: "300px",
                height: "1px",
                background: "#FF7B60",
                marginTop: "6px",
              }}
            />
            <div style={{ display: "flex" }}>
              <LocationOnIcon
                style={{
                  color: "#FF7B60",
                  marginTop: "7px",
                  height: "20px",
                }}
              />
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#00293C",
                  marginTop: "7px",
                  marginLeft: "5px",
                }}
              >
                {location}
              </Typography>
            </div>
            {hasDates ? (
              <div style={{ display: "flex" }}>
                <EventAvailableIcon
                  style={{ color: "#FF7B60", height: "20px" }}
                />
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#00293C",
                    marginLeft: "5px",
                  }}
                >
                  {formatDateString(dateFrom, dateTo, t)}
                </Typography>
              </div>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default PostPreview;
