import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const FormDialog = ({ title, children, open, onClose, onSave }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onSave} color="primary">
          {t("formDialog:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
