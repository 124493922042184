import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { addFacilityType, updateFacilityType } from "../../api/facility";
import useLoader from "../../hooks/useLoader";
import { useLoginFormStyles } from "../../styles/form";
import { FormDialog } from "../../components";

const FacilityTypesForm = ({ edit = false, open = false, onClose, values }) => {
  const classes = useLoginFormStyles();
  const { startLoading, endLoading } = useLoader();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [sysid, setSysid] = useState("");
  useEffect(() => {
    setName(values ? values.name : "");
    setSysid(values ? values.sysid : "");
  }, [values]);

  const handleAddType = () => {
    startLoading();
    addFacilityType(sysid, name)
      .then(() => {
        setName("");
        setSysid("");
        onClose();
      })
      .catch((error) => {
        console.error(error);
        alert(t("facilityTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const handleEditType = () => {
    startLoading();
    updateFacilityType(values.id, sysid, name)
      .then(() => onClose())
      .catch((error) => {
        console.error(error);
        alert(t("facilityTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const onSave = edit ? handleEditType : handleAddType;

  return (
    <FormDialog
      title={
        edit
          ? t("facilityTypes:edit.formTitle")
          : t("facilityTypes:add.formTitle")
      }
      open={open}
      onClose={onClose}
      onSave={onSave}
    >
      <div className={classes.formEntry}>
        <TextField
          label={t("facilityTypes:sysid.fancy")}
          name="sysid"
          type="text"
          value={sysid}
          onChange={(e) => setSysid(e.target.value)}
          variant="outlined"
          disabled={edit}
        />
      </div>
      <div className={classes.formEntry}>
        <TextField
          label={t("facilityTypes:name.fancy")}
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
        />
      </div>
    </FormDialog>
  );
};

export default FacilityTypesForm;
