import { useState, useEffect } from "react";

import { getCountries } from "../api/locality";
import useLoader from "./useLoader";

const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const { startLoading, endLoading } = useLoader();

  useEffect(() => {
    startLoading();
    getCountries()
      .then((data) => setCountries(data))
      .catch((error) => console.error(error))
      .finally(() => endLoading());
  }, [setCountries]);

  return [countries, setCountries];
};

export default useCountries;
