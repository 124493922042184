import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { VisibilityOff, Visibility, Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { getRestaurantProfilePicture } from "../api/storage";
import { useCardStyles } from "../styles/card";
import Tag from "./Tag";

import { useBasicDataContext } from "../hooks/useBasicData";

import { PicPreview } from "./";
import LikesInfo from "../pages/facilityMain/components/LikesInfo";

const FacilityCard = ({ data, onShow, onHide, onDelete }) => {
  const { name, profilePhoto, localities, id, likes, pins, potential } = data;
  const { types, membership, hidden, countryId } = data;

  const classes = useCardStyles();
  const { t } = useTranslation();

  const {
    facilityTypes,
    membershipTypes,
    localities: localitySource,
  } = useBasicDataContext();

  const membershipType = membershipTypes.find((m) => m.id === membership);
  const membershipName = membershipType ? membershipType.name : "";

  let mappedLocalities = [];

  if (localitySource && localitySource[countryId]) {
    mappedLocalities = localities
      .map((l) => {
        const src = localitySource[countryId].find((ls) => ls.id === l);
        if (src) {
          return src.name;
        }

        return null;
      })
      .filter((l) => !!l);
  }

  // TODO responsive !!!
  return (
    <RouterLink
      to={`/facilities/${id}`}
      style={{ cursor: "pointer", textDecoration: "none" }}
    >
      <Grid container className={classes.facilityCard}>
        <Grid item>
          <PicPreview
            src={
              profilePhoto
                ? getRestaurantProfilePicture(profilePhoto, "tiny")
                : null
            }
            alt={`${t("facilityCard:profilePic")} '${name}'`}
            className={classes.cardImage}
            style={{ filter: hidden ? "grayscale(100%)" : undefined }}
          />
        </Grid>
        <Grid item className={classes.cardContent}>
          <Typography variant="h5" style={{ fontSize: 20, fontWeight: "bold" }}>
            {name}{" "}
            {hidden ? (
              <span style={{ color: "gray" }}>
                {t("facilityCard:notShown")}
              </span>
            ) : null}
          </Typography>
          <Typography variant="body2" className={classes.cardDetails}>
            {mappedLocalities
              .sort((fst, snd) => (fst > snd ? 1 : -1))
              .join("; ")}
          </Typography>
          <Typography variant="body2" className={classes.cardDetails}>
            {types.map((t) => (
              <TypeTag key={t} id={t} list={facilityTypes} />
            ))}
          </Typography>
          <LikesInfo
            facilityID={id}
            likes={likes}
            pins={pins}
            potential={potential}
            className={classes.cardDetails}
          />
          <Typography variant="body2" className={classes.cardDetails}>
            {`${t("facilityCard:status")}: ${membershipName}`}
          </Typography>
          <div
            className={classes.cardDetails}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {hidden ? (
              <IconButton onClick={onShow} title={t("facilityCard:show")}>
                <Visibility />
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {t(`facilityCard:show`)}
                </Typography>
              </IconButton>
            ) : (
              <IconButton onClick={onHide} title={t("facilityCard:hide")}>
                <VisibilityOff />
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {t(`facilityCard:hide`)}
                </Typography>
              </IconButton>
            )}
            {
              // isAdmin ?
              //   <IconButton onClick={onDelete} title={t("facilityCard:delete")}>
              //     <Delete />
              //   </IconButton> :
            }
          </div>
        </Grid>
      </Grid>
    </RouterLink>
  );
};

export default FacilityCard;

const TypeTag = ({ id, list }) => {
  const type = list.find((t) => t.id === id);
  if (!type) {
    return null;
  }

  return <Tag>{type.name}</Tag>;
};
