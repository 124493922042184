import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

import usePostTypes from "../../../hooks/usePostTypes";
import { useFullFormStyles } from "../../../styles/form";
import Alert from "@material-ui/lab/Alert";
import PostPhotosPicker from "../../posts/PostPhotosPicker";
import { DescriptionField } from "../../../components/facility";
import CustomDatePicker from "../../../components/CustomDatePicker";

const formatTime = (time) => (time && time.seconds ? time.toDate() : time);

const EditModal = ({ post, t, opened, closeEdit, saveEdit }) => {
  const [currentPost, setCurrentPost] = useState(post.data);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState();
  const postTypes = usePostTypes();
  const classes = useFullFormStyles();

  useEffect(() => {
    setCurrentPost(post.data);
  }, [post]);

  useEffect(() => {
    setError("");
  }, [opened]);

  const handleChange = (key, value) =>
    setCurrentPost((prev) => ({ ...prev, [key]: value }));

  const hideShowError = () => setShowError(false);

  const handeSave = async () => {
    if (!currentPost) {
      return;
    }
    if (!currentPost.title || !currentPost.description || !currentPost.type) {
      setShowError(true);
      setError("notEnough");
      return;
    }
    if (
      moment(formatTime(currentPost.to)).isBefore(
        moment(formatTime(currentPost.from))
      )
    ) {
      // TODO nefunguje
      //setShowError(true);
      //setError("wrongDate");
      //return;
    }

    if (!currentPost.useProfile && !currentPost.photos.length) {
      setShowError(true);
      setError("notEnough");
      return;
    }

    setError("");
    saveEdit(currentPost, post.id);
  };

  return (
    <Dialog open={opened} onClose={closeEdit}>
      <DialogTitle>{t("facilityMain:posts.edit.title")}</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.formEntry}
          value={currentPost ? currentPost.title : ""}
          onChange={(event) => handleChange("title", event.target.value)}
          type="text"
          variant="outlined"
          fullWidth
          name="title"
          error={currentPost ? !currentPost.title : true}
          label={t("addPost:items.title")}
        />
        <Select
          id="type"
          className={classes.formEntry}
          value={currentPost ? currentPost.type : ""}
          variant="outlined"
          error={currentPost ? !currentPost.type : true}
          onChange={(event) => handleChange("type", event.target.value)}
          displayEmpty
          fullWidth
        >
          {postTypes.map((type) => (
            <MenuItem value={type.id} key={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.formEntry}>
          <b>{t("addPost:items.photos")}</b>
        </Typography>
        {!!currentPost && (
          <PostPhotosPicker
            urls={currentPost.photos}
            setUrls={(photos) => handleChange("photos", photos)}
          />
        )}
        <div
          className={classes.formEntry}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={currentPost ? currentPost.useProfile : false}
            onChange={(event) =>
              handleChange("useProfile", event.target.checked)
            }
          />
          <Typography>{t("facilityMain:posts.useProfile")}</Typography>
        </div>
        <DescriptionField
          value={currentPost ? currentPost.description : ""}
          setValue={(val) => handleChange("description", val)}
        />
        <Grid container direction="row" spacing={1}>
          {/*TODO: errors*/}
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              id="dateFrom"
              className={classes.formEntry}
              label={t("addPost:items.dateFrom")}
              onChange={(date) => handleChange("from", date)}
              value={
                currentPost && currentPost.from
                  ? formatTime(currentPost.from)
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              id="dateTo"
              className={classes.formEntry}
              label={t("addPost:items.dateTo")}
              onChange={(date) => handleChange("to", date)}
              value={
                currentPost && currentPost.to
                  ? formatTime(currentPost.to)
                  : null
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handeSave}>
          {t("facilityMain:posts.edit.save")}
        </Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        <Snackbar open={!!error && showError}>
          <Alert severity="error" onClose={hideShowError}>
            {t(`addPost:errors.${error}`)}
          </Alert>
        </Snackbar>
      </div>
    </Dialog>
  );
};

export default EditModal;
