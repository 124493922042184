import ListIcon from '@material-ui/icons/List';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import RoomIcon from '@material-ui/icons/Room';
import HelpIcon from '@material-ui/icons/Help';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreIcon from '@material-ui/icons/More';
import SettingsIcon from '@material-ui/icons/Settings';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import { COLORS } from '../../constants/colors';

export const ADMIN_ROUTER_LINKS = [
    {
        tPath: 'main:types',
        to: '/types',
        Icon: ({ style }) => <ListIcon style={{ ...style, color: COLORS.white }} />,
        key: 'types',
    },
    {
        tPath: 'main:localities',
        to: '/localities',
        Icon: ({ style }) => <RoomIcon style={{ ...style, color: COLORS.white }} />,
        key: 'localities',
    },
    {
        tPath: 'main:newRegistrations',
        to: '/newRegistrations',
        Icon: ({ style }) => <HourglassEmptyIcon style={{ ...style, color: COLORS.white }} />,
        key: 'newRegistrations',
    },
    {
        tPath: 'main:facilitiesManager',
        to: '/facilitiesManager',
        Icon: ({ style }) => <RestaurantIcon style={{ ...style, color: COLORS.white }} />,
        key: 'facilitiesManager',
    },
    {
        tPath: 'main:news',
        to: '/news',
        Icon: ({ style }) => <AnnouncementIcon style={{ ...style, color: COLORS.white }} />,
        key: 'news'
    },
    {
        tPath: 'main:messages',
        to: '/messages',
        Icon: ({ style }) => <ChatBubbleIcon style={{ ...style, color: COLORS.white }} />,
        key: 'messages'
    },
    {
        tPath: 'main:settings',
        to: '/settings',
        Icon: ({ style }) => <SettingsIcon style={{ ...style, color: COLORS.white }} />,
        key: 'settings',
    },
    {
        tPath: 'main:menu',
        to: '/menu',
        Icon: ({ style }) => <LocationOnIcon style={{ ...style, color: COLORS.white }} />,
        key: 'menu',
    },
];

export const MANAGER_ROUTER_LINKS = [
    {
        tPath: 'main:manager.dashboard',
        to: '/',
        Icon: ({ style }) => <DashboardIcon style={{ ...style, color: COLORS.white }} />,
        key: 'dashboard'
    },
    {
        tPath: 'main:manager.myFacilities',
        to: '/facilities',
        Icon: ({ style }) => <RestaurantIcon style={{ ...style, color: COLORS.white }} />,
        key: 'myFacilities'
    },
    {
        tPath: 'main:manager.membership',
        to: '/membership',
        Icon: ({ style }) => <LocationOnIcon style={{ ...style, color: COLORS.white }} />,
        key: 'membership'
    },
    {
        tPath: 'main:manager.help',
        to: '/help',
        Icon: ({ style }) => <HelpIcon style={{ ...style, color: COLORS.white }} />,
        key: 'help'
    },
    {
        tPath: 'main:manager.support',
        to: '/support',
        Icon: ({ style }) => <ContactMailIcon style={{ ...style, color: COLORS.white }} />,
        key: 'support'
    },
    {
        tPath: 'main:manager.other',
        to: '/other',
        Icon: ({ style }) => <MoreIcon style={{ ...style, color: COLORS.white }} />,
        key: 'other'
    },
    {
        tPath: 'main:news',
        to: '/news',
        Icon: ({ style }) => <AnnouncementIcon style={{ ...style, color: COLORS.white }} />,
        key: 'news'
    },
    {
        tPath: 'main:messages',
        to: '/messages',
        Icon: ({ style }) => <ChatBubbleIcon style={{ ...style, color: COLORS.white }} />,
        key: 'messages'
    },
    {
        tPath: 'main:manager.settings',
        to: '/settings',
        Icon: ({ style }) => <SettingsIcon style={{ ...style, color: COLORS.white }} />,
        key: 'settings',
    },
    {
        tPath: 'main:menu',
        to: '/menu',
        Icon: ({ style }) => <LocationOnIcon style={{ ...style, color: COLORS.white }} />,
        key: 'menu',
    },
];