import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { COLORS } from "../../../constants/colors";
import { useBasicDataContext } from "../../../hooks/useBasicData";

const LikesInfo = ({
  facilityID,
  likes,
  pins,
  potential,
  className,
  variant = "body2",
  style,
  align,
}) => {
  const { isAdmin } = useBasicDataContext();

  // TODO lokalizace
  return (
    <Typography
      variant={variant}
      className={className}
      style={style}
      align={align}
    >
      Příspěvky a denní menu sleduje{" "}
      <strong> {potential ? potential : 0} lidí</strong>
      {!!isAdmin && !!likes && (
        <>
          , do oblíbených si provozovnu přidalo{" "}
          <strong> {likes ? likes : 0} lidí</strong>
        </>
      )}
      {!!pins && (
        <>
          {" "}
          a denní menu si připnulo <strong> {pins ? pins : 0} lidí</strong>
        </>
      )}
      .{" "}
      {(!!potential || !!likes) && (
        <Link to={`/addPost/${facilityID}`} style={{ textDecoration: "none" }}>
          <strong style={{ color: COLORS.primary }}>Oslovte je!</strong>
        </Link>
      )}
    </Typography>
  );
};

export default LikesInfo;
