import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import InDevel from '../components/InDevel';


const Help = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant='h5'>
                {t('help:title')}
            </Typography>
            <InDevel />
        </div>
    );
};

export default Help;