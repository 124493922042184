import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getRestaurantPicture, uploadRestaurantPhoto } from "../../api/storage";

import { useFullFormStyles } from "../../styles/form";

import { PhotosPicker } from "..";

const FacilityPhotosPicker = ({ urls, setUrls, showText = true }) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  return (
    <>
      {
        showText && (
          <div className={classes.formEntry}>
            <Typography variant="h6">
              {t("newFacility:add.ilustrationPic")}
            </Typography>
            <Typography variant="body2">
              {t("newFacility:add.ilustrationPicDesc")}
            </Typography>
          </div>
        )
      }
      <PhotosPicker
        urls={urls}
        setUrls={setUrls}
        urlMapper={getRestaurantPicture}
        uploadHandler={uploadRestaurantPhoto}
        showText={showText}
      />
    </>
  );
};

export default FacilityPhotosPicker;
