import { Button, Checkbox, Snackbar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import * as geofire from "geofire-common";

import {
    addFacility, getDefaultMembership, getFacilityById, getFacilityDraft, getFacilityDraftById, saveFacility,
    saveFacilityDraft
} from '../../api/facility';
import { getDerivedLocalities } from '../../api/locality';
import { getGdprPdf, getTermsPdf } from '../../api/storage';
import { COLORS } from '../../constants/colors';
import { emailVerified, sendEmail } from '../../hooks/useAuth';
import { useBasicDataContext } from '../../hooks/useBasicData';
import useLoader from '../../hooks/useLoader';
import { facilityDefaults, facilityDraftDefaults } from '../../utils/defaults';
import SectionHeader from '../SectionHeader';
import AddressCard from './components/AddressCard';
import BasicInfoCard from './components/BasicInfoCard';
import ContactCard from './components/ContactCard';
import LocalityCard from './components/LocalityCard';
import OpeningHoursCard from './components/OpeningHoursCard';
import PicCard from './components/PicCard';
import ProfilePicCard from './components/ProfilePicCard';
import SuccessDraft from './components/SuccessfDraft';
import AddFacilityHeader from './headers/AddFacilityHeader';
import EditFacilityHeader from './headers/EditFacilityHeader';
import EmailVerificationHeader from './headers/EmailVerificationHeader';
import StillWaitingHeader from './headers/StillWaitingHeader';


const isFilledEnough = (facility, addingFacility) => {
    let result = true;
    result = result && facility.name;
    result = result && facility.photos.length;
    result = result && !!facility.profilePhoto;
    result = result && !!facility.street;
    result = result && !!facility.city;
    result = result && !!facility.zipCode;
    result = result && facility.localities.length;
    result = result && facility.types.length;
    result = result && !!facility.description;
    if (addingFacility) {
        result = result && !!facility.contactName;
        result = result && !!facility.contactEmail;
        result = result && facility.gdpr;
        result = result && facility.terms;
    }
    return result;
};

const canBeSaved = (facility) => {
    let result = true;
    result = result && facility.gdpr;
    result = result && facility.terms;
    return result;
};


const chooseHeader = ({ addingDraft, handleSendEmail, emailVerified, editing }) => {
    if (addingDraft) {
        if (emailVerified) {
            return <StillWaitingHeader />;
        }
        return <EmailVerificationHeader handleSendEmail={handleSendEmail} />;
    }
    if (editing) {
        return <EditFacilityHeader />
    }
    return <AddFacilityHeader />;
};

const sanitizeLatLng = (value) => {
    if (typeof value === "string") {
        value = value.replace(",", ".");
        value = parseFloat(value);
    }

    return value;
};


const AddFacility = ({ addingDraft, openedByAdmin, id, editing, adminAddons }) => {
    const [facility, setFacility] = useState(addingDraft ? { ...facilityDraftDefaults } : { ...facilityDefaults });

    const [warning, setWarning] = useState(false);
    const [saveWarning, setSaveWarning] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [gdprPdf, setGdprPdf] = useState();
    const [termsPdf, setTermsPdf] = useState();
    const { startLoading, endLoading } = useLoader();
    const { countries } = useBasicDataContext();
    const history = useHistory();
    const emailIsVerified = emailVerified();

    const mobileView = window.innerWidth < 650;


    useEffect(() => {
        let apiCall;
        let withId = false;
        if (addingDraft && openedByAdmin) {
            apiCall = getFacilityDraftById;
            withId = true;
        } else if (editing) {
            apiCall = getFacilityById;
            withId = true;
        } else {
            apiCall = getFacilityDraft;
        }
        startLoading();
        if (withId && id || !withId) {
            apiCall(id).
                then(snapshot => {
                    if (editing) {
                        setFacility(snapshot);
                    } else {
                        if (snapshot.exists) {
                            setFacility(snapshot.data());
                        }
                    }
                }).
                catch(error => console.log('error', error)).
                finally(() => endLoading());
        }
    }, []);

    useEffect(() => {
        getGdprPdf()
            .then(url => setGdprPdf(url))
            .catch(error => console.log('error', error));
    });

    useEffect(() => {
        getTermsPdf()
            .then(url => setTermsPdf(url))
            .catch(error => console.log('error', error));
    });


    // TODO choose a country for locality (rn it's CZ)
    const defaultCountry = countries && countries.length > 0 ? countries[0].id : undefined;
    const { t } = useTranslation();

    const closeWarning = () => setWarning(false);

    const closeSaveWarning = () => setSaveWarning(false);

    const closeSaveSuccess = () => {
        setSaveSuccess(false);
        if (!isFilledEnough(facility, addingDraft)) {
            setWarning(true);
        }
    };

    const closeSuccess = () => setSuccess(false);

    const onChangeValue = (item, newValue) => setFacility(prev => ({ ...prev, [item]: newValue }));

    const handleSave = async () => {

        const finished = isFilledEnough(facility, addingDraft);

        if (addingDraft && !canBeSaved(facility)) {
            setSaveWarning(true);
            return;
        }

        const derivedLocalities = await getDerivedLocalities(
            defaultCountry,
            facility.localities
        );

        let geohash = null;
        let latitude = null;
        let longitude = null;
        if (facility.latitude && facility.longitude) {
            latitude = sanitizeLatLng(facility.latitude);
            longitude = sanitizeLatLng(facility.longitude);
            geohash = geofire.geohashForLocation([latitude, longitude]);
        }

        console.log(geohash);
        console.log(latitude);
        console.log(longitude);

        if (addingDraft) {
            saveFacilityDraft(
                {
                    ...facility,
                    countryId: defaultCountry,
                    derivedLocalities,
                    latitude,
                    longitude,
                    geohash,
                },
                openedByAdmin && id ? id : undefined,
            ).
                then(() => {
                    closeWarning();
                    setSaveSuccess(true);
                    if (finished) {
                        setSuccess(true);
                    }
                }).
                catch(error => {
                    console.log('error', error);
                });
        } else if (editing) {
            saveFacility(
                {
                    ...facility,
                    countryId: defaultCountry,
                    derivedLocalities,
                    latitude,
                    longitude,
                    geohash,
                },
                id
            ).
                then(() => history.push('/facilities')).
                catch(error => console.log('error', error));
        } else {
            const defaultMembership = await getDefaultMembership();
            addFacility({
                ...facility,
                countryId: defaultCountry,
                derivedLocalities,
                latitude,
                longitude,
                geohash,
                membership: defaultMembership ? defaultMembership.id : null,
            }).
                then(() => history.push('/facilities')).
                catch(error => console.log('error', error));
        }
    }

    const handleSendEmail = async () => {
        await sendEmail();
    }

    return (
        <>
            <SectionHeader>
                {chooseHeader({
                    addingDraft,
                    handleSendEmail,
                    emailVerified: emailIsVerified,
                    editing
                })}
            </SectionHeader>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    //marginTop: '8rem'
                }}
            >
                {
                    addingDraft && (
                        <SuccessDraft
                            opened={success}
                            onClose={closeSuccess}
                        />
                    )
                }
                <Snackbar open={warning && !saveWarning}>
                    <Alert severity="warning" onClose={closeWarning}>{t("newFacility:add.uncomplete")}</Alert>
                </Snackbar>
                <Snackbar open={saveWarning}>
                    <Alert severity="error" onClose={closeSaveWarning}>{t("newFacility:add.cantSave")}</Alert>{/**TODO */}
                </Snackbar>
                <Snackbar open={saveSuccess} autoHideDuration={2000} onClose={closeSaveSuccess}>
                    <Alert severity="success" onClose={closeSaveSuccess}>{t("newFacility:add.success")}</Alert>
                </Snackbar>
                <BasicInfoCard
                    title={t('newFacility:cards.basicInfo.title')}
                    desc={t('newFacility:cards.basicInfo.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                <AddressCard
                    title={t('newFacility:cards.address.title')}
                    desc={t('newFacility:cards.address.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                <LocalityCard
                    title={t('newFacility:cards.locality.title')}
                    desc={t('newFacility:cards.locality.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                <ProfilePicCard
                    title={t('newFacility:cards.profilePic.title')}
                    desc={t('newFacility:cards.profilePic.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                <PicCard
                    title={t('newFacility:cards.pic.title')}
                    desc={t('newFacility:cards.pic.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                <OpeningHoursCard
                    title={t('newFacility:cards.openingHours.title')}
                    desc={t('newFacility:cards.openingHours.desc')}
                    facility={facility}
                    onChange={onChangeValue}
                    t={t}
                />
                {
                    addingDraft && (
                        <>
                            <ContactCard
                                title={t('newFacility:cards.contact.title')}
                                desc={t('newFacility:cards.contact.desc')}
                                facility={facility}
                                onChange={onChangeValue}
                                t={t}
                            />
                            <div
                                style={{
                                    marginTop: '3rem',
                                    width: '90%'
                                }}
                            >
                                <Typography variant='body2'>
                                    {t('newFacility:cards.gdprAndTerms.title')}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: '1rem'
                                    }}
                                >
                                    <Checkbox
                                        checked={facility.gdpr}
                                        onChange={e => onChangeValue('gdpr', e.target.checked)}
                                        color='primary'
                                    />
                                    <Typography variant='body2' style={{ marginLeft: '0.5rem' }}>
                                        {t('newFacility:cards.gdpr.part1')}
                                        <a
                                            href={gdprPdf}
                                            target='_blank'
                                            style={{
                                                textDecoration: 'none',
                                                color: COLORS.blue
                                            }}
                                        >
                                            <strong>{t('newFacility:cards.gdpr.part2')}</strong>
                                        </a>
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Checkbox
                                        checked={facility.terms}
                                        onChange={e => onChangeValue('terms', e.target.checked)}
                                        color='primary'
                                    />
                                    <Typography variant='body2' style={{ marginLeft: '0.5rem' }}>
                                        {t('newFacility:cards.terms.part1')}
                                        <a
                                            href={termsPdf}
                                            target='_blank'
                                            style={{
                                                textDecoration: 'none',
                                                color: COLORS.blue
                                            }}
                                            >
                                            <strong>{t('newFacility:cards.terms.part2')}</strong>
                                        </a>
                                    </Typography>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: '3rem',
                                    width: '90%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Typography variant='body1'>
                                    {t('newFacility:problems.text')}
                                    <a
                                        href='mailTo:support@restomat.cz'
                                        style={{
                                            textDecoration: 'none',
                                            fontWeight: 'bolder',
                                            color: COLORS.primary
                                        }}
                                    >
                                        {t('newFacility:problems.link')}
                                    </a>
                                </Typography>
                            </div>

                        </>
                    )
                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: mobileView ? 'column' : 'row'
                    }}
                >
                    <Button
                        color='primary'
                        onClick={handleSave}
                        variant='contained'
                        disabled={addingDraft && !canBeSaved(facility)}
                        style={{
                            marginTop: '1rem'
                        }}
                    >
                        {t('newFacility:save')}
                    </Button>
                    {
                        openedByAdmin && adminAddons.length ?
                            adminAddons.map(comp => comp) :
                            null
                    }
                </div>
            </div>
        </>
    );
};

export default AddFacility;
