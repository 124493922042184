import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { resetPassword } from '../../hooks/useAuth';
import DefaultBackground from './components/DefaultBackground';
import MainCard from './components/MainCard';


const ChangePassword = ({ oobCode }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [pwd, setPwd] = useState('');
    const [pwdAgain, setPwdAgain] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (pwd !== pwdAgain) {
            setError(t('login:changepwd.errors.noMatch'));
            setSuccess(false);
            return;
        }
        if (pwd.length < 8) {
            setError(t('login:changepwd.errors.tooShort'));
            setSuccess(false);
            return;
        }
        resetPassword(pwd, oobCode).
            then(() => {
                setSuccess(true);
                setTimeout(() => history.push('/'), 2000);
            }).
            catch(() => {
                setError(t('login:changepwd.errors.main'));
                setSuccess(false);
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <DefaultBackground>
                <MainCard
                    isChangePwd={true}
                    error={error}
                    values={{
                    pwd: pwd,
                    pwdAgain: pwdAgain,
                    }}
                    setters={{
                    setPwd: setPwd,
                    setPwdAgain: setPwdAgain
                    }}
                    success={success}
                />
            </DefaultBackground>
        </form>
    );
};

export default ChangePassword;