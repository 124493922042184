import React from "react";
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const ConfirmDialog = ({ title, text, open, onClose, onAccept, onDecline }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} color="primary">
          {t('confirmDialog:accept')}
        </Button>
        <Button onClick={onDecline} color="primary" autoFocus>
          {t('confirmDialog:cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
