/**
 * Copied from PV247 sample project.
 */
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  position: "absolute",
  zIndex: 1000,
  backgroundColor: "rgba(255,255,255,0.8)",
};
const Loader = () => (
  <div style={loaderStyle}>
    <CircularProgress size="4vw" />
  </div>
);

export default Loader;
