import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";

import { postDefaults } from "../../utils/defaults";
import { useFullFormStyles } from "../../styles/form";
import usePostTypes from "../../hooks/usePostTypes";
import { savePost } from "../../api/post";
import WizardStepOne from "./wizardSteps/WizardStepOne";
import WizardStepTwo from "./wizardSteps/WizardStepTwo";
import WizardConclusion from "./wizardSteps/WizardConclusion";
import PostPreview from "./PostPreview";
import { getFacilityById } from "../../api/facility";
import { getRestaurantProfilePicture, getPostPicture } from "../../api/storage";

const validate = (post) => {
  const errors = {};
  if (!post.title) {
    errors.title = true;
  }
  if (!post.description) {
    errors.description = true;
  }
  if (!post.type) {
    errors.type = true;
  }

  if (!post.useProfile && (!post.photos || post.photos.length === 0)) {
    errors.photos = true;
  }

  if (post.from && post.to) {
    if (moment(post.to).isBefore(moment(post.from))) {
      errors.dateFrom = "toAfterFrom";
      errors.dateTo = "toAfterFrom";
    }
  }
  if (post.from) {
    if (moment(post.from).isBefore(moment().subtract(1, "months"))) {
      errors.dateFrom = "tooFarAway";
    }
  }
  if (post.to) {
    if (moment(post.to).isBefore(moment())) {
      errors.dateTo = "toBeforeToday";
    }
  }
  if (post.plannedFrom && post.plannedTo) {
    if (moment(post.plannedTo).isBefore(moment(post.plannedFrom))) {
      errors.plannedFrom = true;
      errors.plannedTo = true;
    }
  }
  return errors;
};

const areObjectEqual = (fst, snd) => {
  if (Object.keys(fst).length !== Object.keys(snd).length) {
    return false;
  }
  for (const key in fst) {
    if (fst[key] !== snd[key]) {
      return false;
    }
  }
  return true;
};

const WIZARD_STEPS = [
  WizardStepOne,
  WizardStepTwo,

  // TODO add more steps here

  WizardConclusion, // this one has to be the last one
];

const AddPost = () => {
  const [wizardStep, setWizardStep] = useState(0);
  const [post, setPost] = useState({ ...postDefaults });
  const [errors, setErrors] = useState(null);
  const [facilityName, setFacilityName] = useState();
  const [facilityProfile, setFacilityProfile] = useState();

  const { facilityId } = useParams();
  const postTypes = usePostTypes();
  const { t } = useTranslation();
  const classes = useFullFormStyles();
  const history = useHistory();

  useEffect(() => {
    getFacilityById(facilityId)
      .then((facility) => {
        setFacilityName(facility.name);
        setFacilityProfile(
          getRestaurantProfilePicture(facility.profilePhoto, "small")
        );
      })
      .catch((error) => console.log("error", error));
  }, [facilityId]);

  const handleSave = async () => {
    savePost(post, facilityId)
      .then(() => {
        history.push(`/facilities/${facilityId}`);
      })
      .catch((error) => console.log("error", error));
  };

  const handleWizardStepChange = (diff) => {
    const attChange = wizardStep + diff;
    if (diff > 0) {
      setWizardStep(Math.min(attChange, WIZARD_STEPS.length - 1));
    } else {
      setWizardStep(Math.max(attChange, 0));
    }
  };

  const handleChange = (key, value) =>
    setPost((prev) => ({ ...prev, [key]: value }));

  const CurrentWizardStep = WIZARD_STEPS[wizardStep];

  const largeSpacing = wizardStep === WIZARD_STEPS.length - 1 ? 12 : 6;
  const currentErrors = validate(post);
  if (
    wizardStep === WIZARD_STEPS.length - 1 &&
    (!errors || !areObjectEqual(errors, currentErrors))
  ) {
    setErrors(currentErrors);
  }

  const getPreviewPicture = () => {
    if (post.useProfile) {
      return facilityProfile;
    }

    if (post.photos && post.photos.length > 0) {
      return getPostPicture(post.photos[0]);
    }

    return undefined;
  };

  const handlePreviewClick = () => {
    // TODO this might cause bugs if we add multiple file pickers in the future
    const fileInput = document.getElementById("multifile-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={largeSpacing}
        lg={largeSpacing}
        xl={largeSpacing}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          style={{
            marginBottom: "2rem",
          }}
        >
          {facilityName}: {t("addPost:header")}
        </Typography>
        <PostPreview
          image={getPreviewPicture()}
          title={post.title}
          location={facilityName}
          dateFrom={post.from}
          dateTo={post.to}
          onClick={handlePreviewClick}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={largeSpacing}
        lg={largeSpacing}
        xl={largeSpacing}
      >
        <CurrentWizardStep
          changeStep={handleWizardStepChange}
          classes={classes}
          errors={errors}
          handleChange={handleChange}
          handleSave={handleSave}
          post={post}
          postTypes={postTypes}
          t={t}
        />
      </Grid>
    </Grid>
  );
};

export default AddPost;
