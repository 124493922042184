import { createTheme } from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import grey from "@material-ui/core/colors/grey";

import "../styles/main.css";
import { COLORS } from '../constants/colors';

const toolbarColor = lightBlue[800];
const fontColor = grey[50];

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#ff7b60",
      contrastText: "#ffffff"
    },
    secondary: {
      main: fontColor,
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: "#ccc"
      },
      colorSecondary: {
        "&$checked": {
          color: COLORS.primary
        }
      },
      track: {
        opacity: 0.7,
        backgroundColor: COLORS.grey,
        "$checked$checked + &": {
          opacity: 0.7,
          backgroundColor: COLORS.primary
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: '25px'
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: COLORS.primary,
        },
      },
    },
  }
});

export default mainTheme;
