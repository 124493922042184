import { Button, Snackbar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useState } from 'react';


const WizardConclusion = ({ classes, changeStep, post, t, handleSave, errors }) => {
    const [notFilledEnough, setNotFilledEnough] = useState();
    const [wrongDate, setWrongDate] = useState();
    const [wrongPlan, setWrongPlan] = useState();

    const planningSentence = [
        `${t('addPost:items.planning.desc1')}`
    ];
    if (post.plannedFrom && post.plannedTo) {
        planningSentence.push(
            t('addPost:items.planning.desc2'),
            `${moment(post.plannedFrom).format('D. M. YYYY')}`,
            `${t('addPost:items.planning.desc3')}`,
            `${moment(post.plannedTo).format('D. M. YYYY')}`
        );
    } else {
        if (post.plannedTo) {
            planningSentence.push(
                `${t('addPost:items.planning.desc3')}`,
                `${moment(post.plannedTo).format('D. M. YYYY')}`
            );
        } else if (post.plannedFrom) {
            planningSentence.push(
                `${t('addPost:items.planning.desc2')}`,
                `${moment(post.plannedFrom).format('D. M. YYYY')}`
            );
        } else {
            planningSentence.push(
                t('addPost:items.planning.desc4')
            );
        }
    }

    const hideNotFilledEnough = () => setNotFilledEnough(false);
    const hideWrongDate = () => setWrongDate(false);
    const hideWrongPlan = () => setWrongPlan(false);

    [ 'title', 'description', 'type', 'photos' ].forEach(er => {
        if (errors && Object.keys(errors).includes(er) && notFilledEnough === undefined) {
            setNotFilledEnough(true);
            return;
        }
    });

    [ 'dateFrom', 'dateTo' ].forEach(er => {
        if (errors && Object.keys(errors).includes(er) && wrongDate === undefined) {
            setWrongDate(true);
            return;
        }
    });

    [ 'plannedFrom', 'plannedTo' ].forEach(er => {
        if (errors && Object.keys(errors).includes(er) && wrongPlan === undefined) {
            setWrongPlan(true);
            return;
        }
    });
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 'fit-content' }}>
                {
                    notFilledEnough ?
                        <Snackbar open={!!(errors && Object.keys(errors).length)}>
                            <Alert severity='error' onClose={hideNotFilledEnough}>
                                {t('addPost:errors.notEnough')}
                            </Alert>
                        </Snackbar> :
                        null
                }
                {
                    wrongDate ?
                        <Snackbar open={!!(errors && Object.keys(errors).length)}>
                            <Alert severity='error' onClose={hideWrongDate}>
                                {t(`addPost:errors.${errors.dateFrom ? errors.dateFrom : errors.dateTo}`)}
                            </Alert>
                        </Snackbar> :
                        null
                }
                {
                    wrongPlan ?
                        <Snackbar open={!!(errors && Object.keys(errors).length)}>
                            <Alert severity='error' onClose={hideWrongPlan}>
                                {t('addPost:errors.wrongPlanDate')}
                            </Alert>
                        </Snackbar> :
                        null
                }
                <Typography className={classes.formEntry}>
                    <b>{t('addPost:items.description')}</b>
                </Typography>
                <Typography className={classes.formEntry}>
                    {post.description}
                </Typography>
                <Typography className={classes.formEntry}>
                    <b>{t('addPost:items.planning.title')}</b>
                </Typography>
                <Typography className={classes.formEntry}>
                    {planningSentence.join(' ')}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        className={classes.formEntry}
                        color='primary'
                        onClick={() => changeStep(-1)}
                        style={{ width: '30%' }}
                        variant='outlined'
                    >
                        {t('addPost:back')}
                    </Button>
                    <Button
                        className={classes.formEntry}
                        color='primary'
                        onClick={handleSave}
                        style={{ width: '30%', marginLeft: '1rem' }}
                        variant='contained'
                        disabled={!errors || !!Object.keys(errors).length}
                    >
                        {t('addPost:save')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WizardConclusion;