import { makeStyles } from "@material-ui/core/styles";

export const useLoginFormStyles = makeStyles({
  formEntry: {
    marginTop: 15,
  },
  error: {
    marginTop: 15,
    color: "red",
  },
});

export const useFullFormStyles = makeStyles({
  formEntry: {
    marginTop: 15,
  },
  error: {
    marginTop: 15,
    color: "red",
  },
  profilePicFrame: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: "gray",
    width: "100%",
    maxWidth: 640,
    //minWidth: 64,
    //minHeight: 36,
  },
  profilePicPrev: {
    width: "100%",
    maxWidth: 640,
    //minWidth: 64,
    //minHeight: 36,
  },
  inputFile: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    "&:focus + label": {
      outline: "1px dotted #000",
    },
    "&+ label *": {
      pointerEvents: "none"
    },
  },
});
