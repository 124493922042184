import { ButtonBase, IconButton, Modal, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CardTemplate from '../../../components/CardTemplate';
import { COLORS } from '../../../constants/colors';
import { getNewsPicture } from '../../../api/storage';
import { useCardStyles } from '../../../styles/card';
import { PicPreview } from '../../../components';


const NewsDetail = ({ news, closeDetail, backTextPath, horizontalPadding = "4rem" }) => {
    const { t } = useTranslation();
    const classes = useCardStyles();
    const [picDetail, setPicDetail] = useState();

    const mobileView = window.innerWidth < 650;

    const closeModal = () => setPicDetail();

    const openModal = pic => setPicDetail(pic);

    const prevPhoto = e => {
        e.stopPropagation();
        const picIndex = news.data.photos.findIndex(p => p === picDetail);
        if (picIndex === 0) {
            setPicDetail(news.data.photos[news.data.photos.length - 1]);
            return;
        }
        setPicDetail(news.data.photos[picIndex - 1]);
    }

    const nextPhoto = e => {
        e.stopPropagation();
        const picIndex = news.data.photos.findIndex(p => p === picDetail);
        if (picIndex === news.data.photos.length - 1) {
            setPicDetail(news.data.photos[0]);
            return;
        }
        setPicDetail(news.data.photos[picIndex + 1]);
    }

    return (
        <>
            <Modal
                open={!!picDetail}
                onClose={closeModal}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={closeModal}
                >
                    {
                        news && news.data.photos.length > 1 &&
                        <ChevronLeftIcon
                            onClick={prevPhoto}
                            style={{
                                borderRadius: '50%',
                                backgroundColor: COLORS.blue,
                                fontSize: mobileView ? '2rem' : '5rem',
                                color: COLORS.primary,
                                zIndex: 10000,
                                cursor: 'pointer'
                            }}
                        />
                    }
                    <PicPreview
                        src={getNewsPicture(picDetail, 'large')}
                        alt='picture'
                        style={{
                            maxWidth: '80vw',
                            maxHeight: '80vh'
                        }}
                    />
                    {
                        news && news.data.photos.length > 1 &&
                        <ChevronRightIcon
                            onClick={nextPhoto}
                            style={{
                                borderRadius: '50%',
                                backgroundColor: COLORS.blue,
                                fontSize: mobileView ? '2rem' : '5rem',
                                color: COLORS.primary,
                                zIndex: 10000,
                                cursor: 'pointer'
                            }}
                        />
                    }
                </div>
            </Modal>
            <div
                style={{
                    width: mobileView ? "100%" : `calc(100% - ${horizontalPadding} - ${horizontalPadding})`,
                    paddingLeft: mobileView ?  undefined : horizontalPadding,
                    paddingRight: mobileView ? undefined : horizontalPadding,
                    marginTop: '2rem',
                }}
            >
                <div
                    style={{
                        textDecoration: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={closeDetail}
                >
                    <IconButton>
                        <ChevronLeftIcon style={{ color: COLORS.blue }} />
                    </IconButton>
                    <Typography variant='body1' style={{ color: COLORS.blue, fontWeight: 'bolder' }}>
                        {t(backTextPath)}
                    </Typography>
                </div>

            </div>
            {
                news &&
                <CardTemplate
                    title={news.data.title}
                    horizontalPadding={horizontalPadding}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            overflow: 'auto'
                        }}
                    >
                        {
                            news.data.photos.map((pic, index) =>
                                <div style={{ marginLeft: index ? '1rem' : undefined }} key={index}>
                                    <ButtonBase onClick={() => openModal(pic)}>
                                        <PicPreview
                                            src={getNewsPicture(pic, 'tiny')}
                                            alt='picture'
                                            className={classes.cardImage}
                                        />
                                    </ButtonBase>
                                </div>
                            )
                        }
                    </div>
                    {
                        news.data.text.split('\n').map((text, i) =>
                            <Typography
                                key={i}
                                variant='body2'
                                style={{
                                    marginBottom: '0.5rem',
                                    marginTop: i ? 0 : '2rem'
                                }}
                            >
                                {text}
                            </Typography>
                        )
                    }
                </CardTemplate>
            }
        </>
    );
};

export default NewsDetail;