import React, { useState } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CardTemplate from '../../../components/CardTemplate';


export const ChangeLanguageCard = () => {
    const { t, i18n } = useTranslation();

    return (
        <CardTemplate
            title={t('settings:changeLanguage.title')}
            desc={t('settings:changeLanguage.desc')}
        >
            <FormControl component='fieldset'>
                <RadioGroup
                    name='languages'
                    onChange={e => i18n.changeLanguage(e.target.value)}
                    value={i18n.language}
                >
                    <FormControlLabel value='cs' control={<Radio color='primary' />} label={t('settings:changeLanguage.cs')} />
                    <FormControlLabel value='en' control={<Radio color='primary' />} label={t('settings:changeLanguage.en')} />
                </RadioGroup>
            </FormControl>
        </CardTemplate>
    );
};

export default ChangeLanguageCard;