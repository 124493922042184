import React from "react";
import { useParams } from "react-router-dom";

import { AddFacility } from "../components";


const FacilityEdit = () => {
  const { id } = useParams();
  return (
    <AddFacility editing={true} id={id} />
  );
};

export default FacilityEdit;
