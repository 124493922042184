import { useState, useEffect } from "react";

import { getMembershipTypes } from "../api/membership";
import useLoader from "./useLoader";

const useMembershipTypes = () => {
  const [types, setTypes] = useState([]);
  const { startLoading, endLoading } = useLoader();

  useEffect(() => {
    startLoading();
    getMembershipTypes()
      .then((data) => setTypes(data))
      .catch((error) => console.error(error))
      .finally(() => endLoading());
  }, [setTypes]);
  return types;
};

export default useMembershipTypes;
