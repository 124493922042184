import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useFullFormStyles } from "../../../styles/form";

const CouponModal = ({ post, t, opened, closeEdit, saveEdit }) => {
  const [currentPost, setCurrentPost] = useState(post.data);
  const classes = useFullFormStyles();

  useEffect(() => {
    setCurrentPost(post.data);
  }, [post]);

  const handleChange = (key, value) =>
    setCurrentPost((prev) => ({ ...prev, [key]: value }));

  const handleSave = () => {
    saveEdit(currentPost, post.id);
  };

  if (!currentPost) {
    return null;
  }

  return (
    <Dialog open={opened} onClose={closeEdit}>
      <DialogTitle>{t("facilityMain:posts.coupon.state")}</DialogTitle>
      <DialogContent>
        <table>
          <tr>
            <td>
              <Typography variant="body2">
                {t("facilityMain:posts.coupon.total")}:{" "}
              </Typography>
            </td>
            <td>
              <Typography variant="body2">
                <strong>
                  {currentPost.couponUsages
                    ? `${currentPost.couponUsages}x ${t(
                        "facilityMain:posts.coupon.activated"
                      )}`
                    : t("facilityMain:posts.coupon.none")}
                </strong>
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="body2">
                {t("facilityMain:posts.coupon.state")}:
              </Typography>
            </td>
            <td>
              <Typography variant="body2">
                <strong>
                  {currentPost.couponActive
                    ? t("facilityMain:posts.coupon.active")
                    : t("facilityMain:posts.coupon.inactive")}
                </strong>{" "}
                (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleChange("couponActive", !currentPost.couponActive);
                  }}
                >
                  {currentPost.couponActive
                    ? t("facilityMain:posts.coupon.deactivate")
                    : t("facilityMain:posts.coupon.activate")}
                </a>
                )
              </Typography>
            </td>
          </tr>
        </table>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          className={classes.formEntry}
        >
          {t("facilityMain:posts.edit.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponModal;
