import firebase from 'firebase/app';
import { firestore, firebaseAppAuth } from '../utils/firebase';
import { getUserFacilities, getFacilityById, getAllFacilities } from './facility';
import { resolveUsername } from './user';


export const sendMessage = ({ convId, text, isAdmin, files, images, isFacility }) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
        return;
    }
    const msgObject = {
        timestamp: new Date(),
        sender: isAdmin ? 'admin' : 'user',
        content: text,
        files: files,
        pictures: images
    };
    if (isFacility && !isAdmin) {
        msgObject.humanSender = user.uid;
    }
    const seenObj = {};
    if (isAdmin) {
        seenObj.seenUser = false;
    } else {
        seenObj.seenAdmin = false;
    }
    return firestore
        .collection('conversations')
        .doc(convId)
        .update({
            messages: firebase.firestore.FieldValue.arrayUnion(msgObject),
            ...seenObj,
        });
};

export const userCreateConversation = async ({ title, isFacility, facility, msg, images, files }) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
        return;
    }
    const convObject = {
        user: isFacility ? facility : user.uid,
        isFacility: isFacility,
        title: title,
        seenAdmin: false,
        seenUser: true,
        messages: [
            {
                timestamp: new Date(),
                sender: 'user',
                content: msg,
                files: files,
                pictures: images
            }
        ]
    };
    const { id } = await firestore.collection('conversations').add(convObject);
    return id;
};

export const adminCreateConversation = async ({ recievers, message, title, files, images }) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
        return;
    }
    const msgObject = {
        timestamp: new Date(),
        sender: 'admin',
        content: message,
        files: files,
        pictures: images
    };

    if (recievers.length === 1) {
        const convObject = {
            user: recievers[0].id,
            isFacility: !!recievers[0].data.countryId,
            title: title,
            seenAdmin: true,
            seenUser: false,
            messages: [ msgObject ]
        };
        const { id } = await firestore.collection('conversations').add(convObject);
        return id;
    }

    return Promise.all(recievers.map(reciever => {
        const convObject = {
            user: reciever.id,
            isFacility: !!reciever.data.countryId,
            title: title,
            seenAdmin: true,
            seenUser: false,
            messages: [ msgObject ]
        };
        return firestore
            .collection('conversations')
            .add(convObject);
    }));
};

export const readConversation = (isAdmin, conversation) => {
    return firestore
        .collection('conversations')
        .doc(conversation)
        .update({
            [isAdmin ? 'seenAdmin' : 'seenUser']: true
        });
};

export const subscribeAllMessages = async (isAdmin, callback) => {
    const user = firebaseAppAuth.currentUser;

    if (isAdmin) {
        return firestore
            .collection('conversations')
            .onSnapshot(data => {
                const result = [];
                data.forEach(entry => {
                    result.push({
                        id: entry.id,
                        data: entry.data()
                    });
                });
                callback(result);
            });
    }

    const userFacilities = await getUserFacilities();
    const acceptableIds = [ ...userFacilities.map(fac => fac.id), user.uid ];

    return firestore
        .collection('conversations')
        .where('user', 'in', acceptableIds)
        .onSnapshot(data => {
            const result = [];
            data.forEach(entry => {
                result.push({
                    id: entry.id,
                    data: entry.data(),
                });
            })
            callback(result);
        });
};

export const subscribeNewMessages = async (isAdmin, callback) => {
    const user = firebaseAppAuth.currentUser;
    const userFacilities = await getUserFacilities();

    const acceptableIds = [ ...userFacilities.map(fac => fac.id), user.uid ];

    if (isAdmin) {
        return firestore
            .collection('conversations')
            .where('seenAdmin', '==', false)
            .onSnapshot(data => {
                let count = 0;
                data.forEach(() => ++count);
                callback(count);
            });
    }

    return firestore
        .collection('conversations')
        .where('user', 'in', acceptableIds)
        .where('seenUser', '==', false)
        .onSnapshot(data => {
            let count = 0;
            data.forEach(() => ++count);
            callback(count);
        });
};