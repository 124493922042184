import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import useAuth, { signOut } from "./hooks/useAuth";
import useAdmin from "./hooks/useAdmin";
import useManager from "./hooks/useManager";
import useEditor from "./hooks/useEditor";
import mainTheme from "./utils/theme";
import useLoader from "./hooks/useLoader";
import BasicDataProvider from "./hooks/useBasicData";
import Layout from "./components/layout/Layout";
import LocalizedPickerProvider from "./components/LocalizedPickerProvider";

const App = () => {
  const user = useAuth();
  const isAdmin = useAdmin(user);
  const isManager = useManager(user);
  const isEditor = useEditor(user);
  const { isLoading } = useLoader();

  return (
    <div>
      <ThemeProvider theme={mainTheme}>
        <BasicDataProvider
          addditionalData={{ user, isAdmin, isManager, isEditor }}
        >
          <LocalizedPickerProvider>
            <BrowserRouter>
              <Layout
                user={user}
                isAdmin={isAdmin}
                isLoading={isLoading}
                isManager={isManager}
                isEditor={isEditor}
                signOut={signOut}
              />
            </BrowserRouter>
          </LocalizedPickerProvider>
        </BasicDataProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
