import React from "react";

import { TextField } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import { useFullFormStyles } from "../../styles/form";

const MIN_ROWS = 3;

const DescriptionField = ({ value, setValue }) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  return (
    <div className={classes.formEntry}>
      <TextField
        label={t('descriptionField:desc')}
        name="description"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        fullWidth
        multiline
        rows={getDescriptionRows(value)}
      />
    </div>
  );
};

export default DescriptionField;

const getDescriptionRows = (text) => {
  const rows = text.split("\n").length;
  return Math.max(MIN_ROWS, rows);
};
