import firebase from "firebase/app";
import moment from 'moment';

import { firestore, firebaseAppAuth } from '../utils/firebase';
import { updateManager, getAllManagers, getManager } from './manager';


export const saveNews = async (news) => {
    news.created = new Date();
    news.deleted = false;
    return new Promise (async (resolve, reject) => {
        firestore
            .collection('news')
            .add(news)
            .then(async added => {
                try {
                    const managers = await getAllManagers();
                    for (const manager of managers) {
                        const newNews = manager.data.news || [];
                        newNews.push(added.id);
                        await updateManager(manager.id, { ...manager.data, news: newNews });
                    }
                    resolve()
                } catch (error) {
                    reject(error);
                }
            })
            .catch(error => reject(error));
    });
};

const ITEMS_PER_PAGE = 10;

const getNewsFromDoc = (doc, dir) => {
    return new Promise((resolve, reject) => {
        const created = firestore.collection('news').orderBy('created', dir);
        const fromItem = doc ? created.startAfter(doc) : created;
        fromItem
            .limit(30)
            .get()
            .then(data => {
                const lastVisible = data.docs[data.docs.length - 1];
                let result = [];
                data.forEach(entry => result.push({ id: entry.id, data: entry.data() }));
                resolve({ data: result, docs: data.docs, lastDoc: lastVisible });
            })
            .catch(error => reject(error));
    });
};

export const getNewsPageAfterDoc = async (doc, dir = 'asc', isAdmin, findJustOne = false, count) => {
    const currentDate = moment();
    const resultForUser = [];
    let lastSeen = doc;
    while (true) {
        const { data, docs, lastDoc } = await getNewsFromDoc(lastSeen, dir);
        if (!data.length) {
            if (findJustOne) {
                return true;
            }
            // const sortedResultForUser = dir === 'asc' ? resultForUser : resultForUser.reverse();
            const sortedResultForUser = dir === 'asc' ? resultForUser.reverse() : resultForUser;
            return {
                data: sortedResultForUser.map(res => res.data),
                lastDoc: sortedResultForUser[sortedResultForUser.length - 1].doc,
                fstDoc: sortedResultForUser[0] ? sortedResultForUser[0].doc : undefined,
                isThereMore: false,
            };
        }
        lastSeen = lastDoc;
        for (let i = 0; i < data.length; ++i) {
            const newsItem = data[i];
            const newsDoc = docs[i];
            if (!newsItem.data.deleted) {
                if (isAdmin || moment(newsItem.data.plannedFrom.toDate()).isBefore(currentDate)) {
                    if (findJustOne) {
                        return true;
                    }
                    resultForUser.push({ data: newsItem, doc: newsDoc });
                }
            }
            if (!findJustOne && resultForUser.length === (count ? count : ITEMS_PER_PAGE)) {
                for (let j = i; j < data.length; ++j) {
                    if (moment(data[j].data.plannedFrom.toDate()).isBefore(currentDate) && !data[j].data.deleted) {
                        // const sortedResultForUser = dir === 'asc' ? resultForUser : resultForUser.reverse();
                        const sortedResultForUser = dir === 'asc' ? resultForUser.reverse() : resultForUser;
                        return {
                            data: sortedResultForUser.map(res => res.data),
                            lastDoc: sortedResultForUser[sortedResultForUser.length - 1].doc,
                            fstDoc: sortedResultForUser[0] ? sortedResultForUser[0].doc : undefined,
                            isThereMore: true
                        };
                    }
                }
                const isThereMore = await getNewsPageAfterDoc(lastSeen, dir, isAdmin, true, count);
                // const sortedResultForUser = dir === 'asc' ? resultForUser : resultForUser.reverse();
                const sortedResultForUser = dir === 'asc' ? resultForUser.reverse() : resultForUser;
                return {
                    data: sortedResultForUser.map(res => res.data),
                    lastDoc: sortedResultForUser[sortedResultForUser.length - 1].doc,
                    fstDoc: sortedResultForUser[0] ? sortedResultForUser[0].doc : undefined,
                    isThereMore
                };
            }
        }
    }
};

export const getNewsById = id => {
    return new Promise((resolve, reject) => {
        firestore
            .collection("news")
            .get(id)
            .then(data => { data.forEach(entry => {
                    if (entry.id === id) {
                        resolve({ id: entry.id, data: entry.data() })
                    }
                });
            })
            .catch(error => reject(error))
    });
};

const getNewsWhereIdIn = (inArray, currentTime) => {
    return new Promise((resolve, reject) => {
        firestore
            .collection('news')
            .where(firebase.firestore.FieldPath.documentId(), 'in', inArray)
            .get()
            .then(data => {
                let subresult = [];
                data.forEach(entry => subresult.push({ id: entry.id, data: entry.data() }));
                resolve(subresult.filter(item => moment(item.data.plannedFrom.toDate()).isBefore(currentTime)));
            })
            .catch(error => reject(error));
    });
};

export const getUnseenNews = async () => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
        return [];
    }
    const manager = await getManager(user.uid);
    if (!manager) {
        return [];
    }
    const managerData = manager.data();
    if (!managerData || !managerData.news || !managerData.news.length) {
        return [];
    }
    const currentTime = moment();
    const managerNews = managerData.news;
    const subArrays = [[]];
    for (const news of managerNews) {
        if (subArrays[subArrays.length - 1].length === 10) {
            subArrays.push([]);
        }
        subArrays[subArrays.length - 1].push(news);
    }
    const result = [];
    for (const subArray of subArrays) {
        const part = await getNewsWhereIdIn(subArray, currentTime);
        result.push(...part);
    }
    return result;
};

export const getUnseenNewsCount = async () => {
    const news = await getUnseenNews();
    return news.length;
};

export const removeNews = async id => {
    return new Promise (async (resolve, reject) => {
        firestore
            .collection('news')
            .doc(id)
            .update({ deleted: true })
            .then(async () => {
                try {
                    const managers = await getAllManagers();
                    for (const manager of managers) {
                        if (!manager.data.news) {
                            resolve();
                        }
                        if (!manager.data.news.includes(id)) {
                            resolve();
                        }
                        const newNews = manager.data.news.filter(old => old !== id);
                        await updateManager(manager.id, { ...manager.data, news: newNews });
                    }
                    resolve();
                } catch (error) {
                    reject(error);
                }
            })
            .catch(error => reject(error));
    });
};

export const saveEditNews = (news, id) => {
    return firestore
      .collection('news')
      .doc(id)
      .update({ ...news });
  };