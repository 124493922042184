import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CircularProgress, Fab, Tooltip,
    Typography,
} from "@material-ui/core";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
    generateDayMenuImage,
    generateWeekMenuImage,
} from "../../api/menuWeek";
import {useBasicDataContext} from "../../hooks/useBasicData";
import WeekTag from "./components/WeekTag";
import {useTranslation} from "react-i18next";
import {COLORS} from "../../constants/colors";
import DayMenu from "./components/DayMenu";
import "moment/locale/cs";
import DailyPreferencesModal from "./components/DailyPreferencesModal";
import useFacility from "../../hooks/useFacility";
import useWeekMenu from "../../hooks/useWeekMenu";
import ExportSettingsModal from "./components/ExportSettingsModal";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {Save} from "@material-ui/icons";
import ConfirmDialog from "./components/ConfirmDialog";

const MenuEdit = () => {
    const {id} = useParams();
    const history = useHistory();

    const [openDailyPreferences, setOpenDailyPreferences] = useState(false);
    const [openExportSetting, setOpenExportSettings] = useState(false);
    const [openRestoreDataDialog, setOpenRestoreDataDialog] = useState(false);

    const [validationErrorText, setValidationErrorText] = useState(null);

    const {facility, revalidateFacility} = useFacility(id);

    const showValidationError = (text) => {
        setValidationErrorText(text);
    };

    const {
        weekMenuData,
        nextWeek,
        prevWeek,
        selectedWeek,
        setWeekMenuData,
        hasUnsavedData,
        setHasUnsavedData,
        isLoading,
        setIsLoading,
        validateAndSave
    } = useWeekMenu(showValidationError);
    const {user} = useBasicDataContext();
    const {t} = useTranslation();

    const onCloseModal = () => {
        setOpenDailyPreferences(false);
    };

    if (!facility || !user) {
        return null;
    }

    const exportImages = () => {
        setIsLoading(true);
        const imageRequests = [];
        let images = {
            InstagramStory: Array(7).fill(null),
            InstagramPost: Array(7).fill(null),
            FacebookPost: Array(7).fill(null),
            GoogleMapsPost: Array(7).fill(null),
            Print: Array(7).fill(null),
            WeekPrint: [],
            WeekMenuRequestData: {},
            IndividualDaysRequestData: []
        };
        const imageFormats = [
            "InstagramStory",
            "InstagramPost",
            "FacebookPost",
            "GoogleMapsPost",
            "Print"
        ];

        images.WeekMenuRequestData = {
            facilityId: id,
            facilityName: facility.name,
            outputSettings: {
                everydayMealsTop: facility?.exportSettings?.everydayMealsTop ?? false,
                showAllergensPrint:
                    facility?.exportSettings?.showAllergensPrint ?? false,
                showAllergensInstagramPost:
                    facility?.exportSettings?.showAllergensInstagramPost ?? false,
                showAllergensInstagramStory:
                    facility?.exportSettings?.showAllergensInstagramStory ?? false,
                showAllergensGoogle:
                    facility?.exportSettings?.showAllergensGoogle ?? false,
                showAllergensFacebook:
                    facility?.exportSettings?.showAllergensFacebook ?? false,
            },
            dateFrom: selectedWeek.clone().weekday(0).toDate(),
            dateTo: selectedWeek.clone().weekday(6).toDate(),
            menuDays: weekMenuData.days.map((day, dayId) => {
                return {...day, weekdayIndex: dayId};
            }),
        }

        generateWeekMenuImage(images.WeekMenuRequestData)
            .then((response) => response.blob())

            .then((blob) => {
                images = {...images, WeekPrint: [blob]};
            })
            .catch((err) => {
                console.log(err);
            });

        imageFormats.forEach((imageFormat) => {
            weekMenuData?.days?.forEach((day, dayIndex) => {
                if (!day.meals?.length && !day.everyDayMeals?.length) {
                    return;
                }
                const requestData = {
                    facilityId: id,
                    facilityName: facility.name,
                    outputSettings: {
                        everydayMealsTop:
                            facility?.exportSettings?.everydayMealsTop ?? false,
                        showAllergensPrint:
                            facility?.exportSettings?.showAllergensPrint ?? false,
                        showAllergensInstagramPost:
                            facility?.exportSettings?.showAllergensInstagramPost ?? false,
                        showAllergensInstagramStory:
                            facility?.exportSettings?.showAllergensInstagramStory ?? false,
                        showAllergensGoogle:
                            facility?.exportSettings?.showAllergensGoogle ?? false,
                        showAllergensFacebook:
                            facility?.exportSettings?.showAllergensFacebook ?? false,
                    },
                    imageFormat: imageFormat,
                    menuDay: {...day, weekdayIndex: dayIndex},
                };

                imageRequests.push({
                    dayIndex,
                    key: imageFormat,
                    req: generateDayMenuImage(requestData),
                });

                if (imageFormat === "Print") {
                    images.IndividualDaysRequestData.push(requestData);
                }
            });
        });

        Promise.all(
            imageRequests.map(({dayIndex, key, req}) =>
                req
                    .catch((e) => {
                        console.log(e);
                    })
                    .then((response) => response?.blob())
                    .then((_blob) => {
                        images[key][dayIndex] = _blob;
                    })
            )
        ).finally(() => {
            history.push("/export", images);
            setIsLoading(false);
        });
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                }}
            >
                <div>
                    <WeekTag selectedWeek={selectedWeek} t={t}/>
                    <Card
                        variant="outlined"
                        style={{
                            display: "inline-block",
                            width: "fit-content",
                            borderColor: COLORS.blue,
                            borderRadius: "24px",
                            padding: "0",
                            cursor: "pointer",
                            userSelect: "none",
                        }}
                        onClick={prevWeek}
                    >
                        <CardContent
                            style={{
                                padding: 4,
                                display: "flex",
                                justifyContent: "center",
                                marginRight: 4,
                            }}
                        >
                            <LeftIcon fontSize="small"/>
                            <Typography
                                variant="body2"
                                style={{
                                    fontWeight: "bold",
                                    color: COLORS.blue,
                                }}
                            >
                                {t("menuEdit:prevWeek")}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        variant="outlined"
                        style={{
                            display: "inline-block",
                            width: "fit-content",
                            borderColor: COLORS.blue,
                            borderRadius: "24px",
                            margin: "8px 0 0 24px",
                            cursor: "pointer",
                            userSelect: "none",
                        }}
                        onClick={nextWeek}
                    >
                        <CardContent
                            style={{
                                padding: 4,
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: 4,
                            }}
                        >
                            <Typography
                                variant="body2"
                                style={{
                                    fontWeight: "bold",
                                    color: COLORS.blue,
                                }}
                            >
                                {t("menuEdit:nextWeek")}
                            </Typography>
                            <RightIcon fontSize="small"/>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Button
                        variant="contained"
                        style={{
                            margin: 10,
                            backgroundColor: "rgb(0, 41, 60)",
                            color: COLORS.white,
                            fontWeight: "bold",
                        }}
                        onClick={() => {
                            exportImages();
                        }}
                    >
                        {t("menuEdit:export")}
                        {isLoading && (
                            <CircularProgress
                                style={{
                                    marginLeft: 10,
                                    color: COLORS.white,
                                }}
                                size={14}
                            />
                        )}
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            margin: 10,
                            backgroundColor: "rgb(0, 41, 60)",
                            color: COLORS.white,
                            fontWeight: "bold",
                        }}
                        onClick={() => setOpenExportSettings(true)}
                    >
                        {t("menuEdit:exportSettings")}
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: 10,
                            backgroundColor: "rgb(0, 41, 60)",
                            color: COLORS.white,
                            fontWeight: "bold",
                        }}
                        onClick={() => setOpenDailyPreferences(true)}
                    >
                        {t("menuEdit:menuPreferences")}
                    </Button>
                </div>
            </div>
            {[...Array(facility?.menuPreferences?.withoutWeekend ? 5 : 7)].map(
                (value, dayId) => {
                    return (
                        <DayMenu
                            key={dayId}
                            isLoading={isLoading}
                            date={selectedWeek
                                .clone()
                                .locale(navigator.language.split("-")[0])
                                .weekday(dayId)
                                .hour(0)
                                .minute(0)
                                .second(0)
                                .toDate()
                                .getTime()}
                            dayId={dayId}
                            hasUnsavedData={hasUnsavedData}
                            setHasUnsavedData={setHasUnsavedData}
                            setWeekMenuData={setWeekMenuData}
                            weekMenuData={weekMenuData}
                            loaded={weekMenuData != null}
                        />
                    );
                }
            )}
            <DailyPreferencesModal
                open={openDailyPreferences}
                onClose={onCloseModal}
                t={t}
                facility={facility}
                facilityId={id}
            />
            <ExportSettingsModal
                revalidate={revalidateFacility}
                setOpen={setOpenExportSettings}
                facilityId={id}
                facility={facility}
                open={openExportSetting}
                onClose={() => setOpenExportSettings(false)}
            />
            <Tooltip title={t("menuEdit:save")} placement="top">

                <Fab onClick={() => validateAndSave()} style={{position: 'fixed', bottom: '15px', right: '15px', backgroundColor: COLORS.orange, color: 'white'}}>
                    {isLoading ? (
                        <CircularProgress
                            style={{
                                color: COLORS.white,
                            }}
                            size={24}
                        />
                    ) : <Save/>}
                </Fab>
            </Tooltip>

            <ConfirmDialog
                open={validationErrorText != null}
                onAgree={() => {
                    setValidationErrorText(null);
                }}
                t={t}
                text={validationErrorText}
                title={'Nesprávně vyplněná data'}
            />

        </div>
    );
};

export default MenuEdit;
