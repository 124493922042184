import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

// production config
let firebaseConfig = {
  apiKey: "AIzaSyAYV7qjJ4AIPcrdRYIgN4nu0eff-_XBHkk",
  authDomain: "restomat-cz-prod.firebaseapp.com",
  projectId: "restomat-cz-prod",
  storageBucket: "restomat-cz-prod.appspot.com",
  messagingSenderId: "459937560977",
  appId: "1:459937560977:web:107c73c6b3bc4b01810230",
  measurementId: "G-PFXQQ6VM7S",
};

// production endpoint
let functionEndpoint = "https://europe-west3-restomat-cz-prod.cloudfunctions.net";

// development config
if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_DEV_BUILD) {
  firebaseConfig = {
    apiKey: "AIzaSyCiMkRedQzImrvqYAdgz3ahpEXcFxujtzQ",
    authDomain: "restomat-cz.firebaseapp.com",
    projectId: "restomat-cz",
    storageBucket: "restomat-cz.appspot.com",
    messagingSenderId: "289480593889",
    appId: "1:289480593889:web:4fda1a64992b23ca62c2a0",
    measurementId: "G-RM8X556Z5T",
  };

  functionEndpoint = "https://europe-west3-restomat-cz.cloudfunctions.net";
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

/**
 * Auth object
 */
export const firebaseAppAuth = firebaseApp.auth();

/**
 * Firestore object to access database
 */
export const firestore = firebase.firestore();

/**
 * Analytics object
 */
export const analytics = firebase.analytics();

/**
 * Main functions firebase endpoint
 */
export const firebaseEndpoint = functionEndpoint;
