import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import CardTemplate from '../../../components/CardTemplate';
import { changePassword } from '../../../hooks/useAuth';


const ChangePasswordCard = () => {
    const [pwd, setPwd] = useState({ old: '', new: '', newAgain: '' });
    const [showPwd, setShowPwd] = useState({ old: false, new: false, newAgain: false });
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const { t } = useTranslation();

    const changePwd = (type, value) => {
        setPwd({ ...pwd, [type]: value });
    }

    const changeShowPwd = type => {
        setShowPwd({ ...showPwd, [type]: !showPwd[type] });
    }

    const closeError = () => setError();

    const closeSuccess = () => setSuccess();

    const handleSave = () => {
        if (pwd.new !== pwd.newAgain) {
            setError('settings:changePassword.errors.noMatch')
            return;
        }
        if (pwd.new.length < 8) {
            setError('settings:changePassword.errors.tooShort');
            return;
        }
        changePassword(pwd.old, pwd.new).
            then(() => {
                setSuccess('settings:changePassword.success');
                setPwd({ old: '', new: '', newAgain: '' });
            }).
            catch(() => setError('settings:changePassword.errors.other'));
    };

    return (
        <CardTemplate
            title={t('settings:changePassword.title')}
            desc={t('settings:changePassword.desc')}
        >
            <Snackbar open={!!error} onClose={closeError}>
                <Alert severity='error' onClose={closeError}>{t(error)}</Alert>
            </Snackbar>
            <Snackbar open={!!success} autoHideDuration={2000} onClose={closeSuccess}>
                <Alert severity='success' onClose={closeSuccess}>{t(success)}</Alert>
            </Snackbar>
            <Grid container spacing={5} justify='center'>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <TextField
                        name="oldPwd"
                        onChange={e => changePwd('old', e.target.value)}
                        label={t('settings:changePassword.oldPwd')}
                        variant='outlined'
                        value={pwd.old}
                        style={{ width: '100%' }}
                        type={showPwd.old ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={() => changeShowPwd('old')}>
                                    {showPwd.old ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <TextField
                        name="newPwd"
                        onChange={e => changePwd('new', e.target.value)}
                        label={t('settings:changePassword.newPwd')}
                        variant='outlined'
                        value={pwd.new}
                        style={{ width: '100%' }}
                        type={showPwd.new ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={() => changeShowPwd('new')}>
                                    {showPwd.new ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <TextField
                        name="newAgainPwd"
                        onChange={e => changePwd('newAgain', e.target.value)}
                        label={t('settings:changePassword.newPwdAgain')}
                        variant='outlined'
                        value={pwd.newAgain}
                        style={{ width: '100%' }}
                        type={showPwd.newAgain ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={() => changeShowPwd('newAgain')}>
                                    {showPwd.newAgain ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid
                    item
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSave}
                    >
                        {t('settings:changePassword.save')}
                    </Button>
                </Grid>
            </Grid>
        </CardTemplate>
    )
};

export default ChangePasswordCard;