import React, { useState } from 'react';
import { Badge, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import { COLORS } from '../../constants/colors';
import { ADMIN_ROUTER_LINKS, MANAGER_ROUTER_LINKS } from './links';


const Footer = ({ t, isAdmin }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            bottom: 0,
            paddingBottom: '1rem',
            justifyContent: 'center',
            width: '100%'
        }}
    >
        <img
            src="/logo_white.svg"
            style={{
                maxHeight: '30px',
                objectFit: 'contain'
            }}
        />
        <Typography
            align='center'
            style={{
                color: COLORS.white,
                marginTop: '10px'
            }}
            variant='body2'
        >
            {t(`main:footer.${isAdmin ? 'admin' : 'manager'}`)}
        </Typography>
        <Typography
            align='center'
            style={{
                color: COLORS.white,
                marginTop: '5px'
            }}
            variant='body2'
        >
            {`${t('main:footer.version')} 1.01-beta`}
        </Typography>
    </div>
);


const SideMenu = ({ authenticated, isAdmin, isManager, isEditor, newsCount, messagesCount }) => {
    const [loc, setLoc] = useState(window.location.pathname);
    const { t } = useTranslation();

    if (!authenticated) {
        return null;
    }

    const mobileView = window.innerWidth < 650;
    const links = isAdmin ? ADMIN_ROUTER_LINKS : MANAGER_ROUTER_LINKS; //TODO change this after more roles

    const menuDisabled = !isAdmin && !isManager && !isEditor;

    const chooseBadgeCount = link => {
        switch(link.key) {
        case 'news':
            return newsCount;
        case 'messages':
            return messagesCount;
        default:
            return 0;
        }
    };

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={!mobileView}
            PaperProps={{
                style: {
                    backgroundColor: COLORS.blue,
                    width: mobileView ? 0 : '231px',
                    height: 'calc(100% - 64px)',
                    position: 'absolute',
                    padding: 0,
                    margin: 0,
                    top: '64px',
                    left: 0
                }
            }}
        >
            <Divider
                style={{
                    backgroundColor: COLORS.white,
                    opacity: '0.2',
                    height: '2px'
                }}
            />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <List
                        style={{
                            color: COLORS.white,
                            marginTop: '3rem'
                        }}
                    >
                    {
                        links.map(link =>
                            <ListItem
                                button
                                component={Link}
                                disabled={menuDisabled}
                                onClick={() => setLoc(link.to)}
                                key={link.key}
                                to={link.to}
                                style={{
                                    marginLeft: '0.5rem',
                                    marginRight: '0.5rem',
                                    width: 'auto',
                                    borderRadius: '10px',
                                    backgroundColor: link.to === loc ? 'rgba(255,255,255,0.2)' : ''
                                }}
                            >
                                <ListItemIcon style={{ minWidth: '2.5rem' }}>
                                    <Badge
                                        color='primary'
                                        badgeContent={chooseBadgeCount(link)}
                                        key={link.key}
                                    >
                                        <link.Icon />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary={t(link.tPath)} />
                            </ListItem>
                        )
                    }
                    </List>
                    <Footer t={t} isAdmin={isAdmin} />

                </div>
        </Drawer>
    );
};

export default SideMenu;