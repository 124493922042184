import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { COLORS } from '../../../constants/colors';


const EmailVerificationHeader = ({ handleSendEmail }) => {
    const { t } = useTranslation();

    const mobileView = window.innerWidth < 650;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: mobileView ? 'column' : 'row',
                justifyContent: "space-between",
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    //minWidth: '80%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                        {t('newFacility:headers.email.title')}
                    </Typography>
                    <ErrorOutlineIcon style={{ color: COLORS.red, marginLeft: '1rem' }} fontSize='large' />
                </div>
                <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '1rem' }}>
                    {t('newFacility:headers.email.desc1')}
                </Typography>
                <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '0.5rem' }}>
                    {t('newFacility:headers.email.desc2')}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '20%',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSendEmail}
                >
                    {t('newFacility:headers.email.send')}
                </Button>
            </div>
        </div>
    );
};

export default EmailVerificationHeader;