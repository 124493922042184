import React from "react";
import styled from "@material-ui/styles/styled";
import {COLORS} from "../constants/colors";
import {Checkbox} from "@material-ui/core";
import {Check} from '@material-ui/icons';

const CustomCheckbox = styled((props) =>
    <Checkbox checkedIcon={<Check />} {...props} />
)({
    "& .MuiIconButton-label": {
        color: COLORS.primary,
        border: "1px solid #AEAEAE",
        borderRadius: 9
    },
    "& .MuiSvgIcon-root": {
        opacity: 0
    },

    "&.Mui-checked": {
        "& .MuiSvgIcon-root": {
            opacity: 1
        },
    }
});
export default CustomCheckbox;
