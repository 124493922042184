import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../constants/colors';


const Support = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant='h5'>
                {t('support:title')}
            </Typography>
            <Typography variant='body2' style={{ marginTop: '2rem' }}>
                {t('support:content')}
                <span>
                    <a
                        href='mailTo:support@restomat.cz'
                        style={{
                            textDecoration: 'none',
                            color: COLORS.primary,
                            fontWeight: 'bolder'
                        }}
                    >
                        {t('support:email')}
                    </a>
                </span>
            </Typography>
        </div>
    );
};

export default Support;