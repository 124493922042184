import firebase from "firebase/app";
import { firestore, firebaseAppAuth } from "../utils/firebase";

export const addManager = (id, data) => {
  return firestore.collection("managers").doc(id).set(data);
};

export const updateManager = (id, manager) => {
  return firestore
    .collection('managers')
    .doc(id)
    .set(manager);
};

export const getOwnedFacilities = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve([]);
    }

    firestore
      .collection("facilities")
      .where("manager", "==", user.uid)
      .where("deleted", "==", false)
      .get()
      .then((docs) => {
        const facilities = [];
        docs.forEach((entry) =>
          facilities.push({ ...entry.data(), id: entry.id })
        );
        resolve(facilities);
      })
      .catch((error) => reject(error));
  });
};

export const getManager = id => {
  return firestore.collection('managers').doc(id).get();
};

export const getUnseenNewsIds = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve([]);
    }
    firestore
      .collection('managers')
      .doc(user.uid)
      .get()
      .then(data => resolve(data.exists ? (data.data().news || []) : []))
      .catch(error => reject(error));
  });
};

export const seeNews = id => {
  const user = firebaseAppAuth.currentUser;
  if (!user) {
    return;
  }
  firestore
    .collection('managers')
    .doc(user.uid)
    .update({ news: firebase.firestore.FieldValue.arrayRemove(id) });
};

export const getAllManagers = () => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("managers")
        .get()
        .then((data) => {
          let result = [];
          data.forEach((entry) => result.push({ id: entry.id, data: entry.data() }));
          resolve(result);
        })
        .catch((error) => reject(error));
    });
};