import React from 'react';
import { Paper } from '@material-ui/core';


const SectionHeader = ({ children }) => {

    const mobileView = window.innerWidth < 650;

    return (
        <Paper
            style={{
                width: mobileView ? "100%" : "calc(100% - 128px)",
                marginLeft: mobileView ? -16 : -48,
                marginTop: mobileView ? -16 : -48,
                //position: 'fixed',
                //top: mobileView ? '62px' : '64px',
                //left: mobileView ? 0 : '232px',
                //width: `calc(100% - ${mobileView ? '4' : '28'}rem)`,
                paddingLeft: mobileView ? '1rem' : '7rem',
                paddingRight: mobileView ? '1rem' : '7rem',
                paddingTop: mobileView ? '1rem' : '1.5rem',
                paddingBottom: '1.5rem',
                //borderRadius: 0,
                //zIndex: 10000
            }}
        >
            {children}
        </Paper>
    );
};

export default SectionHeader;