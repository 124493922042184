import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from "@material-ui/core";

import SectionHeader from '../../components/SectionHeader';
import { COLORS } from '../../constants/colors';
import ChangePasswordCard from './cards/ChangePasswordCard';
import ChangeLanguageCard from './cards/ChangeLanguageCard';


const Settings = ({}) => {
    const { t } = useTranslation();

    return (
        <>
            <SectionHeader>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }}>
                            {t('settings:header.title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' style={{ color: COLORS.blue, marginTop: '1rem' }}>
                            {t('settings:header.desc')}
                        </Typography>
                    </Grid>
                </Grid>
            </SectionHeader>
            <ChangePasswordCard />
            <ChangeLanguageCard />
        </>
    );
};

export default Settings;