import { useState, useEffect } from "react";

import { getFacilityTypes } from "../api/facility";
import useLoader from "./useLoader";

const useFacilityTypes = () => {
  const [types, setTypes] = useState([]);
  const { startLoading, endLoading } = useLoader();

  useEffect(() => {
    startLoading();
    getFacilityTypes()
      .then((data) => setTypes(data))
      .catch((error) => console.error(error))
      .finally(() => endLoading());
  }, [setTypes]);

  return types;
};

export default useFacilityTypes;
