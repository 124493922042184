import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { COLORS } from '../../constants/colors';
import Conversations from './components/Conversations';
import Conversation from './components/Conversation';
import useAuth from '../../hooks/useAuth';
import useAdmin from '../../hooks/useAdmin';
import { useEffect } from 'react';
import { readConversation, subscribeAllMessages } from '../../api/messages';
import { getUserFacilities } from '../../api/facility';
import { sortMessages } from './components/utils';
import useUsernames from '../../hooks/useUsernames';
import useFacilitiesNames from '../../hooks/useFacilitiesNames';


const MobileContent = ({
    personal, setIsPersonal, t, isMobile, conversation, userMessages, isAdmin, doSetConversation, callback,
    userFacilities
}) => {

    return (
        <>
            {
                conversation ?
                    <>
                        <div
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center'
                            }}
                            onClick={() => doSetConversation('')}
                        >
                            <ChevronLeftIcon
                                style={{
                                    fontSize: '2rem',
                                    color: COLORS.blue
                                }}
                            />
                            <Typography
                                variant='body2'
                                style={{
                                    color: COLORS.blue,
                                    marginLeft: '1rem'
                                }}
                            >
                                {t('messages:conversation.back')}
                            </Typography>
                        </div>
                        <Conversation
                            conversation={conversation}
                            t={t}
                            isAdmin={isAdmin}
                            isMobile={isMobile}
                        />
                    </> :
                    <Conversations
                        isMobile={isMobile}
                        personal={personal}
                        setIsPersonal={setIsPersonal}
                        t={t}
                        userMessages={userMessages}
                        isAdmin={isAdmin}
                        doSetConversation={doSetConversation}
                        newConversationCallback={callback}
                        userFacilities={userFacilities}
                        selectedId={conversation ? conversation.id : undefined}
                    />
            }
        </>
    );
};

const PcContent = ({
    personal, setIsPersonal, t, conversation, userMessages, isAdmin, doSetConversation, callback, userFacilities
}) => {

    return (
        <Grid
            container
        >
            <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
            >
                <Conversations
                    personal={personal}
                    setIsPersonal={setIsPersonal}
                    t={t}
                    userMessages={userMessages}
                    isAdmin={isAdmin}
                    doSetConversation={doSetConversation}
                    newConversationCallback={callback}
                    userFacilities={userFacilities}
                    selectedId={conversation ? conversation.id : undefined}
                />
            </Grid>
            <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
            >
                <Conversation
                    conversation={conversation}
                    t={t}
                    isAdmin={isAdmin}
                    // newMsgCallback={callback}
                />
            </Grid>
        </Grid>
    );
};

const Messages = () => {
    const [personal, setPersonal] = useState(true);
    const [conversation, setConversation] = useState();
    const [userFacilities, setUserFacilities] = useState();
    const [awaitId, setAwaitId] = useState();

    const [userMessages, setUserMessages] = useState([]);

    const { t } = useTranslation();
    const user = useAuth();
    const isAdmin = useAdmin(user);
    const usernames = useUsernames();
    const allFacilities = useFacilitiesNames(); //TODO: this should be done only if admin

    const mobileView = window.innerWidth < 1200;

    useEffect(() => {
        if (!user || isAdmin === null || !isAdmin && !userFacilities) {
            return;
        }
        const callback = conversation => newData => {
            for (const conv of newData) {
                if (isAdmin) {

                    if (conv.data.isFacility) {
                        conv.data.userName = allFacilities[conv.data.user];
                        for (const msg of conv.data.messages) {
                            if (msg.humanSender) {
                                msg.humanSender = usernames[msg.humanSender];
                            }
                        }
                    } else {
                        conv.data.userName = usernames[conv.data.user];
                    }
                } else {
                    if (conv.data.isFacility) {
                        conv.data.userName = userFacilities.find(fac => fac.id === conv.data.user).data.name;
                        for (const msg of conv.data.messages) {
                            if (msg.humanSender) {
                                if (msg.humanSender === user.uid) {
                                    msg.humanSender = undefined; // im gonna cry
                                } else {
                                    msg.humanSender = usernames[msg.humanSender];
                                }
                            }
                        }
                    }
                }
            }
            setUserMessages(sortMessages(newData));
        };
        const unsubscribe = subscribeAllMessages(isAdmin, callback(conversation));

        if (typeof unsubscribe === 'function') {
            return () => unsubscribe();
        }
        return () => {};
    }, [setUserMessages, user, isAdmin, usernames, userFacilities]);

    useEffect(() => {
        if (conversation) {
            for (const conv of userMessages) {
                if (conv.id === awaitId) {
                    setConversation(conv);
                    setAwaitId(undefined);
                    break;
                }
                if (conv.id === conversation.id) {
                    setConversation(conv);
                    break;
                }
            }
        } else {
            if (!mobileView) {
                setConversation(userMessages[0]);
            }
        }
    }, [userMessages, awaitId]);

    useEffect(() => {
        if (!isAdmin) {
            getUserFacilities()
                .then(data => setUserFacilities(data))
                .catch(error => console.log('error', error));
        }
    }, [isAdmin]);

    const setIsPersonal = value => setPersonal(value);

    const doSetConversation = value => {
        setConversation(value);
        if (!value?.data?.seenAdmin && isAdmin || !isAdmin && !value?.data?.seenUser) {
            readConversation(isAdmin, value.id)
                .then(() => {})
                .catch(e => console.log('e', e));
        }
    }

    const callback = id => {
        if (!id) {
            return;
        }
        setAwaitId(id);
    };

    return (
        <div
            style={{
                width: mobileView ? "100%" : "calc(100% - 128px)",
                marginLeft: mobileView ? -16 : -48,
                marginTop: mobileView ? -16 : -48,
                paddingLeft: mobileView ? '1rem' : '7rem',
                paddingRight: mobileView ? '1rem' : '7rem',
                paddingTop: mobileView ? '1rem' : '1.5rem',
                paddingBottom: '1.5rem',
            }}
        >
            <Typography variant='h6' style={{ color: COLORS.blue, fontWeight: 'bold' }} >
                {t('messages:title')}
            </Typography>

            {
                mobileView ?
                    <MobileContent
                        isMobile={mobileView}
                        t={t}
                        personal={personal}
                        setIsPersonal={setIsPersonal}
                        userMessages={userMessages}
                        isAdmin={isAdmin}
                        doSetConversation={doSetConversation}
                        conversation={conversation}
                        callback={callback}
                        userFacilities={userFacilities}
                    /> :
                    <PcContent
                        isMobile={mobileView}
                        t={t}
                        personal={personal}
                        setIsPersonal={setIsPersonal}
                        userMessages={userMessages}
                        isAdmin={isAdmin}
                        doSetConversation={doSetConversation}
                        conversation={conversation}
                        callback={callback}
                        userFacilities={userFacilities}
                    />
            }
        </div>
    );
};

export default Messages;