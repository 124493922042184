import React, { useState } from "react";

import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ConfirmDialog, FacilityCard } from "../components";
import useManagedFacilities from "../hooks/useManagedFacilities";

import { showFacility, hideFacility, deleteFacility } from "../api/facility";
import { useBasicDataContext } from "../hooks/useBasicData";

const ManagerMain = () => {
  const [facilities, setFacilities] = useManagedFacilities();
  const [dialog, setDialog] = useState({ open: false });
  const { t } = useTranslation();

  const { isAdmin, isManager } = useBasicDataContext();

  const handleShow = async (id) => {
    await showFacility(id);
    const newFacilities = [...facilities];
    newFacilities.find((f) => f.id === id).hidden = false;
    setFacilities(newFacilities);
  };

  const handleHide = async (id) => {
    await hideFacility(id);
    const newFacilities = [...facilities];
    newFacilities.find((f) => f.id === id).hidden = true;
    setFacilities(newFacilities);
  };

  const handleDelete = (id) => {
    const closeDialog = () => setDialog({ open: false });

    setDialog({
      title: t("managerMain:deleteConfirm.title"),
      text: t("managerMain:deleteConfirm.desc"),
      open: true,
      onClose: closeDialog,
      onDecline: closeDialog,
      onAccept: async () => {
        closeDialog();
        await deleteFacility(id);
        setFacilities((prev) => prev.filter((f) => f.id !== id));
      },
    });
  };

  return (
    <div>
      <Typography variant="h5">{t("managerMain:title")}</Typography>
      <Typography variant="body2">{t("managerMain:desc")}</Typography>
      {facilities
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((facility) => (
          <FacilityCard
            key={facility.id}
            data={facility}
            onDelete={(event) => {
              event.preventDefault();
              handleDelete(facility.id);
            }}
            onShow={(event) => {
              event.preventDefault();
              handleShow(facility.id);
            }}
            onHide={(event) => {
              event.preventDefault();
              handleHide(facility.id);
            }}
          />
        ))}
      {(isAdmin || isManager) && (
        <div style={{ margin: 10 }}>
          <Link to="/newFacility" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary">
              {t("managerMain:add")}
            </Button>
          </Link>
        </div>
      )}
      <ConfirmDialog {...dialog} />
    </div>
  );
};

export default ManagerMain;
