import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, CircularProgress, Typography } from "@material-ui/core";

import { COLORS } from "../constants/colors";
import useLoader from "../hooks/useLoader";
import { getFacilityById } from "../api/facility";
import { SectionHeader } from "../components";
import EditorsModal from "../components/EditorsModal";
import { getEditors } from "../api/editors";
import EditorCard from "./facilityMain/components/EditorCard";

const FacilityEditors = () => {
  const { t } = useTranslation();
  const { startLoading, endLoading } = useLoader();

  const { id } = useParams();
  const [facility, setFacility] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editorsData, setEditorsData] = useState([]);

  useEffect(() => {
    startLoading();
    getFacilityById(id)
      .then((data) => setFacility({ ...data, id: id }))
      .catch((error) => console.log("error", error))
      .finally(() => endLoading());
  }, []);

  const editors = facility && facility.editors ? facility.editors : [];

  useEffect(() => {
    if (!editors || editors.length === 0) {
      return;
    }

    startLoading();
    getEditors(editors)
      .then((data) => {
        const newEditorsData = [];
        data.forEach((e) => newEditorsData.push({ ...e.data(), id: e.id }));
        setEditorsData(newEditorsData);
      })
      .catch((error) => console.log("error", error))
      .finally(() => endLoading());
  }, [editors]);

  const handleEditorAdd = (id) =>
    setFacility((prev) => ({
      ...prev,
      editors: [...(prev.editors ? prev.editors : []), id],
    }));

  const handleEditorRemove = (id) =>
    setFacility((prev) => ({
      ...prev,
      editors: prev.editors.filter((e) => e !== id),
    }));
  return (
    <div>
      <SectionHeader>
        <Typography
          variant="h6"
          style={{ color: COLORS.blue, fontWeight: "bold" }}
        >
          {t("editors:facilityEditors")}
          {facility ? ` - ${facility.name}` : null}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Typography variant="body2" style={{ color: COLORS.blue }}>
            {t("editors:facilityEditorsDesc")}
          </Typography>
        </div>
      </SectionHeader>
      {!!facility && (
        <div>
          <EditorsModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            facility={facility.id}
            onEditorAdd={handleEditorAdd}
          />
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            disabled={editors.length > 10}
            onClick={() => setOpenModal(true)}
          >
            {t("editors:addEditor")}
          </Button>
          <div>
            {editors.length === 0 && (
              <Typography
                variant="body2"
                style={{ textAlign: "center", marginTop: 20 }}
              >
                {t("editors:noEditors")}
              </Typography>
            )}
            {editors.map((e) => (
              <EditorCard
                key={e}
                id={e}
                editorsData={editorsData}
                facility={facility}
                handleRemove={handleEditorRemove}
              />
            ))}
          </div>
        </div>
      )}
      {!facility && (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default FacilityEditors;
