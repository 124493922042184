import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import FacilityTypes from "./types/FacilityTypes";
import MembershipTypes from "./types/MembershipTypes";
import PostTypes from "./types/PostTypes";

const Types = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h4">{t('types:title')}</Typography>
      <FacilityTypes />
      <PostTypes />
      <MembershipTypes />
    </div>
  );
};

export default Types;
