import React from "react";
import Switch from "@material-ui/core/Switch";
import { styled } from "@material-ui/styles/";
import { COLORS } from "../constants/colors";


const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  marginRight: 10,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginTop: 2,
    marginLeft: 2,
    transitionDuration: "300ms",
    color: COLORS.white,

    "&.Mui-checked": {
      transform: "translateX(20px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: COLORS.primary,
      },
    },

  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 17,
    height: 17,
  },
  "& .MuiSwitch-track": {
    backgroundColor: '#aeaeae',
    borderRadius: 20 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default CustomSwitch;
