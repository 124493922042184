import { firestore } from '../utils/firebase';
import moment from "moment";
import { getUtcNow } from "../utils/utcHelper";

/**
 * creates menu week
 * @param from date from
 * @param facilityId facility id
 * @param facility facility object
 * @param days day objects array
 * @returns {Promise<void>}
 */
export const createMenuWeek = async (from, facilityId, facility, days) => {
    const newMenuWeek = {
        from: from,
        facility: facilityId,
        localities: facility.localities.concat(facility.derivedLocalities),
        days: days,
        hidden: false,
        deleted: false,
        created: getUtcNow()
    };
    if (facility.geohash) {
        newMenuWeek.geohash = facility.geohash;
    }
    await firestore.collection('menuWeeks').add(newMenuWeek);
};

/**
 * updates menu week
 * @param from date from
 * @param facilityId facility id
 * @param days day objects array
 * @param id menu week id
 * @returns {Promise<void>}
 */
export const updateMenuWeek = async (from, facilityId, days, id) => {
    const newMenuWeek = {
        from: from,
        facility: facilityId,
        days: days,
        hidden: false,
        deleted: false,
        created: getUtcNow()
    };
    await firestore.collection('menuWeeks').doc(id).set(newMenuWeek);
};
//---------------------------------decide whether we wont to use update per day or whole week
export const updateMenuWeekDay = async (menuWeekId, dayIndex, day) => {
    const menuWeekRef = firestore.collection('menuWeeks').doc(menuWeekId);

    const menuWeek = (await menuWeekRef.get()).data();
    menuWeek.days[dayIndex] = day;

    await firestore.collection('menuWeeks').doc(menuWeekId).set(menuWeek);
}

/**
 * gets menu week
 * @param id menu week id
 * @param from date from
 * @returns {Promise<null|firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>>}
 */
export const getMenuWeek = async (id, from) => {
    const menuWeekRef = firestore.collection('menuWeeks')
        .where('facility', '==', id)
        .where('from', '==', from)
        .limit(1);

    const menuWeeks = await menuWeekRef.get();
    if (menuWeeks.size === 1) {
        const element = menuWeeks.docs[0];
        return element;
    }

    return null;
};



/**
 * gets menu week filled until
 * @param id menu week id
 * @returns {Promise<null|Date>}
 */
export const getFilledUntil = async (id) => {
    return new Promise((resolve, reject) => {
        const menuWeekRef = firestore.collection('menuWeeks')
            .where('facility', '==', id)
            .orderBy('from', "desc");

        menuWeekRef.get().then(menuWeeks => {
            if (menuWeeks.size === 0) {
                resolve(null);
            }

            menuWeeks.docs.forEach(week => {
                let found = null;
                let allWeek = false;
                for (let i = 0; i < 6; i++) {
                    let day = week.data().days[i];
                    if (day) {
                        if (day.meals && day.meals.map(m => m.everyDay).includes(true)) allWeek = true;
                        if (day.meals?.length > 0 || day.noDailyMenu) {
                            found = moment.unix(week.data().from.seconds).utc().startOf('isoWeek').add(i, "days").toDate();
                        }
                    }
                }

                if (allWeek) {
                    resolve(moment.unix(week.data().from.seconds).utc().endOf('isoWeek').toDate());
                }
                if (found) resolve(found);
            });

            resolve(null);
        });
    });
};



export const generateWeekMenuImage = (weekMenu) => {

    const myHeaders = new Headers();
    const requestOptions = {

        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(weekMenu),
        redirect: 'follow'
    };
    myHeaders.append("Content-Type", "application/json");
    return fetch("https://api.restomat.cz/api/v1/public/images/generate-week-menu-image", requestOptions)
}

export const generateDayMenuImage = (weekMenu) => {

    const myHeaders = new Headers();
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(weekMenu),
        redirect: 'follow'
    };
    myHeaders.append("Content-Type", "application/json");
    return fetch("https://api.restomat.cz/api/v1/public/images/generate-individual-day-image", requestOptions)
}
