import React, { useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useFullFormStyles } from "../styles/form";

import { DraggableGallery } from ".";

const PhotosPicker = ({
  urls,
  setUrls,
  urlMapper,
  uploadHandler,
  variant = "standard",
  showText = true,
}) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  const inputRef = useRef(null);
  const handleUpload = async (event) => {
    const files = inputRef.current.files;
    if (!files || files.length < 1) {
      return;
    }

    const newUrls = [...urls];
    for (let i = 0; i < files.length; i++) {
      const filename = await uploadHandler(files[i]);
      newUrls.push(filename);
    }

    setUrls(newUrls);
    event.target.value = null;

    scrollToEnd("picture-gallery");
  };

  const handleOpenPicker = () =>
    document.getElementById("multifile-input").click();

  return (
    <>
      {variant === "standard" && showText && (
        <div className={classes.formEntry}>
          <Typography variant="body2">
            {t("newFacility:add.picDesc")} {t("newFacility:add.dragDrop")}
          </Typography>
        </div>
      )}
      {!!urls && urls.length > 0 && (
        <DraggableGallery
          id="picture-gallery"
          items={urls}
          setItems={setUrls}
          urlMapper={urlMapper}
        />
      )}
      <div className={classes.formEntry}>
        <input
          id="multifile-input"
          className={classes.inputFile}
          type="file"
          accept="image/jpeg,image/png"
          ref={inputRef}
          onChange={handleUpload}
          multiple
        />
        {(!urls || urls.length === 0) && (
          <Button
            variant="contained"
            color={variant === "standard" ? "primary" : "default"}
            onClick={handleOpenPicker}
          >
            {t("newFacility:add.illustrPicsButton")}
          </Button>
        )}
        {!!urls && urls.length > 0 && (
          <Typography variant="body2">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleOpenPicker();
              }}
            >
              {t("newFacility:add.illustrPicsAdd")}
            </a>
          </Typography>
        )}
      </div>
    </>
  );
};

export default PhotosPicker;

const scrollToEnd = (id) => {
  const element = document.getElementById(id);
  element.scrollTo(element.scrollWidth, 0);
};
