import { useState, useEffect } from "react";
import useLoader from "./useLoader";
import { facilityDefaults } from "../utils/defaults";
import { getFacilityById } from "../api/facility";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useFacility = () => {
  const { id } = useParams()
  const [facility, setFacility] = useState({ ...facilityDefaults });

  const { startLoading, endLoading } = useLoader();

  const revalidateFacility = () => {
    startLoading();
    getFacilityById(id)
      .then((facility) => {

        setFacility({ ...facility, id });

      })
      .catch((error) => console.error(error))
      .finally(() => endLoading());
  }

  useEffect(() => {

    revalidateFacility()
  }, [id]);

  return { facility, revalidateFacility };
};

export default useFacility;

export const isCompleted = (facility) => {
  const {
    name,
    types,
    website,
    description,
    street,
    number,
    city,
    zipCode,
    country,
    localities
  } = facility;

  if (!name) {
    return false;
  }
  if (!types || types.length === 0) {
    return false;
  }
  if (!website) {
    return false;
  }
  if (!description) {
    return false;
  }
  if (!street || !number || !city || !zipCode || !country) {
    return false;
  }
  if (!localities || localities.length === 0) {
    return false;
  }

  return true;
};
