import { useState, useEffect } from "react";

import { getAllLocalities } from "../api/locality";
import useLoader from "./useLoader";

// TODO
const countries = ["ASgP8vViyCZ0ueHI4qL2"];

const useLocalities = () => {
  const [localities, setLocalities] = useState({});
  const { startLoading, endLoading } = useLoader();

  const loadAllCountries = async () => {
    startLoading();
    for (const country of countries) {
      if (localities[country]) {
        continue;
      }
      const newLocalities = { ...localities };
      newLocalities[country] = await getAllLocalities(country);
      setLocalities(newLocalities);
    }
    endLoading();
  };

  useEffect(() => {
    loadAllCountries(countries);
  }, [setLocalities]);

  return localities;
};

export default useLocalities;
