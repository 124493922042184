import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CustomSwitch from "../../../components/CustomSwitch";
import { COLORS } from "../../../constants/colors";
import { saveFacility } from "../../../api/facility";
import BorderlessTextField from "../../../components/BorderlessTextField";

const DailyPreferencesModal = (props) => {
  const { open, onClose, t, facility, facilityId } = props;
  const [soupInPriceEveryDay, setSoupInPriceEveryDay] = useState(
    facility?.menuPreferences?.soupAlwaysInPrice ?? false
  );
  const [mealsFromMondayToFriday, setMealsFromMondayToFriday] = useState(
    facility?.menuPreferences?.withoutWeekend ?? false
  );
  const [timeFrom, setTimeFrom] = useState(
    facility?.menuPreferences?.timeFrom ?? ""
  );
  const [timeTo, setTimeTo] = useState(facility?.menuPreferences?.timeTo ?? "");
  const [timeFromFormatError, setTimeFromFormatError] = useState(false);
  const [timeToFormatError, setTimeToFormatError] = useState(false);
  const timeRegex = new RegExp(/\d\d:\d\d/g);

  useEffect(() => {
    setSoupInPriceEveryDay(
      facility?.menuPreferences?.soupAlwaysInPrice ?? false
    );
    setMealsFromMondayToFriday(
      facility?.menuPreferences?.withoutWeekend ?? false
    );
    setTimeFrom(facility?.menuPreferences?.timeFrom ?? "");
    setTimeTo(facility?.menuPreferences?.timeTo ?? "");
  }, [open, facility]);

  const onSave = () => {
    let hasError = false;
    if (timeFrom) {
      if (!timeFrom.match(timeRegex)) {
        setTimeFromFormatError(true);
        hasError = true;
      } else {
        setTimeFromFormatError(false);
      }
    }

    if (timeTo) {
      if (!timeTo.match(timeRegex)) {
        setTimeToFormatError(true);
        hasError = true;
      } else {
        setTimeToFormatError(false);
      }
    }

    if (hasError) return;

    facility.menuPreferences = {
      withoutWeekend: mealsFromMondayToFriday ?? false,
      soupAlwaysInPrice: soupInPriceEveryDay ?? false,
      timeFrom: timeFrom ?? null,
      timeTo: timeTo ?? null,
    };

    saveFacility(facility, facilityId).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          backgroundColor: "white",
          width: "50%",
          borderRadius: "8px",
          padding: "32px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{t("menuEdit:menuPreferences")}</Typography>
          <IconButton
            style={{
              height: 32,
              width: 32,

              color: COLORS.blue,
            }}
            onClick={() => {
              setSoupInPriceEveryDay(false);
              setMealsFromMondayToFriday(false);
              setTimeTo(null);
              setTimeFrom(null);
              setTimeFromFormatError(null);
              setTimeToFormatError(null);
              onClose();
            }}
          >
            <CancelRounded style={{ fontSize: 32 }} />
          </IconButton>
        </div>
        <Box my={"16px"}>
          <CustomSwitch
            checked={soupInPriceEveryDay}
            onChange={() => setSoupInPriceEveryDay(!soupInPriceEveryDay)}
          />
          <Typography display="inline" variant="body1">
            {t("menuEdit:soupInPriceEveryDay")}
          </Typography>
        </Box>
        <Box my={"16px"}>
          <CustomSwitch
            checked={mealsFromMondayToFriday}
            onChange={() =>
              setMealsFromMondayToFriday(!mealsFromMondayToFriday)
            }
          />
          <Typography display="inline" variant="body1">
            {t("menuEdit:mealsFromMondayToFriday")}
          </Typography>
        </Box>
        <Box mt={"70px"} mb={"40px"}>
          <Typography variant="h6">{t("menuEdit:menuTime")}</Typography>
          <div
            style={{
              border: "1px solid #AEAEAE",
              width: "160px",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BorderlessTextField
              placeholder={t("menuEdit:from")}
              value={timeFrom}
              onChange={(e) => setTimeFrom(e.target.value)}
              variant="outlined"
              error={timeFromFormatError}
              helperText={
                timeFromFormatError ? t("menuEdit:timeFormatError") : ""
              }
              style={{ width: "50%", border: "none" }}
            />
            <div
              style={{
                height: "100%",
                width: "1px",
                backgroundColor: "#777777",
              }}
            >
              &nbsp;
            </div>
            <BorderlessTextField
              placeholder={t("menuEdit:to")}
              value={timeTo}
              onChange={(e) => setTimeTo(e.target.value)}
              variant="outlined"
              error={timeToFormatError}
              helperText={
                timeToFormatError ? t("menuEdit:timeFormatError") : ""
              }
              style={{ width: "50%", border: "none" }}
            />
          </div>
        </Box>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            style={{
              margin: 10,
              backgroundColor: COLORS.primary,
              color: COLORS.white,
              fontWeight: "bold",
            }}
            onClick={() => onSave()}
          >
            {t("menuEdit:save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default DailyPreferencesModal;
