import React, { useEffect, useState } from 'react';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';


const RegisterContent = ({ setters, t, values, error }) => (
    <>
        <Typography
            variant="h5"
            style={{
                marginTop: '2rem',
                fontWeight: 'bolder'
            }}
        >
            {t('login:register.title')}
        </Typography>
        <TextField
            placeholder={t('login:email')}
            name="email"
            type="email"
            value={values.email}
            onChange={e => setters.setEmail(e.target.value)}
            variant="outlined"
            style={{
                marginTop: '2rem'
            }}
        />
        <TextField
            placeholder={t('login:password')}
            name="password"
            type="password"
            value={values.password}
            onChange={e => setters.setPassword(e.target.value)}
            variant="outlined"
            style={{
                marginTop: '1rem'
            }}
        />
        <TextField
            placeholder={t('login:register.passwordAgain')}
            name="passwordAgain"
            type="password"
            value={values.passwordAgain}
            onChange={e => setters.setPasswordAgain(e.target.value)}
            variant="outlined"
            style={{
                marginTop: '1rem'
            }}
        />
        {
            !!error &&
            <Alert
                severity="error"
                style={{
                    marginTop: '1rem',
                    maxWidth: '85%'
                }}
            >
                {error}
            </Alert>
        }
        <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
                marginTop: '1rem'
            }}
        >
            {t('login:submit')}
        </Button>
    </>
);


const LoginContent = ({ setters, t, values, error }) => {
    const [currentMessage, setCurrentMessage] = useState(error);
    const [isError, setIsError] = useState(!!error);

    useEffect(() => {
        setCurrentMessage(error);
        setIsError(!!error);
    }, [error])

    return (
        <>
            <Typography
                variant='h5'
                style={{
                    marginTop: '2rem',
                    fontWeight: 'bolder'
                }}
            >
                {t('login:header')}
            </Typography>
            <TextField
                placeholder={t('login:email')}
                name="email"
                type="email"
                value={values.email}
                onChange={e => setters.setEmail(e.target.value)}
                variant="outlined"
                style={{
                    marginTop: '2rem'
                }}
            />
            <TextField
                placeholder={t('login:password')}
                name="password"
                type="password"
                value={values.password}
                onChange={e => setters.setPassword(e.target.value)}
                variant="outlined"
                style={{
                    marginTop: '1rem'
                }}
            />
            {
                !!currentMessage &&
                <Alert
                    severity={isError ? 'error' : 'success'}
                    style={{
                        marginTop: '1rem',
                        maxWidth: '85%'
                    }}
                >
                    {currentMessage}
                </Alert>

            }
            <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{
                    marginTop: '1rem'
                }}
            >
                {t('login:submit')}
            </Button>
            <Typography
                variant="body1"
                style={{
                    marginTop: error ? '1.325rem' : '5rem'
                }}
            >
                {`${t('login:register.question')} `}
                <Link to="/register">
                    {t('login:register.register')}
                </Link>
            </Typography>
            <Typography
                variant='body2'
                style={{
                    marginTop: error ? '0.5rem' : '1rem',
                }}
            >
                <Link to="/reset">
                    {`${t('login:resetPwd.link')}`}
                </Link>
            </Typography>
        </>
    );
};

const ResetPwdContent = ({ setters, t, values, error, success }) => (
    <>
        <Typography
            variant="h5"
            style={{
                marginTop: '2rem',
                fontWeight: 'bolder'
            }}
        >
            {t('login:resetPwd.title')}
        </Typography>
        <Typography
            variant="body2"
            style={{
                padding: '2rem'
            }}
        >
            {t('login:resetPwd.helper1')}
            <span style={{ fontWeight: 'bold' }}>{t('login:resetPwd.helper2')}</span>
            {t('login:resetPwd.helper3')}
        </Typography>
        <TextField
            placeholder={t('login:email')}
            name="email"
            type="email"
            value={values.email}
            onChange={e => setters.setEmail(e.target.value)}
            variant="outlined"
            style={{
                marginTop: '1rem'
            }}
        />
        {
            (!!error || success) &&
            <Alert
                severity={error ? 'error' : 'success'}
                style={{
                    marginTop: '1rem',
                    maxWidth: '85%'
                }}
            >
                {!!error ? error : t('login:resetPwd.success')}
            </Alert>
        }
        <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
                marginTop: '1rem'
            }}
        >
            {t('login:resetPwd.confirm')}
        </Button>
        <Typography
            variant='caption'
            style={{
                marginTop: error ? '0.5rem' : '1rem',
            }}
        >
            <Link to="/">
                {`${t('login:resetPwd.back')}`}
            </Link>
        </Typography>
    </>
);

const ChangePasswordContent = ({ setters, t, values, error, success }) => (
    <>
        <Typography
            variant="h5"
            style={{
                marginTop: '2rem',
                fontWeight: 'bolder'
            }}
        >
            {t('login:changePwd.title')}
        </Typography>
        <Typography
            variant="body2"
            style={{
                padding: '2rem'
            }}
        >
            {t('login:changePwd.desc')}
            <span style={{ fontWeight: 'bold' }}>{t('login:changePwd.desc2')}</span>
            {t('login:changePwd.desc3')}
        </Typography>
        <TextField
            placeholder={t('login:changePwd.input')}
            name="password"
            type="password"
            value={values.pwd}
            onChange={e => setters.setPwd(e.target.value)}
            variant="outlined"
        />
        <TextField
            placeholder={t('login:changePwd.inputAgain')}
            name="passwordAgain"
            type="password"
            value={values.pwdAgain}
            onChange={e => setters.setPwdAgain(e.target.value)}
            variant="outlined"
            style={{
                marginTop: '1rem'
            }}
        />
        {
            (!!error || success) &&
            <Alert
                severity={error ? 'error' : 'success'}
                style={{
                    marginTop: '1rem',
                    maxWidth: '85%'
                }}
            >
                {!!error ? error : t('login:changePwd.success')}
            </Alert>
        }
        <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
                marginTop: '1rem'
            }}
        >
            {t('login:changePwd.confirm')}
        </Button>
    </>
);

const ConfirmEmailContent = ({ t, error, success }) => (
<>
        <Typography
            variant="h5"
            style={{
                marginTop: '2rem',
                fontWeight: 'bolder'
            }}
        >
            {t('confirmEmail:header')}
        </Typography>
        <Typography
            variant="body2"
            style={{
                padding: '2rem'
            }}
        >
            {t('confirmEmail:desc')}
            <span style={{ fontWeight: 'bold' }}>{t('confirmEmail:desc2')}</span>
            {t('confirmEmail:desc3')}
        </Typography>
        {
            (!!error || success) &&
            <Alert
                severity={error ? 'error' : 'success'}
                style={{
                    marginTop: '1rem',
                    maxWidth: '85%'
                }}
            >
                {!!error ? error : t('confirmEmail:success')}
            </Alert>
        }
        <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
                marginTop: '1rem'
            }}
        >
            {t('confirmEmail:submit')}
        </Button>
    </>
);

const chooseContent = ({ isLogin, isReset, isChangePwd, isConfirmEmail }) => {
    if (isLogin) {
        return LoginContent;
    }
    if (isReset) {
        return ResetPwdContent;
    }
    if (isChangePwd) {
        return ChangePasswordContent;
    }
    if (isConfirmEmail) {
        return ConfirmEmailContent;
    }
    return RegisterContent;
}


const MainCard = ({
    isLogin, setters, values, error, isReset, success, isChangePwd, isConfirmEmail
}) => {
    const { t } = useTranslation();
    const Content = chooseContent({ isLogin, isReset, isChangePwd, isConfirmEmail });
    return (
        <Paper
            style={{
                width: '362px',
                height: '443px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Content
                error={error}
                setters={setters}
                t={t}
                success={success}
                values={values}
            />
        </Paper>
    );
};

export default MainCard;