import React from "react";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constants/colors";

const TableHeader = (props) => {
  const { t } = useTranslation();
  const { dayId } = props;

  return (
    <thead id={dayId}>
      <tr>
        <div style={{ marginRight: "5px" }}>
          <td>
            <ArrowUpward style={{ color: COLORS.blue, height: 16 }} />
          </td>
          <td>
            <ArrowDownward style={{ color: COLORS.blue, height: 16 }} />
          </td>
        </div>
        <td>
          <Typography display="inline" variant="h6">
            {t("menuEdit:name")}
          </Typography>
        </td>
        <td style={{ minWidth: 300 }}>
          <Typography display="inline" variant="h6">
            {t("menuEdit:description")}
          </Typography>
        </td>
        <td style={{ minWidth: 100 }}>
          <Typography display="inline" variant="h6">
            {t("menuEdit:price")}
          </Typography>
        </td>
        <td>
          {dayId !== 5 && dayId !== 6 && (
            <Typography display="inline" variant="h6">
              {t("menuEdit:wholeWeek")}
            </Typography>
          )}
        </td>

        <td>
          <Typography display="inline" variant="h6">
            {t("menuEdit:actions")}
          </Typography>
        </td>
      </tr>
    </thead>
  );
};

export default TableHeader;
