import axios from "axios";
import {
  firestore,
  firebaseAppAuth,
  firebaseEndpoint,
} from "../utils/firebase";
import firebase from "firebase/app";

export const getEditorInfo = async (email) => {
  const response = await axios.post(`${firebaseEndpoint}/hasEditorAccount`, {
    email: email,
  });

  return response.data;
};

export const createEditorAccount = async (email, name, facility) => {
  const response = await axios.post(`${firebaseEndpoint}/createEditorAccount`, {
    email,
    name,
    facility,
  });

  return response.data;
};

export const addEditorToTable = (user, name, email) => {
  return firestore.collection("editors").doc(user).set({ name, email });
};

export const addEditorToFacility = (user, facility) => {
  return firestore
    .collection("facilities")
    .doc(facility)
    .update({
      editors: firebase.firestore.FieldValue.arrayUnion(user),
    });
};

export const removeEditorFromFacility = (user, facility) => {
  return firestore
    .collection("facilities")
    .doc(facility)
    .update({
      editors: firebase.firestore.FieldValue.arrayRemove(user),
    });
};

export const getEditors = (ids) => {
  return firestore
    .collection("editors")
    .where(firebase.firestore.FieldPath.documentId(), "in", ids)
    .get();
};

export const getEditedFacilities = () => {
  return new Promise((resolve, reject) => {
    const user = firebaseAppAuth.currentUser;
    if (!user) {
      return resolve([]);
    }

    firestore
      .collection("facilities")
      .where("editors", "array-contains", user.uid)
      .where("deleted", "==", false)
      .get()
      .then((docs) => {
        const facilities = [];
        docs.forEach((entry) =>
          facilities.push({ ...entry.data(), id: entry.id })
        );
        resolve(facilities);
      })
      .catch((error) => reject(error));
  });
};
