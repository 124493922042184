import React, {useEffect, useState} from "react";
import {Card, CardContent, Typography} from "@material-ui/core";
import {COLORS} from "../../../constants/colors";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const WeekTag = ({t, selectedWeek}) => {
    const [formatedSelectedWeek, setFormatedSelectedWeek] = useState();
    const getThisWeek = () => {
        const now = selectedWeek;
        const firstDay = now.clone().weekday(0);
        const lastDay = now.clone().weekday(6)
        return firstDay.format("D.M.Y") + ' - ' + lastDay.format("D.M.Y");
    };

    useEffect(() => {
        setFormatedSelectedWeek(getThisWeek(selectedWeek))
    }, [selectedWeek])

    return (
        <Card
            variant="outlined"
            style={{
                minWidth: "275px",
                width: "fit-content",
                borderColor: COLORS.blue,
                borderRadius: "24px",
            }}
        >
            <CardContent
                style={{
                    padding: 4,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <EventAvailableIcon
                    style={{marginLeft: '8px'}}
                />
                <Typography
                    variant="body1"
                    style={{
                        margin: "0 auto 0 12px",
                        fontWeight: "bold",
                        color: COLORS.blue,
                    }}
                >
                    {t("menuEdit:week") + ' '}
                    {formatedSelectedWeek}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default WeekTag;
