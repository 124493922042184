import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useTextStyles } from "../styles/text";
import { isFacilityDeclined } from "../api/facility";
import useLoader from "../hooks/useLoader";
import { AddFacility } from "../components";

const address = "support@restomat.cz";
const Declined = ({ classes, t }) => (
  <div>
    <Typography variant="h5" style={{ fontWeight: "blod" }}>
      {t("waitingRoom:denied.title")}
    </Typography>
    <Typography variant="body2" className={classes.paragraph}>
      {t("waitingRoom:denied.desc")}
    </Typography>
    <Typography variant="body1" style={{ textAlign: "center" }}>
      <a href={`mailto:${address}`}>{address}</a>
    </Typography>
  </div>
);

const WaitingRoom = () => {
  const [declined, setDeclined] = useState(null);
  const { startLoading, endLoading } = useLoader();
  const classes = useTextStyles();
  const { t } = useTranslation();

  useEffect(() => {
    startLoading();
    isFacilityDeclined()
      .then((data) => {
        setDeclined(data);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => endLoading());
  }, []);

  return declined ? (
    <Declined classes={classes} t={t} />
  ) : (
    <AddFacility addingDraft={true} />
  );
};

export default WaitingRoom;
