import React from "react";
import styled from "@material-ui/styles/styled";
import TextField from "@material-ui/core/TextField";
import { COLORS } from "../constants/colors";

const BorderlessTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    height: "42px",
    margin: "0 10px",
    "& fieldset": {
      borderRadius: "24px",
      border: 'none',
      padding: 0,
    },
    "& input": {
      padding: 0,
      textAlign: 'center'
    },
    "&.Mui-focused fieldset": {
      border: 'none',
      padding: 0,
    },
  },
});
export default BorderlessTextField;
