import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getOwnedFacilities } from "../api/manager";
import { getEditedFacilities } from "../api/editors";
import useLoader from "./useLoader";

const useManagedFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const { startLoading, endLoading } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    startLoading();

    Promise.all([getOwnedFacilities(), getEditedFacilities()])
      .then((dataArray) => {
        const allFacilities = [];
        dataArray.forEach((data) => {
          data.forEach((facility) => {
            if (allFacilities.find((f) => f.id === facility.id)) {
              return;
            }

            allFacilities.push(facility);
          });
        });

        setFacilities(allFacilities);
      })
      .catch((error) => {
        // TODO
        console.error(error);
        alert(t("managerMain:loadingError"));
      })
      .finally(() => endLoading());
  }, [setFacilities]);

  return [facilities, setFacilities];
};

export default useManagedFacilities;
