import React from "react";
import { useTranslation } from "react-i18next";
import stringInject from "stringinject";

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";

import { addEditorToTable, addEditorToFacility } from "../../api/editors";

const ExistingUserStage = ({
  data,
  setStageData,
  facility,
  onClose,
  onEditorAdd,
}) => {
  const { t } = useTranslation();

  const handleFormSubmit = async () => {
    if (!data.name) {
      return alert(t("editors:insertNameAndSurname"));
    }

    await addEditorToTable(data.uid, data.name, data.email);
    await addEditorToFacility(data.uid, facility);

    onEditorAdd(data.uid);

    onClose();
  };

  return (
    <>
      <DialogContent>
        <Typography variant="body2">
          {stringInject(t("editors:notEditorDesc"), [data.email])}
        </Typography>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <TextField
            name="name"
            type="text"
            placeholder={t("editors:nameAndSurname")}
            value={data.name}
            onChange={(e) =>
              setStageData((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!data.name}
          onClick={handleFormSubmit}
        >
          {t("confirmDialog:accept")}
        </Button>
      </DialogActions>
    </>
  );
};

export default ExistingUserStage;
