import React, { useRef } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";

import { useFullFormStyles } from "../styles/form";
import { uploadMessageFile } from "../api/storage";
import { COLORS } from "../constants/colors";

const MAX_FILE_PREVIEW_LENGTH = 20;
export const displayFileName = (url, shorten = false) => {
  const name = url.match(/(.{28})(\d{13})(.*)/i)[3];

  if (!shorten || name.length <= MAX_FILE_PREVIEW_LENGTH) {
    return name;
  }

  return (
    name.substr(0, MAX_FILE_PREVIEW_LENGTH - 6) +
    "..." +
    name.substr(name.length - 3)
  );
};

const displayFilesCount = (count) => {
  switch (count) {
    case 1:
      return "1 soubor";
    case 2:
    case 3:
    case 4:
      return `${count} soubory`;
    default:
      return `${count} souborů`;
  }
};

const FilesPicker = ({
  urls,
  setUrls,
  text,
  id,
  showFilenames = true,
  style = {},
}) => {
  const { t } = useTranslation();
  const classes = useFullFormStyles();

  const inputRef = useRef();

  const handleUpload = async (event) => {
    const files = inputRef.current.files;
    if (!files || files.length < 1) {
      return;
    }

    const newUrls = [...urls];
    for (let i = 0; i < files.length; ++i) {
      const filename = await uploadMessageFile(files[i]);
      newUrls.push(filename);
    }

    setUrls(newUrls);
    event.target.value = null;
  };

  const handleRemove = async (file) => {
    const newUrls = urls.filter((url) => url !== file);
    setUrls(newUrls);
  };

  const handleOpenPicker = () => document.getElementById(id).click();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "1rem",
        justifyContent: "center",
        ...style,
      }}
    >
      {!!urls && urls.length > 0 && (
        <>
          {showFilenames &&
            urls.map((url) => (
              <Grid container key={url}>
                <Grid item>
                  <IconButton
                    onClick={() => handleRemove(url)}
                    style={{
                      padding: "0.2rem",
                    }}
                  >
                    <CancelIcon
                      style={{ fontSize: "1rem", padding: 0, margin: 0 }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ wordBreak: "break-word" }}
                  >
                    {displayFileName(url, true)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {!showFilenames && (
            <Typography variant="body2">
              {displayFilesCount(urls.length)}
            </Typography>
          )}
        </>
      )}
      <div>
        <input
          id={id}
          className={classes.inputFile}
          type="file"
          ref={inputRef}
          onChange={handleUpload}
          multiple
        />
        <div
          onClick={handleOpenPicker}
          style={{
            cursor: "pointer",
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: COLORS.primary,
            }}
          >
            {t(text)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FilesPicker;
