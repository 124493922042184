import React, { useState } from "react";

import { Button, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

const WizardStepTwo = ({
  classes,
  changeStep,
  post,
  handleChange,
  t,
  errors,
}) => {
  const [warning, setWarning] = useState("");

  const handlePrevStepChange = () => changeStep(-1);

  const handleNextStepChange = () => {
    if (post.from && post.plannedTo && post.plannedTo < post.from) {
      return setWarning(t("addPost:errors.plannedBeforeStart"));
    }

    if (post.from && post.plannedFrom && post.from < post.plannedFrom) {
      return setWarning(t("addPost:errors.plannedAfterStart"));
    }

    if (post.to && post.plannedFrom && post.to < post.plannedFrom) {
      return setWarning(t("addPost:errors.plannedAfterEnd"));
    }

    setWarning("");
    changeStep(1);
  };

  // TODO make the DateTimePicker a custom component similar to src/components/CustomDatePicker.jsx
  return (
    <>
      <Typography className={classes.fromEntry}>
        <b>{t("addPost:items.planning.title")}</b>
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DateTimePicker
          className={classes.formEntry}
          ampm={false}
          clearable
          okLabel={t("timeEditor:ok")}
          cancelLabel={t("timeEditor:cancel")}
          clearLabel={t("timeEditor:clear")}
          error={errors ? errors.plannedFrom : false}
          format="dd.MM.yyyy HH:mm"
          inputVariant="outlined"
          id="plannedFrom"
          label={t("addPost:items.planning.plannedFrom")}
          onChange={(date) => handleChange("plannedFrom", date)}
          value={post.plannedFrom}
        />
        <DateTimePicker
          className={classes.formEntry}
          ampm={false}
          clearable
          okLabel={t("timeEditor:ok")}
          cancelLabel={t("timeEditor:cancel")}
          clearLabel={t("timeEditor:clear")}
          error={errors ? errors.plannedTo : false}
          format="dd.MM.yyyy HH:mm"
          inputVariant="outlined"
          id="plannedTo"
          label={t("addPost:items.planning.plannedTo")}
          onChange={(date) => handleChange("plannedTo", date)}
          value={post.plannedTo}
        />
      </div>
      {warning && (
        <div style={{ color: "red", margin: 10 }}>
          <Typography variant="body2">{warning}</Typography>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className={classes.formEntry}
          color="primary"
          onClick={handlePrevStepChange}
          style={{ width: "30%" }}
          variant="outlined"
        >
          {t("addPost:back")}
        </Button>
        <Button
          className={classes.formEntry}
          color="primary"
          onClick={handleNextStepChange}
          style={{ width: "30%", marginLeft: "1rem" }}
          variant="contained"
        >
          {t("addPost:continue")}
        </Button>
      </div>
    </>
  );
};

export default WizardStepTwo;
