import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { addMembershipType, updateMembershipType } from "../../api/membership";
import useLoader from "../../hooks/useLoader";
import { useLoginFormStyles } from "../../styles/form";
import { FormDialog } from "../../components";
import { DescriptionField } from "../../components/facility";

const MembershipTypesForm = ({
  edit = false,
  open = false,
  onClose,
  values,
}) => {
  const classes = useLoginFormStyles();
  const { startLoading, endLoading } = useLoader();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sysid, setSysid] = useState("");
  const [color, setColor] = useState("#000000");
  useEffect(() => {
    setName(values ? values.name : "");
    setDescription(values ? values.description : "");
    setSysid(values ? values.sysid : "");
    setColor(values ? values.color : "#000000");
  }, [values]);

  const handleAddType = () => {
    startLoading();
    addMembershipType(sysid, name, description, color)
      .then(() => {
        setName("");
        setDescription("");
        setSysid("");
        setColor("#000000");
        onClose();
      })
      .catch((error) => {
        console.error(error);
        alert(t("membershipTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const handleEditType = () => {
    startLoading();
    updateMembershipType(values.id, sysid, name, color, description)
      .then(() => onClose())
      .catch((error) => {
        console.error(error);
        alert(t("membershipTypes:add.error"));
      })
      .finally(() => endLoading());
  };

  const onSave = edit ? handleEditType : handleAddType;

  return (
    <FormDialog
      title={edit ? t("membershipTypes:edit.formTitle") : t("membershipTypes:add.formTitle")}
      open={open}
      onClose={onClose}
      onSave={onSave}
    >
      <div className={classes.formEntry}>
        <TextField
          label={t("membershipTypes:sysid.fancy")}
          name="sysid"
          type="text"
          value={sysid}
          onChange={(e) => setSysid(e.target.value)}
          variant="outlined"
          disabled={edit}
        />
      </div>
      <div className={classes.formEntry}>
        <TextField
          label={t("membershipTypes:name.fancy")}
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
        />
      </div>
      <DescriptionField value={description} setValue={setDescription} />
      <div className={classes.formEntry}>
        <TextField
          helperText={t("membershipTypes:color")}
          name="color"
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          variant="outlined"
          style={{ width: 222.4 }}
        />
      </div>
    </FormDialog>
  );
};

export default MembershipTypesForm;
