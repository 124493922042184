import moment from 'moment';

const TIMESTAMP_FORMAT = 'HH:mm';

const createMomentObj = time => {
    if (time && time.seconds) {
        return moment(time.toDate());
    }
    return moment(time);
}

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const formatTime = (time, t) => {
    const momentObj = createMomentObj(time);
    const now = moment();
    const yesterday = moment().subtract(1, 'days');

    if (now.isSame(momentObj, 'day')) {
        return momentObj.format(TIMESTAMP_FORMAT);
    }

    if (yesterday.isSame(momentObj, 'day')) {
        return `${t('messages:conversation.yesterday')} ${momentObj.format(TIMESTAMP_FORMAT)}`;
    }

    if (yesterday.isSame(momentObj, 'year')) {
        return momentObj.format('D. M.');
    }

    return momentObj.format('D. M. YY');
};

export const chooseSender = (isAdmin, message, userName, t, humanSender) => {
    if (isAdmin) {
        if (message.sender === 'admin') {
            return t('messages:conversation.participants.you');
        }
        return humanSender || userName || t('messages:conversation.participants.user');
    }
    return humanSender || t(`messages:conversation.participants.${message.sender === 'admin' ? 'admin' : 'you'}`);
};

const messagesComparator = (fst, snd) => {
    if (!fst.data.messages && !snd.data.messages) {
        return 0;
    }
    if (!fst.data.messages) {
        return -1;
    }
    if (!snd.data.messages) {
        return 1;
    }
    if (!fst.data.messages.length && !snd.data.messages.length) {
        return 0;
    }
    if (!fst.data.messages.length) {
        return -1;
    }
    if (!snd.data.messages.length) {
        return 1;
    }
    const fstTime = createMomentObj(fst.data.messages[fst.data.messages.length - 1].timestamp);
    const sndTime = createMomentObj(snd.data.messages[snd.data.messages.length - 1].timestamp);
    return sndTime.diff(fstTime);
};

export const sortMessages = (messages) => {
    return messages.sort(messagesComparator);
};

export const showConversationTitle = (title, t) => {
    if (!title) {
        return t('messages:conversations.add.defaultSubject');
    }

    if (title.length > 40) {
        return `${title.slice(0, 40)}...`;
    }

    return title;
};
